import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { QueryFunctionContext } from 'react-query';
import {
  GetPartnersQuery,
  GetCustomersQuery,
  GetPartnersQueryVariables,
  GetCustomersQueryVariables,
  searchInput,
  SearchOrdersQuery,
  SearchPaymentsQuery,
  SearchQuotesQuery,
  SearchRequestsQuery,
  OrderSearchResults,
  ErrorResponse,
  PaymentSearchResults,
  RequestSearchResults,
  PartnerPage,
  CustomerPage,
  QuoteSearchResults,
  GetQuotesByRequestQuery,
  QuotePage,
} from '../API';
import {
  getPartners as getPartnersQuery,
  searchOrders as searchOrdersMutation,
  searchPayments as searchPaymentsMutation,
  searchQuotes as searchQuotesMutation,
  searchRequests as searchRequestsMutation,
  getCustomers as getCustomersQuery,
  getQuotesByRequest as getQuotesByRequestMutation,
} from '../graphql/queries';

export async function searchPayments({ queryKey }: any) {
  const input = queryKey[1] as searchInput;

  const { data } = (await API.graphql({
    query: searchPaymentsMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: SearchPaymentsQuery };

  return data.searchPayments as PaymentSearchResults;
}

export async function searchRequests({ queryKey }: any) {
  const input = queryKey[1] as searchInput;

  try {
    const { data } = (await API.graphql({
      query: searchRequestsMutation,
      variables: { input },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as { data: SearchRequestsQuery };

    return data.searchRequests as RequestSearchResults;
  } catch (error) {
    console.log(
      '🚀 ~ file: queries.ts ~ line 31 ~ searchRequests ~ error',
      error,
    );

    return null;
  }
}

export async function searchOrders({ queryKey }: any) {
  const input = queryKey[1] as searchInput;

  const { data } = (await API.graphql({
    query: searchOrdersMutation,
    variables: { input },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: SearchOrdersQuery };

  return data.searchOrders as OrderSearchResults | ErrorResponse;
}

export async function getPartners(
  context: QueryFunctionContext<[string, GetPartnersQueryVariables]>,
) {
  const variables = context.queryKey[1];

  const nextToken = context.pageParam;
  const { data } = await (API.graphql({
    query: getPartnersQuery,
    variables: {
      ...variables,
      nextToken,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  }) as Promise<{ data: GetPartnersQuery }>);
  return data.getPartners as PartnerPage;
}

export async function getCustomers(
  context: QueryFunctionContext<[string, GetCustomersQueryVariables]>,
) {
  const variables = context.queryKey[1];
  const nextToken = context.pageParam;
  const { data } = await (API.graphql({
    query: getCustomersQuery,
    variables: {
      ...variables,
      nextToken,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  }) as Promise<{ data: GetCustomersQuery }>);
  return data.getCustomers as CustomerPage;
}

export async function searchQuotes({ queryKey }: any) {
  const input = queryKey[1] as searchInput;

  try {
    const { data } = (await API.graphql({
      query: searchQuotesMutation,
      variables: { input },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as { data: SearchQuotesQuery };

    return data.searchQuotes as QuoteSearchResults;
  } catch (error) {
    console.log(
      '🚀 ~ file: queries.ts ~ line 62 ~ searchQuotes ~ error',
      error,
    );
  }
}

export async function getQuotesByRequest({ queryKey }: any) {
  const input = queryKey[1] as searchInput;

  try {
    const { data } = (await API.graphql({
      query: getQuotesByRequestMutation,
      variables: { input },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    })) as { data: GetQuotesByRequestQuery };

    return data.getQuotesByRequest as QuotePage;
  } catch (error) {
    console.log(
      '🚀 ~ file: queries.ts ~ line 62 ~ searchQuotes ~ error',
      error,
    );
  }
}
