import {
  Box,
  Heading,
  HStack,
  IconButton,
  Select,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useQuery } from 'react-query';
import { searchQuotes } from '../../api/queries';

import { groupBy, orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { searchInput, Status } from '../../API';
import { selectedQuotesAtom, quotesAtom } from '../../recoil/atoms';
import EntityWithStatusChart from '../charts/entity-with-status';
import { paths, statuses } from '../../utils/constants';
import { FiMoreHorizontal } from 'react-icons/fi';

export default function QuoteSummary() {
  const [quoteInput] = useState<searchInput>({ all: { all: true } });
  const setSelectedQuotes = useSetRecoilState(selectedQuotesAtom);
  const [statusList, setStatusList] = useState<Status[]>(statuses);

  const navigate = useNavigate();

  const { data } = useQuery(['quotes', quoteInput], searchQuotes, {
    cacheTime: 10000,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const setQuotes = useSetRecoilState(quotesAtom);

  useEffect(() => {
    if (!data) return;
    if (!data.all) return;
    setQuotes(data.all.quotes);
  }, [data]);

  if (!data) return null;

  const groupedByStatus = groupBy(data.all?.quotes, 'quote.status');

  if (Object.keys(groupedByStatus).length < 1) {
    return (
      <Box width="full" p={6} bg="beige">
        <Heading fontSize={'md'} my="3" color={'orange.500'}>
          NO QUOTE DATA YET
        </Heading>
      </Box>
    );
  }

  const sortedByTime = orderBy(
    data.all?.quotes?.map(x => {
      return {
        time: new Date(x.quote.created).toDateString(),
        type: x.quote.entityType,
        status: x.quote.status,
        item: x,
      };
    }),
    'time',
    'asc',
  );

  return (
    <Box width="full" p={6} bg="beige">
      <HStack p={3}>
        <Heading fontSize={'md'} color={'orange.500'}>
          QUOTES
        </Heading>
        <Spacer />
        <IconButton
          onClick={() => {
            setSelectedQuotes(groupedByStatus[statusList[0]]);
            navigate(paths.quotes);
          }}
          aria-label="more button"
          bg="orange"
          icon={<FiMoreHorizontal />}
        />
      </HStack>
      <Select
        placeholder="Select Status"
        onChange={e => {
          setSelectedQuotes(groupedByStatus[e.target.value]);
          if (!e.target.value) {
            setStatusList(statuses);
            return;
          }
          setStatusList([e.target.value as Status]);
        }}
      >
        {statuses.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
      <VStack spacing={'auto'}>
        {statuses.map(x =>
          groupedByStatus[x] ? (
            <Text key={x} fontSize={'small'}>
              {x}: <b>{groupedByStatus[x].length}</b>
            </Text>
          ) : null,
        )}
      </VStack>
      <EntityWithStatusChart
        sortedByTime={sortedByTime}
        statusToDisplay={statusList}
      />
    </Box>
  );
}
