import { Box, HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import { Order } from '../../API';
import { orderDisplayAttributes, splitCamelCase } from '../../utils/functions';

interface Props {
  order: Order;
}

export default function OrderCard({ order }: Props) {
  return (
    <Stack spacing="0">
      {Object.entries(orderDisplayAttributes(order)).map(([key, value]) => {
        return value ? (
          <HStack width={'full'} key={key}>
            <Text fontWeight={'bold'} size="xs">
              {splitCamelCase(key)}:
            </Text>
            <Spacer />
            {typeof value !== 'object' ? (
              <Text pt="2" fontSize="xs">
                {value}
              </Text>
            ) : (
              <Box pt="2" fontSize="xs">
                <ReactJson
                  name={null}
                  sortKeys={true}
                  collapsed={false}
                  displayDataTypes={false}
                  indentWidth={1}
                  src={value}
                />
              </Box>
            )}
          </HStack>
        ) : null;
      })}
    </Stack>
  );
}
