/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type createCustomerInput = {
  firstName: string,
  familyName: string,
  gender?: Gender | null,
  imageS3Name?: string | null,
  birthdate?: string | null,
  phoneNumber: string,
  email?: string | null,
};

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  OTHER = "OTHER",
}


export type Customer = {
  __typename: "Customer",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  createdBy?: string | null,
  firstName: string,
  familyName: string,
  gender?: Gender | null,
  imageS3Name?: string | null,
  birthdate?: string | null,
  phoneNumber: string,
  email?: string | null,
};

export type ICommon = {
  __typename: "ICommon",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
};

export type Order = {
  __typename: "Order",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  quotes:  Array<PartnerOrder >,
  customerId: string,
  status: Status,
};

export enum EntityType {
  PENDING_PAYMENT = "PENDING_PAYMENT",
  CUSTOMER = "CUSTOMER",
  INVALID_PAYMENT = "INVALID_PAYMENT",
  TIME_TO_LIVE = "TIME_TO_LIVE",
  PARTNER = "PARTNER",
  REQUEST = "REQUEST",
  QUOTE = "QUOTE",
  CONVERSATION = "CONVERSATION",
  MESSAGE = "MESSAGE",
  AUTO_PART = "AUTO_PART",
  DELIVERY = "DELIVERY",
  FEEDBACK = "FEEDBACK",
  ORDER = "ORDER",
  PAYMENT = "PAYMENT",
  PAYOUT = "PAYOUT",
  REVIEW = "REVIEW",
  NOTIFICATION = "NOTIFICATION",
  PHOTO = "PHOTO",
  VEHICLE = "VEHICLE",
  RELATIONSHIP = "RELATIONSHIP",
  MOBILE_MONEY = "MOBILE_MONEY",
  CARD = "CARD",
  PARTNER_ORDER = "PARTNER_ORDER",
  CONTACT_LIST = "CONTACT_LIST",
  AUTO_PART_CATEGORY = "AUTO_PART_CATEGORY",
  AUTO_PART_SUBCATEGORY = "AUTO_PART_SUBCATEGORY",
  CONVERSATION_CONNECTION = "CONVERSATION_CONNECTION",
  VEHICLE_TYPE = "VEHICLE_TYPE",
  MAKE = "MAKE",
  MODEL = "MODEL",
  VARIANT = "VARIANT",
  ENGINE = "ENGINE",
  PAYMENT_REF = "PAYMENT_REF",
  TRANSACTION_STATUS = "TRANSACTION_STATUS",
  TRANSACTION = "TRANSACTION",
  REFUND = "REFUND",
  SMS = "SMS",
  NOTIFICATION_PERMISSION = "NOTIFICATION_PERMISSION",
}


export type PartnerOrder = {
  __typename: "PartnerOrder",
  partnerId: string,
  quoteIds: Array< string >,
};

export enum Status {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}


export type Quote = {
  __typename: "Quote",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  discontinued?: boolean | null,
  comment?: string | null,
  status: Status,
  expiryDate: string,
  total: number,
  isNew?: boolean | null,
};

export type Partner = {
  __typename: "Partner",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  createdBy?: string | null,
  name: string,
  imageS3Name?: string | null,
  email?: string | null,
  phoneNumber: string,
  address?: string | null,
  bankAccount?: string | null,
  mainContactPerson: string,
  subscribedCategories?: Array< string > | null,
  mobileMoneyDetails?:  Array<MobileMoneyDetail > | null,
};

export type MobileMoneyDetail = {
  __typename: "MobileMoneyDetail",
  processor: PaymentProcessors,
  name: string,
  number: string,
};

export enum PaymentProcessors {
  MANUAL = "MANUAL",
  FLUTTERWAVE = "FLUTTERWAVE",
  MTN = "MTN",
  AIRTEL = "AIRTEL",
  TINGG = "TINGG",
}


export type Request = {
  __typename: "Request",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  discontinued?: boolean | null,
  make?: string | null,
  model?: string | null,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  variant?: string | null,
  VIN?: string | null,
  engineNumber?: string | null,
  status: Status,
  contactDetails?: string | null,
  otherDetails?: string | null,
  creatorId: string,
  creatorType: Creator,
  quoteIds?: Array< string > | null,
  vehicleId?: string | null,
};

export enum GearBox {
  MANUAL = "MANUAL",
  AUTOMATIC = "AUTOMATIC",
  HYBRID = "HYBRID",
}


export enum Creator {
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
}


export type AutoPart = {
  __typename: "AutoPart",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  category?: AutoPartCategory | null,
  subCategory?: AutoPartSubCategory | null,
  description?: string | null,
  imageS3Name?: string | null,
  fitmentNotes?: string | null,
  weightG?: number | null,
  widthMM?: number | null,
  heightMM?: number | null,
  lengthMM?: number | null,
  isUniversal?: boolean | null,
  modelCode?: string | null,
  regYearAndmonth?: string | null,
  mileage?: string | null,
  transmissionType?: string | null,
  engineModel?: string | null,
  engineSize?: string | null,
  fuel?: string | null,
  drive?: string | null,
  manufacturer?: string | null,
  OEM?: string | null,
  src?: Array< string | null > | null,
  make?: string | null,
  model?: string | null,
};

export type AutoPartCategory = {
  __typename: "AutoPartCategory",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  imageS3Name?: string | null,
  description?: string | null,
};

export type AutoPartSubCategory = {
  __typename: "AutoPartSubCategory",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  imageS3Name?: string | null,
  category?: AutoPartCategory | null,
  description?: string | null,
};

export type Photo = {
  __typename: "Photo",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  imageS3Name: string,
  description?: string | null,
  contentType?: string | null,
  title?: string | null,
  creatorType: Creator,
  creatorId: string,
};

export type Vehicle = {
  __typename: "Vehicle",
  id: string,
  created: string,
  modified: string,
  discontinued?: boolean | null,
  entityType: EntityType,
  make: string,
  model: string,
  engineNumber: string,
  variant: string,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  plateNumber?: string | null,
  VIN?: string | null,
  creatorType: Creator,
  creatorId: string,
  otherDetails?: string | null,
};

export type Payment = {
  __typename: "Payment",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  orderId: string,
  creatorId: string,
  creatorType: string,
  currency: string,
  otherDetails?: string | null,
  referenceId: string,
  transactionId?: string | null,
  notes?: string | null,
  momoPhoneNumber: string,
  momoText: string,
  status: Status,
  eventHistory:  Array<EventHistoryItem >,
  event?: EventType | null,
  processor: PaymentProcessors,
  fee: Fees,
  receivedFees?: ReceivedFees | null,
};

export type EventHistoryItem = {
  __typename: "EventHistoryItem",
  event: EventType,
  date: string,
  note: string,
};

export enum EventType {
  PAYMENT_PROCESSOR_INITIATION_RESPONSE = "PAYMENT_PROCESSOR_INITIATION_RESPONSE",
  PAYMENT_PROCESSOR_WEBHOOK_VERIFICATION = "PAYMENT_PROCESSOR_WEBHOOK_VERIFICATION",
  TEST_EVENT = "TEST_EVENT",
  PAYMENT_CREATED = "PAYMENT_CREATED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  CUSTOMER_OVERPAYED = "CUSTOMER_OVERPAYED",
  CUSTOMER_UNDERPAYED = "CUSTOMER_UNDERPAYED",
  QUOTATION_NOT_EXPIRED = "QUOTATION_NOT_EXPIRED",
  QUOTATION_EXPIRED = "QUOTATION_EXPIRED",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  PAYMENT_CONFIRMED = "PAYMENT_CONFIRMED",
  WAITING_FOR_CUSTOMER_PAYMENT = "WAITING_FOR_CUSTOMER_PAYMENT",
  MOBILE_MONEY_WEBHOOK_RECEIVED = "MOBILE_MONEY_WEBHOOK_RECEIVED",
  PAYMENT_CONFIRMATION_FAILED = "PAYMENT_CONFIRMATION_FAILED",
  PAYMENT_STATUS_CHECK_SUCCESS = "PAYMENT_STATUS_CHECK_SUCCESS",
  PAYMENT_STATUS_CHECK_SUCCESS_FAIL = "PAYMENT_STATUS_CHECK_SUCCESS_FAIL",
  MTN_PAYMENT_SUCCESSFUL = "MTN_PAYMENT_SUCCESSFUL",
  MTN_PAYMENT_REJECTED = "MTN_PAYMENT_REJECTED",
  MTN_PAYMENT_FAILED = "MTN_PAYMENT_FAILED",
  MTN_PAYMENT_TIMED_OUT = "MTN_PAYMENT_TIMED_OUT",
  AIRTEL_PAYMENT_SUCCESSFUL = "AIRTEL_PAYMENT_SUCCESSFUL",
  AIRTEL_PAYMENT_REJECTED = "AIRTEL_PAYMENT_REJECTED",
  AIRTEL_PAYMENT_FAILED = "AIRTEL_PAYMENT_FAILED",
  AIRTEL_PAYMENT_TIMED_OUT = "AIRTEL_PAYMENT_TIMED_OUT",
  MANUAL_CUSTOMER_PAYED = "MANUAL_CUSTOMER_PAYED",
  MANUAL_PAYMENT_SUCCESSFUL = "MANUAL_PAYMENT_SUCCESSFUL",
  MANUAL_PAYMENT_FAILED = "MANUAL_PAYMENT_FAILED",
  RETRY_PAYMENT = "RETRY_PAYMENT",
}


export type Fees = {
  __typename: "Fees",
  finalFee: number,
  chargeFee: number,
  serviceFee: number,
  deliveryFee: number,
  deliveryWithdrawalFee: number,
  withdrawalFee: number,
  processorChargePercentage: number,
  processorChargeRate: number,
  processorPayoutPercentage: number,
  processorPayoutRate: number,
  serviceFeeDate: string,
  partnerPayoutAmount: number,
  deliveryPayoutAmount: number,
  isDeliveryIncluded: boolean,
  processor: PaymentProcessors,
  quotedPrice: number,
  payoutFee: number,
  discount?: number | null,
};

export type ReceivedFees = {
  __typename: "ReceivedFees",
  receivedTotal: number,
  overpayment: number,
  underpayment: number,
};

export type MobileMoney = {
  __typename: "MobileMoney",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  phoneNumber: string,
  country: string,
  mobileMoneyProvider?: MobileMoneyProvider | null,
  creatorId: string,
  creatorType: Creator,
};

export enum MobileMoneyProvider {
  MTN = "MTN",
  AIRTEL = "AIRTEL",
  ZAMTEL = "ZAMTEL",
  FLUTTERWAVE = "FLUTTERWAVE",
}


export type CreditCard = {
  __typename: "CreditCard",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  country: string,
  cardProvider?: CardProvider | null,
  creatorId: string,
  creatorType: Creator,
  expMonth: number,
  expYear: number,
  last4: string,
};

export enum CardProvider {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  FLUTTERWAVE = "FLUTTERWAVE",
}


export type Refund = {
  __typename: "Refund",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  creatorId: string,
  creatorType: Creator,
  status: Status,
  paymentId: string,
  refundAmount: number,
  reason: Status,
  refundMethod: PaymentMethodDef,
};

export type PaymentMethodDef = {
  __typename: "PaymentMethodDef",
  id: string,
  type: PaymentType,
};

export enum PaymentType {
  CARD = "CARD",
  MOBILE_MONEY = "MOBILE_MONEY",
}


export type Payout = {
  __typename: "Payout",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  orderId: string,
  partnerId: string,
  status: Status,
  total: number,
  currency: string,
  paymentMethod: PaymentMethodDef,
  transactionFee: number,
  otherDetails?: string | null,
};

export type VehicleCategory = {
  __typename: "VehicleCategory",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  name: string,
  generation: number,
};

export type Sms = {
  __typename: "Sms",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  _id: string,
  thread_id: number,
  address: string,
  person: number,
  date: string,
  date_sent: string,
  protocol: number,
  read: number,
  status: number,
  type: number,
  body: string,
  service_center: string,
  locked: number,
  error_code: number,
  sub_id: number,
  seen: number,
  deletable: number,
  sim_slot: number,
  hidden: number,
  app_id: number,
  msg_id: number,
  reserved: number,
  pri: number,
  teleservice_id: number,
  svc_cmd: number,
  roam_pending: number,
  spam_report: number,
  secret_mode: number,
  safe_message: number,
  favorite: number,
};

export type Conversation = {
  __typename: "Conversation",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  members:  Array<PartnerCustomer | null >,
};

export type PartnerCustomer = Partner | Customer


export type Message = {
  __typename: "Message",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  message: string,
  conversationId: string,
  senderId: string,
  senderType: Creator,
  imageS3Name?: string | null,
};

export type CustomerJourney = {
  __typename: "CustomerJourney",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  request: Request,
  quote: Quote,
  order: Order,
  payment: Payment,
  creator: Creator,
};

export type NotificationPermission = {
  __typename: "NotificationPermission",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  sendRequestEmails?: boolean | null,
  sendOrderSMSes?: boolean | null,
  sendRequestSMSes?: boolean | null,
  sendOrderEmails?: boolean | null,
  excludedSMSPartners?: Array< string > | null,
  excludedEmailPartners?: Array< string > | null,
};

export type AudioFeedback = {
  __typename: "AudioFeedback",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  audioCDNUrl: string,
  contentType?: string | null,
};

export type TimeToLive = {
  __typename: "TimeToLive",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  otherEntityType: EntityType,
  ttl: number,
};

export type updateCustomerInput_ = {
  firstName?: string | null,
  familyName?: string | null,
  gender?: Gender | null,
  imageS3Name?: string | null,
  birthdate?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
};

export type CustomerOutput = Customer | ErrorResponse


export type ErrorResponse = {
  __typename: "ErrorResponse",
  code: ErrorCode,
  error?: string | null,
};

export enum ErrorCode {
  NOT_FOUND = "NOT_FOUND",
  MALFOMED_INPUT = "MALFOMED_INPUT",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  CREATE_FAILED = "CREATE_FAILED",
  UPDATE_FAILED = "UPDATE_FAILED",
}


export type adminUpdateCustomerInput_ = {
  firstName?: string | null,
  familyName?: string | null,
  gender?: Gender | null,
  imageS3Name?: string | null,
  birthdate?: string | null,
  phoneNumber?: string | null,
  email?: string | null,
  customerId: string,
};

export type DeleteOutput = OperationResponse | ErrorResponse


export type OperationResponse = {
  __typename: "OperationResponse",
  success?: boolean | null,
};

export type createPartnerInput = {
  name: string,
  imageS3Name: string,
  email: string,
  phoneNumber: string,
  address: string,
  bankAccount?: string | null,
  mainContactPerson: string,
  subscribedCategories?: Array< string > | null,
  mobileMoneyDetails?: Array< mobileMoneyDetailInput > | null,
};

export type mobileMoneyDetailInput = {
  processor: PaymentProcessors,
  name: string,
  number: string,
};

export type PartnerOutput = Partner | ErrorResponse


export type updatePartnerInputNew = {
  name?: string | null,
  imageS3Name?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  address?: string | null,
  bankAccount?: string | null,
  mainContactPerson?: string | null,
  subscribedCategories?: Array< string > | null,
  mobileMoneyDetails?: Array< mobileMoneyDetailInput > | null,
};

export type adminUpdatePartnerInputNew = {
  name?: string | null,
  imageS3Name?: string | null,
  email?: string | null,
  phoneNumber?: string | null,
  address?: string | null,
  bankAccount?: string | null,
  mainContactPerson?: string | null,
  subscribedCategories?: Array< string > | null,
  mobileMoneyDetails?: Array< mobileMoneyDetailInput > | null,
  partnerId: string,
};

export type createAutoPartCategoryInput = {
  name: string,
  imageS3Name: string,
  description?: string | null,
};

export type updateAutoPartCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  imageS3Name?: string | null,
};

export type createAutoPartSubCategoryInput = {
  name: string,
  imageS3Name: string,
  categoryId: string,
  description?: string | null,
};

export type updateAutoPartSubCategoryInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  imageS3Name?: string | null,
  categoryId?: string | null,
};

export type createAutoPartInput = {
  name: string,
  categoryId?: string | null,
  subCategoryId?: string | null,
  description?: string | null,
  imageS3Name?: string | null,
  fitmentNotes?: string | null,
  weightG?: number | null,
  widthMM?: number | null,
  heightMM?: number | null,
  lengthMM?: number | null,
  isUniversal?: boolean | null,
  modelCode?: string | null,
  regYearAndmonth?: string | null,
  mileage?: string | null,
  transmissionType?: string | null,
  engineModel?: string | null,
  engineSize?: string | null,
  fuel?: string | null,
  drive?: string | null,
  manufacturer?: string | null,
  OEM?: string | null,
  src?: Array< string > | null,
  make?: string | null,
  model?: string | null,
};

export type AutoPartOutput = AutoPart | ErrorResponse


export type updateAutoPartInput = {
  id: string,
  name?: string | null,
  categoryId?: string | null,
  subCategoryId?: string | null,
  description?: string | null,
  imageS3Name?: string | null,
  fitmentNotes?: string | null,
  weightG?: number | null,
  widthMM?: number | null,
  heightMM?: number | null,
  lengthMM?: number | null,
  isUniversal?: boolean | null,
  modelCode?: string | null,
  regYearAndmonth?: string | null,
  mileage?: string | null,
  transmissionType?: string | null,
  engineModel?: string | null,
  engineSize?: string | null,
  fuel?: string | null,
  drive?: string | null,
  manufacturer?: string | null,
  OEM?: string | null,
  src?: Array< string | null > | null,
  make?: string | null,
  model?: string | null,
};

export type createSmsInput = {
  _id: string,
  thread_id: number,
  address: string,
  person: number,
  date: string,
  date_sent: string,
  protocol: number,
  read: number,
  status: number,
  type: number,
  body: string,
  service_center: string,
  locked: number,
  error_code: number,
  sub_id: number,
  seen: number,
  deletable: number,
  sim_slot: number,
  hidden: number,
  app_id: number,
  msg_id: number,
  reserved: number,
  pri: number,
  teleservice_id: number,
  svc_cmd: number,
  roam_pending: number,
  spam_report: number,
  secret_mode: number,
  safe_message: number,
  favorite: number,
};

export type SmsOutput = Sms | ErrorResponse


export type updateSmsInput = {
  id: string,
  _id?: string | null,
  thread_id?: number | null,
  address?: string | null,
  person?: number | null,
  date?: string | null,
  date_sent?: string | null,
  protocol?: number | null,
  read?: number | null,
  status?: number | null,
  type?: number | null,
  body?: string | null,
  service_center?: string | null,
  locked?: number | null,
  error_code?: number | null,
  sub_id?: number | null,
  seen?: number | null,
  deletable?: number | null,
  sim_slot?: number | null,
  hidden?: number | null,
  app_id?: number | null,
  msg_id?: number | null,
  reserved?: number | null,
  pri?: number | null,
  teleservice_id?: number | null,
  svc_cmd?: number | null,
  roam_pending?: number | null,
  spam_report?: number | null,
  secret_mode?: number | null,
  safe_message?: number | null,
  favorite?: number | null,
};

export type createInvalidPaymentInput = {
  id: string,
  momoText: string,
  momoNumber: string,
  momoAmount: number,
  momoName: string,
  refNumber: string,
  reason: string,
};

export type InvalidPaymentOutput = InvalidPayment | ErrorResponse


export type InvalidPayment = {
  __typename: "InvalidPayment",
  id: string,
  created: string,
  modified: string,
  entityType: EntityType,
  momoText: string,
  momoNumber: string,
  momoAmount: number,
  momoName: string,
  refNumber: string,
  reason: string,
  isOpen: boolean,
};

export type updateInvalidPaymentInput = {
  id: string,
  momoAmount?: number | null,
  momoName?: string | null,
  momoNumber?: string | null,
  momoText?: string | null,
  reason?: string | null,
  refNumber?: string | null,
  isOpen?: boolean | null,
};

export type updatePhotoInput = {
  id: string,
  imageS3Name?: string | null,
  description?: string | null,
};

export type createRequestInput = {
  requestedPart: autoPartRequestInput,
  photos?: Array< string > | null,
  creatorId: string,
  creatorType: Creator,
  make: string,
  model: string,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  VIN?: string | null,
  variant?: string | null,
  engineNumber?: string | null,
  contactDetails?: string | null,
  otherDetails?: string | null,
  status: Status,
  vehicleId?: string | null,
};

export type autoPartRequestInput = {
  autoPartId: string,
  quantity: number,
};

export type RequestResponse = {
  __typename: "RequestResponse",
  request: Request,
  autoPart: AutoPartRequest,
  photos?:  Array<Photo > | null,
  vehicle?: VehicleResponse | null,
};

export type AutoPartRequest = {
  __typename: "AutoPartRequest",
  autoPart: AutoPart,
  quantity: number,
};

export type VehicleResponse = {
  __typename: "VehicleResponse",
  vehicle: Vehicle,
  owner: PartnerCustomer,
};

export type updateRequestInput = {
  id: string,
  requestedPart?: autoPartRequestInput | null,
  quoteIds?: Array< string > | null,
  photos?: Array< string > | null,
  make?: string | null,
  model?: string | null,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  VIN?: string | null,
  engineNumber?: string | null,
  contactDetails?: string | null,
  otherDetails?: string | null,
  vehicleId?: string | null,
};

export type createPaymentInput = {
  orderId: string,
  creatorId: string,
  creatorType: Creator,
  otherDetails?: string | null,
  processor: PaymentProcessors,
  momoPhoneNumber: string,
  isDeliveryIncluded: boolean,
};

export type PaymentOutput = PaymentResponse | ErrorResponse


export type PaymentResponse = {
  __typename: "PaymentResponse",
  payment: Payment,
  order: OrderResponse,
  paymentMethod?: PaymentMethod | null,
  creator: PartnerCustomer,
};

export type OrderResponse = {
  __typename: "OrderResponse",
  order: Order,
  quotes?:  Array<QuoteResponse > | null,
  payment?: Payment | null,
};

export type QuoteResponse = {
  __typename: "QuoteResponse",
  quote: Quote,
  partner: Partner,
  request: RequestResponse,
  autoPart: AutoPartQuoteOutput,
  photos?:  Array<Photo > | null,
};

export type AutoPartQuoteOutput = {
  __typename: "AutoPartQuoteOutput",
  autoPart: AutoPart,
  quotedPrice: number,
  isSold: boolean,
  soldPrice?: number | null,
  comment?: string | null,
  paymentOptions:  Array<Fees >,
};

export type PaymentMethod = MobileMoney | CreditCard


export type updatePaymentInput = {
  id: string,
  currency?: string | null,
  otherDetails?: string | null,
  partnerFee?: number | null,
  referenceId?: string | null,
  transactionId?: string | null,
  momoText?: string | null,
  notes?: string | null,
  momoPhoneNumber?: string | null,
  status?: Status | null,
  eventHistoryItem?: eventHistoryItemInput | null,
  isDeliveryIncluded?: boolean | null,
  processor?: PaymentProcessors | null,
  receivedFees?: receivedFeesInput | null,
};

export type eventHistoryItemInput = {
  event: EventType,
  date: string,
  note: string,
};

export type receivedFeesInput = {
  receivedTotal: number,
  overpayment: number,
  underpayment: number,
};

export type changePaymentProcessorInput = {
  id: string,
  momoPhoneNumber: string,
  processor: PaymentProcessors,
};

export type RetryPaymentResponse = {
  __typename: "RetryPaymentResponse",
  hasRetryBeenInitialized: boolean,
  message: string,
};

export type updateManualCustomerPaymentInput = {
  referenceId: string,
  momoText: string,
  notes?: string | null,
  momoPhoneNumber: string,
  amount: number,
  momoTransactionId: string,
};

export type byCreatorInput = {
  creatorType: Creator,
  id: string,
  limitAndToken?: limitAndTokenInput | null,
};

export type limitAndTokenInput = {
  limit?: number | null,
  nextToken?: string | null,
};

export type createRefundInput = {
  creatorId: string,
  creatorType: Creator,
  status: Status,
  paymentId: string,
  refundAmount: number,
  reason: Status,
  refundMethod: paymentMethodInput,
};

export type paymentMethodInput = {
  id: string,
  type: PaymentType,
};

export type RefundOutput = RefundResponse | ErrorResponse


export type RefundResponse = {
  __typename: "RefundResponse",
  payment: Payment,
  invalid?: Refund | null,
};

export type updateRefundInput = {
  id: string,
  status?: Status | null,
  refundAmount?: number | null,
  reason?: Status | null,
  refundMethod?: paymentMethodInput | null,
};

export type createPayoutInput = {
  orderId: string,
  partnerId: string,
  status: Status,
  total: number,
  currency: string,
  paymentMethod: paymentMethodInput,
  transactionFee: number,
  otherDetails?: string | null,
};

export type PayoutResponse = {
  __typename: "PayoutResponse",
  payout: Payout,
  order: OrderResponse,
  to: Partner,
};

export type updatePayoutInput = {
  id: string,
  partnerId: string,
  orderId?: string | null,
  total?: number | null,
  currency?: string | null,
  paymentMethod?: paymentMethodInput | null,
  transactionFee?: number | null,
  otherDetails?: string | null,
  quoteIds?: Array< string > | null,
};

export type createOrderInput = {
  quotes: Array< partnerQuoteInput >,
  customerId: string,
  status: Status,
};

export type partnerQuoteInput = {
  partnerId: string,
  quoteIds: Array< string >,
};

export type OrderOutput = OrderResponse | ErrorResponse


export type updateOrderInput = {
  id: string,
  customerId: string,
  quotes?: Array< partnerQuoteInput > | null,
};

export type deleteQuoteFromOrderInput = {
  orderId: string,
  quotes: Array< partnerQuoteInput >,
};

export type createVehicleInput_ = {
  make: string,
  model: string,
  variant: string,
  engineNumber: string,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  plateNumber?: string | null,
  VIN?: string | null,
};

export type updateVehicleInput = {
  id: string,
  make?: string | null,
  model?: string | null,
  year?: number | null,
  fuel?: string | null,
  gearbox?: GearBox | null,
  plateNumber?: string | null,
  VIN?: string | null,
  engineNumber?: string | null,
  variant?: string | null,
};

export type VehicleOutput = VehicleResponse | ErrorResponse


export type createPaymentMethodInput = {
  mobileMoney?: createMobileMoneyInput | null,
  creditCard?: createCreditCardInput | null,
};

export type createMobileMoneyInput = {
  name: string,
  phoneNumber: string,
  country: string,
  mobileMoneyProvider: MobileMoneyProvider,
  creatorId: string,
  creatorType: Creator,
};

export type createCreditCardInput = {
  name: string,
  country: string,
  cardProvider: CardProvider,
  creatorId: string,
  creatorType: Creator,
  expMonth: number,
  expYear: number,
  last4: string,
};

export type updatePaymentMethodInput = {
  mobileMoney?: updateMobileMoneyInput | null,
  creditCard?: updateCreditCardInput | null,
};

export type updateMobileMoneyInput = {
  id: string,
  creatorId: string,
  creatorType: Creator,
  name?: string | null,
  phoneNumber?: string | null,
  country?: string | null,
  mobileMoneyProvider?: MobileMoneyProvider | null,
};

export type updateCreditCardInput = {
  id: string,
  creatorId: string,
  creatorType: Creator,
  name?: string | null,
  country?: string | null,
  cardProvider?: CardProvider | null,
  expMonth?: number | null,
  expYear?: number | null,
  last4?: string | null,
};

export type createQuoteInput = {
  requestId: string,
  partnerId: string,
  photos?: Array< string > | null,
  autoPartQuote: AutoPartQuoteInput,
  comment?: string | null,
  status: Status,
  expiryDate?: string | null,
  isNew: boolean,
};

export type AutoPartQuoteInput = {
  autoPartId: string,
  quotedPrice: number,
  isSold?: boolean | null,
  soldPrice?: number | null,
  comment?: string | null,
};

export type updateQuoteInput = {
  id: string,
  photos?: Array< string > | null,
  autoPartQuote?: AutoPartQuoteInput | null,
  comment?: string | null,
  expiryDate?: string | null,
  isNew?: boolean | null,
};

export type createMessageInput = {
  message: string,
  senderId: string,
  senderType: Creator,
  conversationId: string,
  imageS3Name?: string | null,
};

export type createConversationInput = {
  members: Array< PartnerCustomerInput >,
};

export type PartnerCustomerInput = {
  id: string,
  entityType: Creator,
};

export type updateStatusInput = {
  status: Status,
  entityType: EntityType,
  id: string,
};

export type searchInput = {
  all?: allInput | null,
  byStatus?: byStatusInput | null,
  byCreator?: byCreatorInput | null,
  byDateRange?: byDateRangeInput | null,
  byCreatorAndStatus?: byCreatorAndStatusInput | null,
  byStatusAndDateRange?: byStatusAndDateRangeInput | null,
  byCreatorAndDateRange?: byCreatorAndDateRangeInput | null,
  byStatusAndDateRangeAndCreator?: byStatusAndCreatorAndDateRangeInput | null,
};

export type allInput = {
  all: boolean,
  limitAndToken?: limitAndTokenInput | null,
};

export type byStatusInput = {
  status: Status,
  limitAndToken?: limitAndTokenInput | null,
};

export type byDateRangeInput = {
  start: string,
  end: string,
  limitAndToken?: limitAndTokenInput | null,
};

export type byCreatorAndStatusInput = {
  creator: byCreatorInput,
  status: Status,
  limitAndToken?: limitAndTokenInput | null,
};

export type byStatusAndDateRangeInput = {
  dateRange: byDateRangeInput,
  status: Status,
  limitAndToken?: limitAndTokenInput | null,
};

export type byCreatorAndDateRangeInput = {
  dateRange: byDateRangeInput,
  creator: byCreatorInput,
  limitAndToken?: limitAndTokenInput | null,
};

export type byStatusAndCreatorAndDateRangeInput = {
  dateRange: byDateRangeInput,
  creator: byCreatorInput,
  status: Status,
  limitAndToken?: limitAndTokenInput | null,
};

export type OrderSearchResultsOutput = OrderSearchResults | ErrorResponse


export type OrderSearchResults = {
  __typename: "OrderSearchResults",
  all?: OrderPage | null,
  byStatus?: OrderPage | null,
  byPartner?: OrderPage | null,
  byCustomer?: OrderPage | null,
  byDateRange?: OrderPage | null,
  byPartnerAndStatus?: OrderPage | null,
  byCustomerAndStatus?: OrderPage | null,
  byStatusAndDateRange?: OrderPage | null,
  byPartnerAndDateRange?: OrderPage | null,
  byCustomerAndDateRange?: OrderPage | null,
  byStatusAndDateRangeAndPartner?: OrderPage | null,
  byStatusAndDateRangeAndCustomer?: OrderPage | null,
};

export type OrderPage = {
  __typename: "OrderPage",
  orders?:  Array<OrderResponse > | null,
  nextToken?: string | null,
};

export type InvalidPaymentPageOutput = InvalidPaymentPage | ErrorResponse


export type InvalidPaymentPage = {
  __typename: "InvalidPaymentPage",
  invalidPayments?:  Array<InvalidPayment > | null,
  nextToken?: string | null,
};

export type PaymentSearchResults = {
  __typename: "PaymentSearchResults",
  all?: PaymentPage | null,
  byStatus?: PaymentPage | null,
  byCreator?: PaymentPage | null,
  byDateRange?: PaymentPage | null,
  byCreatorAndStatus?: PaymentPage | null,
  byStatusAndDateRange?: PaymentPage | null,
  byCreatorAndDateRange?: PaymentPage | null,
  byStatusAndDateRangeAndCreator?: PaymentPage | null,
};

export type PaymentPage = {
  __typename: "PaymentPage",
  payments?:  Array<PaymentResponse > | null,
  nextToken?: string | null,
};

export type RefundSearchResults = {
  __typename: "RefundSearchResults",
  all?: RefundsPage | null,
  byStatus?: RefundsPage | null,
  byCustomer?: RefundsPage | null,
  byDateRange?: RefundsPage | null,
  byCustomerAndStatus?: RefundsPage | null,
  byStatusAndDateRange?: RefundsPage | null,
  byCustomerAndDateRange?: RefundsPage | null,
  byStatusAndDateRangeAndCustomer?: RefundsPage | null,
};

export type RefundsPage = {
  __typename: "RefundsPage",
  refunds?:  Array<RefundResponse > | null,
  nextToken?: string | null,
};

export type PayoutSearchResults = {
  __typename: "PayoutSearchResults",
  all?: PayoutPage | null,
  byStatus?: PayoutPage | null,
  byCreator?: PayoutPage | null,
  byDateRange?: PayoutPage | null,
  byCreatorAndStatus?: PayoutPage | null,
  byStatusAndDateRange?: PayoutPage | null,
  byCreatorAndDateRange?: PayoutPage | null,
  byStatusAndDateRangeAndCreator?: PayoutPage | null,
};

export type PayoutPage = {
  __typename: "PayoutPage",
  payouts?:  Array<PayoutResponse > | null,
  nextToken?: string | null,
};

export type PaymentMethodResponse = {
  __typename: "PaymentMethodResponse",
  paymentMethod: PaymentMethod,
  creator: PartnerCustomer,
};

export type PaymentMethodPage = {
  __typename: "PaymentMethodPage",
  paymentMethods?:  Array<PaymentMethodResponse > | null,
  nextToken?: string | null,
};

export type vehicleCategoryTreeInput = {
  id: string,
  entityType: EntityType,
  descendants?: depthInput | null,
  ancestors?: depthInput | null,
};

export type depthInput = {
  depth: number,
  limitAndToken?: limitAndTokenInput | null,
};

export type VehicleCategoryOutput = VehicleCategoryResponse | ErrorResponse


export type VehicleCategoryResponse = {
  __typename: "VehicleCategoryResponse",
  category: VehicleCategory,
  ancestors?:  Array<VehicleCategory | null > | null,
  descendants?:  Array<VehicleCategoryPage | null > | null,
};

export type VehicleCategoryPage = {
  __typename: "VehicleCategoryPage",
  categories?:  Array<VehicleCategory > | null,
  generation: number,
  nextToken?: string | null,
};

export type vehicleCategoryTypeInput = {
  type: VehicleCategoryType,
  limitAndToken?: limitAndTokenInput | null,
};

export enum VehicleCategoryType {
  VEHICLE_TYPE = "VEHICLE_TYPE",
  MAKE = "MAKE",
  MODEL = "MODEL",
  VARIANT = "VARIANT",
  ENGINE = "ENGINE",
}


export type VehicleCategoryPageOutput = VehicleCategoryPage | ErrorResponse


export type CustomerPage = {
  __typename: "CustomerPage",
  customers?:  Array<Customer > | null,
  nextToken?: string | null,
};

export type VehiclePage = {
  __typename: "VehiclePage",
  vehicles?:  Array<VehicleResponse | null > | null,
  nextToken?: string | null,
};

export type byCreatorInput_ = {
  limitAndToken?: limitAndTokenInput | null,
};

export type VehiclesOutput = VehiclePage | ErrorResponse


export type PartnerPage = {
  __typename: "PartnerPage",
  partners?:  Array<Partner > | null,
  nextToken?: string | null,
};

export type AutoPartPageOutput = AutoPartPage | ErrorResponse


export type AutoPartPage = {
  __typename: "AutoPartPage",
  autoParts?:  Array<AutoPart > | null,
  nextToken?: string | null,
};

export type SmsPageOutput = SmsPage | ErrorResponse


export type SmsPage = {
  __typename: "SmsPage",
  smses?:  Array<Sms > | null,
  nextToken?: string | null,
};

export type AutoPartCategoryPageOutput = AutoPartCategoryPage | ErrorResponse


export type AutoPartCategoryPage = {
  __typename: "AutoPartCategoryPage",
  autoPartCategories?:  Array<AutoPartCategory > | null,
  nextToken?: string | null,
};

export type AutoPartSubCategoryPageOutput = AutoPartSubCategoryPage | ErrorResponse


export type AutoPartSubCategoryPage = {
  __typename: "AutoPartSubCategoryPage",
  autoPartSubCategories?:  Array<AutoPartSubCategory > | null,
  nextToken?: string | null,
};

export type PhotoPage = {
  __typename: "PhotoPage",
  photos?:  Array<Photo > | null,
  nextToken?: string | null,
};

export type ServiceFeeOutput = ServiceFee | ErrorResponse


export type ServiceFee = {
  __typename: "ServiceFee",
  fee: number,
};

export type RequestSearchResults = {
  __typename: "RequestSearchResults",
  all?: RequestPage | null,
  byStatus?: RequestPage | null,
  byCustomer?: RequestPage | null,
  byDateRange?: RequestPage | null,
  byCustomerAndStatus?: RequestPage | null,
  byStatusAndDateRange?: RequestPage | null,
  byCustomerAndDateRange?: RequestPage | null,
  byStatusAndDateRangeAndCustomer?: RequestPage | null,
};

export type RequestPage = {
  __typename: "RequestPage",
  requests?:  Array<RequestResponse > | null,
  nextToken?: string | null,
};

export type byRequestInput = {
  request: string,
  limitAndToken: limitAndTokenInput,
};

export type QuotesOutput = QuotePage | ErrorResponse


export type QuotePage = {
  __typename: "QuotePage",
  quotes?:  Array<QuoteResponse > | null,
  nextToken?: string | null,
};

export type QuoteSearchResults = {
  __typename: "QuoteSearchResults",
  all?: QuotePage | null,
  byStatus?: QuotePage | null,
  byPartner?: QuotePage | null,
  byDateRange?: QuotePage | null,
  byPartnerAndStatus?: QuotePage | null,
  byStatusAndDateRange?: QuotePage | null,
  byPartnerAndDateRange?: QuotePage | null,
  byStatusAndDateRangeAndPartner?: QuotePage | null,
};

export type ConversationPage = {
  __typename: "ConversationPage",
  conversations?:  Array<ConversationResponse > | null,
  nextToken?: string | null,
};

export type ConversationResponse = {
  __typename: "ConversationResponse",
  conversation: Conversation,
  messages?:  Array<MessageResponse > | null,
};

export type MessageResponse = {
  __typename: "MessageResponse",
  date: string,
  message: Message,
  sender: PartnerCustomer,
};

export type MessagePage = {
  __typename: "MessagePage",
  messages?:  Array<MessageResponse > | null,
  nextToken?: string | null,
};

export type CreateCustomerMutationVariables = {
  input: createCustomerInput,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    createdBy?: string | null,
    firstName: string,
    familyName: string,
    gender?: Gender | null,
    imageS3Name?: string | null,
    birthdate?: string | null,
    phoneNumber: string,
    email?: string | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: updateCustomerInput_,
};

export type UpdateCustomerMutation = {
  updateCustomer: ( {
      __typename: "Customer",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      firstName: string,
      familyName: string,
      gender?: Gender | null,
      imageS3Name?: string | null,
      birthdate?: string | null,
      phoneNumber: string,
      email?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type AdminCreateCustomerMutationVariables = {
  input: createCustomerInput,
};

export type AdminCreateCustomerMutation = {
  adminCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    createdBy?: string | null,
    firstName: string,
    familyName: string,
    gender?: Gender | null,
    imageS3Name?: string | null,
    birthdate?: string | null,
    phoneNumber: string,
    email?: string | null,
  } | null,
};

export type AdminUpdateCustomerMutationVariables = {
  input: adminUpdateCustomerInput_,
};

export type AdminUpdateCustomerMutation = {
  adminUpdateCustomer: ( {
      __typename: "Customer",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      firstName: string,
      familyName: string,
      gender?: Gender | null,
      imageS3Name?: string | null,
      birthdate?: string | null,
      phoneNumber: string,
      email?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeleteCustomerMutationVariables = {
  id: string,
};

export type DeleteCustomerMutation = {
  deleteCustomer: ( {
      __typename: "OperationResponse",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type CreatePartnerMutationVariables = {
  input: createPartnerInput,
};

export type CreatePartnerMutation = {
  createPartner: ( {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: string,
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type UpdatePartnerMutationVariables = {
  input: updatePartnerInputNew,
};

export type UpdatePartnerMutation = {
  updatePartner: ( {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: string,
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type AdminCreatePartnerMutationVariables = {
  input: createPartnerInput,
};

export type AdminCreatePartnerMutation = {
  adminCreatePartner: ( {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: string,
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type AdminUpdatePartnerMutationVariables = {
  input: adminUpdatePartnerInputNew,
};

export type AdminUpdatePartnerMutation = {
  adminUpdatePartner: ( {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: string,
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeletePartnerMutationVariables = {
  id: string,
};

export type DeletePartnerMutation = {
  deletePartner: ( {
      __typename: "OperationResponse",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type CreateAutoPartCategoryMutationVariables = {
  input: createAutoPartCategoryInput,
};

export type CreateAutoPartCategoryMutation = {
  createAutoPartCategory?:  {
    __typename: "AutoPartCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    description?: string | null,
  } | null,
};

export type UpdateAutoPartCategoryMutationVariables = {
  input: updateAutoPartCategoryInput,
};

export type UpdateAutoPartCategoryMutation = {
  updateAutoPartCategory?:  {
    __typename: "AutoPartCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    description?: string | null,
  } | null,
};

export type CreateAutoPartSubCategoryMutationVariables = {
  input: createAutoPartSubCategoryInput,
};

export type CreateAutoPartSubCategoryMutation = {
  createAutoPartSubCategory?:  {
    __typename: "AutoPartSubCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    category?:  {
      __typename: "AutoPartCategory",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      imageS3Name?: string | null,
      description?: string | null,
    } | null,
    description?: string | null,
  } | null,
};

export type UpdateAutoPartSubCategoryMutationVariables = {
  input: updateAutoPartSubCategoryInput,
};

export type UpdateAutoPartSubCategoryMutation = {
  updateAutoPartSubCategory?:  {
    __typename: "AutoPartSubCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    category?:  {
      __typename: "AutoPartCategory",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      imageS3Name?: string | null,
      description?: string | null,
    } | null,
    description?: string | null,
  } | null,
};

export type CreateAutoPartMutationVariables = {
  input: createAutoPartInput,
};

export type CreateAutoPartMutation = {
  createAutoPart: ( {
      __typename: "AutoPart",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      category?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        description?: string | null,
      } | null,
      subCategory?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        description?: string | null,
      } | null,
      description?: string | null,
      imageS3Name?: string | null,
      fitmentNotes?: string | null,
      weightG?: number | null,
      widthMM?: number | null,
      heightMM?: number | null,
      lengthMM?: number | null,
      isUniversal?: boolean | null,
      modelCode?: string | null,
      regYearAndmonth?: string | null,
      mileage?: string | null,
      transmissionType?: string | null,
      engineModel?: string | null,
      engineSize?: string | null,
      fuel?: string | null,
      drive?: string | null,
      manufacturer?: string | null,
      OEM?: string | null,
      src?: Array< string | null > | null,
      make?: string | null,
      model?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type UpdateAutoPartMutationVariables = {
  input: updateAutoPartInput,
};

export type UpdateAutoPartMutation = {
  updateAutoPart: ( {
      __typename: "AutoPart",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      category?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        description?: string | null,
      } | null,
      subCategory?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        description?: string | null,
      } | null,
      description?: string | null,
      imageS3Name?: string | null,
      fitmentNotes?: string | null,
      weightG?: number | null,
      widthMM?: number | null,
      heightMM?: number | null,
      lengthMM?: number | null,
      isUniversal?: boolean | null,
      modelCode?: string | null,
      regYearAndmonth?: string | null,
      mileage?: string | null,
      transmissionType?: string | null,
      engineModel?: string | null,
      engineSize?: string | null,
      fuel?: string | null,
      drive?: string | null,
      manufacturer?: string | null,
      OEM?: string | null,
      src?: Array< string | null > | null,
      make?: string | null,
      model?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type CreateSmsMutationVariables = {
  input: createSmsInput,
};

export type CreateSmsMutation = {
  createSms: ( {
      __typename: "Sms",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      _id: string,
      thread_id: number,
      address: string,
      person: number,
      date: string,
      date_sent: string,
      protocol: number,
      read: number,
      status: number,
      type: number,
      body: string,
      service_center: string,
      locked: number,
      error_code: number,
      sub_id: number,
      seen: number,
      deletable: number,
      sim_slot: number,
      hidden: number,
      app_id: number,
      msg_id: number,
      reserved: number,
      pri: number,
      teleservice_id: number,
      svc_cmd: number,
      roam_pending: number,
      spam_report: number,
      secret_mode: number,
      safe_message: number,
      favorite: number,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type UpdateSmsMutationVariables = {
  input: updateSmsInput,
};

export type UpdateSmsMutation = {
  updateSms: ( {
      __typename: "Sms",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      _id: string,
      thread_id: number,
      address: string,
      person: number,
      date: string,
      date_sent: string,
      protocol: number,
      read: number,
      status: number,
      type: number,
      body: string,
      service_center: string,
      locked: number,
      error_code: number,
      sub_id: number,
      seen: number,
      deletable: number,
      sim_slot: number,
      hidden: number,
      app_id: number,
      msg_id: number,
      reserved: number,
      pri: number,
      teleservice_id: number,
      svc_cmd: number,
      roam_pending: number,
      spam_report: number,
      secret_mode: number,
      safe_message: number,
      favorite: number,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type DeleteSmsMutationVariables = {
  id: string,
};

export type DeleteSmsMutation = {
  deleteSms: boolean,
};

export type CreateInvalidPaymentMutationVariables = {
  input: createInvalidPaymentInput,
};

export type CreateInvalidPaymentMutation = {
  createInvalidPayment: ( {
      __typename: "InvalidPayment",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      momoText: string,
      momoNumber: string,
      momoAmount: number,
      momoName: string,
      refNumber: string,
      reason: string,
      isOpen: boolean,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type UpdateInvalidPaymentMutationVariables = {
  input: updateInvalidPaymentInput,
};

export type UpdateInvalidPaymentMutation = {
  updateInvalidPayment: ( {
      __typename: "InvalidPayment",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      momoText: string,
      momoNumber: string,
      momoAmount: number,
      momoName: string,
      refNumber: string,
      reason: string,
      isOpen: boolean,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type DeleteInvalidPaymentMutationVariables = {
  id: string,
};

export type DeleteInvalidPaymentMutation = {
  deleteInvalidPayment?: boolean | null,
};

export type DeleteAutoPartMutationVariables = {
  id: string,
};

export type DeleteAutoPartMutation = {
  deleteAutoPart: ( {
      __typename: "OperationResponse",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type DeleteAutoPartsFromQuoteMutationVariables = {
  id: string,
  autoPartId: string,
};

export type DeleteAutoPartsFromQuoteMutation = {
  deleteAutoPartsFromQuote?: boolean | null,
};

export type DeleteAutoPartSubCategoryMutationVariables = {
  id: string,
  autoPartId: string,
};

export type DeleteAutoPartSubCategoryMutation = {
  deleteAutoPartSubCategory?: boolean | null,
};

export type DeleteAutoPartCategoryMutationVariables = {
  id: string,
  autoPartId: string,
};

export type DeleteAutoPartCategoryMutation = {
  deleteAutoPartCategory?: boolean | null,
};

export type UpdatePhotoMutationVariables = {
  input: updatePhotoInput,
};

export type UpdatePhotoMutation = {
  updatePhoto?:  {
    __typename: "Photo",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    imageS3Name: string,
    description?: string | null,
    contentType?: string | null,
    title?: string | null,
    creatorType: Creator,
    creatorId: string,
  } | null,
};

export type DeletePhotoMutationVariables = {
  id: string,
};

export type DeletePhotoMutation = {
  deletePhoto: boolean,
};

export type CreateRequestMutationVariables = {
  input: createRequestInput,
};

export type CreateRequestMutation = {
  createRequest?:  {
    __typename: "RequestResponse",
    request:  {
      __typename: "Request",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      make?: string | null,
      model?: string | null,
      year?: number | null,
      fuel?: string | null,
      gearbox?: GearBox | null,
      variant?: string | null,
      VIN?: string | null,
      engineNumber?: string | null,
      status: Status,
      contactDetails?: string | null,
      otherDetails?: string | null,
      creatorId: string,
      creatorType: Creator,
      quoteIds?: Array< string > | null,
      vehicleId?: string | null,
    },
    autoPart:  {
      __typename: "AutoPartRequest",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quantity: number,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    vehicle?:  {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: "Vehicle",
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | null,
  } | null,
};

export type UpdateRequestMutationVariables = {
  input: updateRequestInput,
};

export type UpdateRequestMutation = {
  updateRequest?:  {
    __typename: "RequestResponse",
    request:  {
      __typename: "Request",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      make?: string | null,
      model?: string | null,
      year?: number | null,
      fuel?: string | null,
      gearbox?: GearBox | null,
      variant?: string | null,
      VIN?: string | null,
      engineNumber?: string | null,
      status: Status,
      contactDetails?: string | null,
      otherDetails?: string | null,
      creatorId: string,
      creatorType: Creator,
      quoteIds?: Array< string > | null,
      vehicleId?: string | null,
    },
    autoPart:  {
      __typename: "AutoPartRequest",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quantity: number,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    vehicle?:  {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: "Vehicle",
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | null,
  } | null,
};

export type DeleteRequestMutationVariables = {
  id: string,
};

export type DeleteRequestMutation = {
  deleteRequest?: boolean | null,
};

export type CreatePaymentMutationVariables = {
  input: createPaymentInput,
};

export type CreatePaymentMutation = {
  createPayment: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type UpdatePaymentMutationVariables = {
  input: updatePaymentInput,
};

export type UpdatePaymentMutation = {
  updatePayment: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type ChangePaymentProcessorMutationVariables = {
  input: changePaymentProcessorInput,
};

export type ChangePaymentProcessorMutation = {
  changePaymentProcessor: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type RetryPaymentMutationVariables = {
  id: string,
};

export type RetryPaymentMutation = {
  retryPayment?:  {
    __typename: "RetryPaymentResponse",
    hasRetryBeenInitialized: boolean,
    message: string,
  } | null,
};

export type UpdateManualCustomerPaymentMutationVariables = {
  input: updateManualCustomerPaymentInput,
};

export type UpdateManualCustomerPaymentMutation = {
  updateManualCustomerPayment: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeletePaymentMutationVariables = {
  creator: byCreatorInput,
  paymentId: string,
};

export type DeletePaymentMutation = {
  deletePayment?: boolean | null,
};

export type CreateRefundMutationVariables = {
  input: createRefundInput,
};

export type CreateRefundMutation = {
  createRefund: ( {
      __typename: "RefundResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      invalid?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        creatorId: string,
        creatorType: Creator,
        status: Status,
        paymentId: string,
        refundAmount: number,
        reason: Status,
        refundMethod:  {
          __typename: string,
          id: string,
          type: PaymentType,
        },
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type UpdateRefundMutationVariables = {
  input: updateRefundInput,
};

export type UpdateRefundMutation = {
  updateRefund: ( {
      __typename: "RefundResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      invalid?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        creatorId: string,
        creatorType: Creator,
        status: Status,
        paymentId: string,
        refundAmount: number,
        reason: Status,
        refundMethod:  {
          __typename: string,
          id: string,
          type: PaymentType,
        },
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeleteRefundMutationVariables = {
  id: string,
};

export type DeleteRefundMutation = {
  deleteRefund?: boolean | null,
};

export type CreatePayoutMutationVariables = {
  input: createPayoutInput,
};

export type CreatePayoutMutation = {
  createPayout?:  {
    __typename: "PayoutResponse",
    payout:  {
      __typename: "Payout",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      orderId: string,
      partnerId: string,
      status: Status,
      total: number,
      currency: string,
      paymentMethod:  {
        __typename: "PaymentMethodDef",
        id: string,
        type: PaymentType,
      },
      transactionFee: number,
      otherDetails?: string | null,
    },
    order:  {
      __typename: "OrderResponse",
      order:  {
        __typename: "Order",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: "PartnerOrder",
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: "Payment",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: "EventHistoryItem",
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: "Fees",
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: "ReceivedFees",
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    },
    to:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
  } | null,
};

export type UpdatePayoutMutationVariables = {
  input: updatePayoutInput,
};

export type UpdatePayoutMutation = {
  updatePayout?:  {
    __typename: "PayoutResponse",
    payout:  {
      __typename: "Payout",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      orderId: string,
      partnerId: string,
      status: Status,
      total: number,
      currency: string,
      paymentMethod:  {
        __typename: "PaymentMethodDef",
        id: string,
        type: PaymentType,
      },
      transactionFee: number,
      otherDetails?: string | null,
    },
    order:  {
      __typename: "OrderResponse",
      order:  {
        __typename: "Order",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: "PartnerOrder",
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: "Payment",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: "EventHistoryItem",
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: "Fees",
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: "ReceivedFees",
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    },
    to:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
  } | null,
};

export type DeletePayoutMutationVariables = {
  creator: byCreatorInput,
  payoutId: string,
};

export type DeletePayoutMutation = {
  deletePayout?: boolean | null,
};

export type CreateOrderMutationVariables = {
  input: createOrderInput,
};

export type CreateOrderMutation = {
  createOrder: ( {
      __typename: "OrderResponse",
      order:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: string,
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type UpdateOrderMutationVariables = {
  input: updateOrderInput,
};

export type UpdateOrderMutation = {
  updateOrder: ( {
      __typename: "OrderResponse",
      order:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: string,
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeleteQuotesFromOrderMutationVariables = {
  input: deleteQuoteFromOrderInput,
};

export type DeleteQuotesFromOrderMutation = {
  deleteQuotesFromOrder:  Array<( {
      __typename: "OrderResponse",
      order:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: string,
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null > | null,
};

export type DeleteOrderMutationVariables = {
  id: string,
};

export type DeleteOrderMutation = {
  deleteOrder?: boolean | null,
};

export type CreateVehicleMutationVariables = {
  input: createVehicleInput_,
};

export type CreateVehicleMutation = {
  createVehicle?:  {
    __typename: "VehicleResponse",
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      created: string,
      modified: string,
      discontinued?: boolean | null,
      entityType: EntityType,
      make: string,
      model: string,
      engineNumber: string,
      variant: string,
      year?: number | null,
      fuel?: string | null,
      gearbox?: GearBox | null,
      plateNumber?: string | null,
      VIN?: string | null,
      creatorType: Creator,
      creatorId: string,
      otherDetails?: string | null,
    },
    owner: ( {
        __typename: "Partner",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        name: string,
        imageS3Name?: string | null,
        email?: string | null,
        phoneNumber: string,
        address?: string | null,
        bankAccount?: string | null,
        mainContactPerson: string,
        subscribedCategories?: Array< string > | null,
        mobileMoneyDetails?:  Array< {
          __typename: string,
          processor: PaymentProcessors,
          name: string,
          number: string,
        } > | null,
      } | {
        __typename: "Customer",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        firstName: string,
        familyName: string,
        gender?: Gender | null,
        imageS3Name?: string | null,
        birthdate?: string | null,
        phoneNumber: string,
        email?: string | null,
      }
    ),
  } | null,
};

export type UpdateVehicleMutationVariables = {
  input: updateVehicleInput,
};

export type UpdateVehicleMutation = {
  updateVehicle: ( {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type DeleteVehicleMutationVariables = {
  id: string,
};

export type DeleteVehicleMutation = {
  deleteVehicle: ( {
      __typename: "OperationResponse",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type CreatePaymentMethodMutationVariables = {
  input: createPaymentMethodInput,
};

export type CreatePaymentMethodMutation = {
  createPaymentMethod:  Array<( {
      __typename: "MobileMoney",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      phoneNumber: string,
      country: string,
      mobileMoneyProvider?: MobileMoneyProvider | null,
      creatorId: string,
      creatorType: Creator,
    } | {
      __typename: "CreditCard",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      country: string,
      cardProvider?: CardProvider | null,
      creatorId: string,
      creatorType: Creator,
      expMonth: number,
      expYear: number,
      last4: string,
    }
  ) | null > | null,
};

export type UpdatePaymentMethodMutationVariables = {
  input: updatePaymentMethodInput,
};

export type UpdatePaymentMethodMutation = {
  updatePaymentMethod?: boolean | null,
};

export type DeletePaymentMethodMutationVariables = {
  creator: byCreatorInput,
  provider: paymentMethodInput,
};

export type DeletePaymentMethodMutation = {
  deletePaymentMethod?: boolean | null,
};

export type CreateQuoteMutationVariables = {
  input: createQuoteInput,
};

export type CreateQuoteMutation = {
  createQuote?:  {
    __typename: "QuoteResponse",
    quote:  {
      __typename: "Quote",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      comment?: string | null,
      status: Status,
      expiryDate: string,
      total: number,
      isNew?: boolean | null,
    },
    partner:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
    request:  {
      __typename: "RequestResponse",
      request:  {
        __typename: "Request",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        discontinued?: boolean | null,
        make?: string | null,
        model?: string | null,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        variant?: string | null,
        VIN?: string | null,
        engineNumber?: string | null,
        status: Status,
        contactDetails?: string | null,
        otherDetails?: string | null,
        creatorId: string,
        creatorType: Creator,
        quoteIds?: Array< string > | null,
        vehicleId?: string | null,
      },
      autoPart:  {
        __typename: "AutoPartRequest",
        autoPart:  {
          __typename: "AutoPart",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          subCategory?:  {
            __typename: "AutoPartSubCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            description?: string | null,
          } | null,
          description?: string | null,
          imageS3Name?: string | null,
          fitmentNotes?: string | null,
          weightG?: number | null,
          widthMM?: number | null,
          heightMM?: number | null,
          lengthMM?: number | null,
          isUniversal?: boolean | null,
          modelCode?: string | null,
          regYearAndmonth?: string | null,
          mileage?: string | null,
          transmissionType?: string | null,
          engineModel?: string | null,
          engineSize?: string | null,
          fuel?: string | null,
          drive?: string | null,
          manufacturer?: string | null,
          OEM?: string | null,
          src?: Array< string | null > | null,
          make?: string | null,
          model?: string | null,
        },
        quantity: number,
      },
      photos?:  Array< {
        __typename: "Photo",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        imageS3Name: string,
        description?: string | null,
        contentType?: string | null,
        title?: string | null,
        creatorType: Creator,
        creatorId: string,
      } > | null,
      vehicle?:  {
        __typename: "VehicleResponse",
        vehicle:  {
          __typename: "Vehicle",
          id: string,
          created: string,
          modified: string,
          discontinued?: boolean | null,
          entityType: EntityType,
          make: string,
          model: string,
          engineNumber: string,
          variant: string,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          plateNumber?: string | null,
          VIN?: string | null,
          creatorType: Creator,
          creatorId: string,
          otherDetails?: string | null,
        },
        owner: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } | null,
    },
    autoPart:  {
      __typename: "AutoPartQuoteOutput",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quotedPrice: number,
      isSold: boolean,
      soldPrice?: number | null,
      comment?: string | null,
      paymentOptions:  Array< {
        __typename: "Fees",
        finalFee: number,
        chargeFee: number,
        serviceFee: number,
        deliveryFee: number,
        deliveryWithdrawalFee: number,
        withdrawalFee: number,
        processorChargePercentage: number,
        processorChargeRate: number,
        processorPayoutPercentage: number,
        processorPayoutRate: number,
        serviceFeeDate: string,
        partnerPayoutAmount: number,
        deliveryPayoutAmount: number,
        isDeliveryIncluded: boolean,
        processor: PaymentProcessors,
        quotedPrice: number,
        payoutFee: number,
        discount?: number | null,
      } >,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
  } | null,
};

export type UpdateQuoteMutationVariables = {
  input: updateQuoteInput,
};

export type UpdateQuoteMutation = {
  updateQuote?:  {
    __typename: "QuoteResponse",
    quote:  {
      __typename: "Quote",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      comment?: string | null,
      status: Status,
      expiryDate: string,
      total: number,
      isNew?: boolean | null,
    },
    partner:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
    request:  {
      __typename: "RequestResponse",
      request:  {
        __typename: "Request",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        discontinued?: boolean | null,
        make?: string | null,
        model?: string | null,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        variant?: string | null,
        VIN?: string | null,
        engineNumber?: string | null,
        status: Status,
        contactDetails?: string | null,
        otherDetails?: string | null,
        creatorId: string,
        creatorType: Creator,
        quoteIds?: Array< string > | null,
        vehicleId?: string | null,
      },
      autoPart:  {
        __typename: "AutoPartRequest",
        autoPart:  {
          __typename: "AutoPart",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          subCategory?:  {
            __typename: "AutoPartSubCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            description?: string | null,
          } | null,
          description?: string | null,
          imageS3Name?: string | null,
          fitmentNotes?: string | null,
          weightG?: number | null,
          widthMM?: number | null,
          heightMM?: number | null,
          lengthMM?: number | null,
          isUniversal?: boolean | null,
          modelCode?: string | null,
          regYearAndmonth?: string | null,
          mileage?: string | null,
          transmissionType?: string | null,
          engineModel?: string | null,
          engineSize?: string | null,
          fuel?: string | null,
          drive?: string | null,
          manufacturer?: string | null,
          OEM?: string | null,
          src?: Array< string | null > | null,
          make?: string | null,
          model?: string | null,
        },
        quantity: number,
      },
      photos?:  Array< {
        __typename: "Photo",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        imageS3Name: string,
        description?: string | null,
        contentType?: string | null,
        title?: string | null,
        creatorType: Creator,
        creatorId: string,
      } > | null,
      vehicle?:  {
        __typename: "VehicleResponse",
        vehicle:  {
          __typename: "Vehicle",
          id: string,
          created: string,
          modified: string,
          discontinued?: boolean | null,
          entityType: EntityType,
          make: string,
          model: string,
          engineNumber: string,
          variant: string,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          plateNumber?: string | null,
          VIN?: string | null,
          creatorType: Creator,
          creatorId: string,
          otherDetails?: string | null,
        },
        owner: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } | null,
    },
    autoPart:  {
      __typename: "AutoPartQuoteOutput",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quotedPrice: number,
      isSold: boolean,
      soldPrice?: number | null,
      comment?: string | null,
      paymentOptions:  Array< {
        __typename: "Fees",
        finalFee: number,
        chargeFee: number,
        serviceFee: number,
        deliveryFee: number,
        deliveryWithdrawalFee: number,
        withdrawalFee: number,
        processorChargePercentage: number,
        processorChargeRate: number,
        processorPayoutPercentage: number,
        processorPayoutRate: number,
        serviceFeeDate: string,
        partnerPayoutAmount: number,
        deliveryPayoutAmount: number,
        isDeliveryIncluded: boolean,
        processor: PaymentProcessors,
        quotedPrice: number,
        payoutFee: number,
        discount?: number | null,
      } >,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
  } | null,
};

export type DeleteQuoteMutationVariables = {
  id: string,
};

export type DeleteQuoteMutation = {
  deleteQuote: ( {
      __typename: "OperationResponse",
      success?: boolean | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type CreateMessageMutationVariables = {
  input: createMessageInput,
};

export type CreateMessageMutation = {
  createMessage?:  {
    __typename: "Message",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    message: string,
    conversationId: string,
    senderId: string,
    senderType: Creator,
    imageS3Name?: string | null,
  } | null,
};

export type DeleteMessageMutationVariables = {
  id: string,
  conversationId: string,
};

export type DeleteMessageMutation = {
  deleteMessage?: boolean | null,
};

export type CreateConversationMutationVariables = {
  input: createConversationInput,
};

export type CreateConversationMutation = {
  createConversation?:  {
    __typename: "Conversation",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    members:  Array<( {
        __typename: "Partner",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        name: string,
        imageS3Name?: string | null,
        email?: string | null,
        phoneNumber: string,
        address?: string | null,
        bankAccount?: string | null,
        mainContactPerson: string,
        subscribedCategories?: Array< string > | null,
        mobileMoneyDetails?:  Array< {
          __typename: string,
          processor: PaymentProcessors,
          name: string,
          number: string,
        } > | null,
      } | {
        __typename: "Customer",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        firstName: string,
        familyName: string,
        gender?: Gender | null,
        imageS3Name?: string | null,
        birthdate?: string | null,
        phoneNumber: string,
        email?: string | null,
      }
    ) | null >,
  } | null,
};

export type DeleteConversationMutationVariables = {
  id: string,
};

export type DeleteConversationMutation = {
  deleteConversation?: boolean | null,
};

export type UpdateStatusMutationVariables = {
  input: updateStatusInput,
};

export type UpdateStatusMutation = {
  updateStatus?: boolean | null,
};

export type GetOrderQueryVariables = {
  id: string,
  partnerId?: string | null,
};

export type GetOrderQuery = {
  getOrder: ( {
      __typename: "OrderResponse",
      order:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: string,
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type SearchOrdersQueryVariables = {
  input: searchInput,
};

export type SearchOrdersQuery = {
  searchOrders: ( {
      __typename: "OrderSearchResults",
      all?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byStatus?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byPartner?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byCustomer?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byDateRange?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byPartnerAndStatus?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byCustomerAndStatus?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byStatusAndDateRange?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byPartnerAndDateRange?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byCustomerAndDateRange?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byStatusAndDateRangeAndPartner?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
      byStatusAndDateRangeAndCustomer?:  {
        __typename: string,
        orders?:  Array< {
          __typename: string,
          order:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: string,
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: string,
            quote:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: string,
              request:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: string,
                autoPart:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: string,
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: string,
                vehicle:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: string,
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: string,
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: string,
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        } > | null,
        nextToken?: string | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetInvalidPaymentQueryVariables = {
  id: string,
};

export type GetInvalidPaymentQuery = {
  getInvalidPayment: ( {
      __typename: "InvalidPayment",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      momoText: string,
      momoNumber: string,
      momoAmount: number,
      momoName: string,
      refNumber: string,
      reason: string,
      isOpen: boolean,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetInvalidPaymentsQuery = {
  getInvalidPayments: ( {
      __typename: "InvalidPaymentPage",
      invalidPayments?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        momoText: string,
        momoNumber: string,
        momoAmount: number,
        momoName: string,
        refNumber: string,
        reason: string,
        isOpen: boolean,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetPaymentByRefNumberQueryVariables = {
  ref: string,
};

export type GetPaymentByRefNumberQuery = {
  getPaymentByRefNumber: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetPaymentByTransactionIdQueryVariables = {
  id: string,
};

export type GetPaymentByTransactionIdQuery = {
  getPaymentByTransactionId: ( {
      __typename: "PaymentResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      order:  {
        __typename: string,
        order:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          quotes:  Array< {
            __typename: string,
            partnerId: string,
            quoteIds: Array< string >,
          } >,
          customerId: string,
          status: Status,
        },
        quotes?:  Array< {
          __typename: string,
          quote:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            comment?: string | null,
            status: Status,
            expiryDate: string,
            total: number,
            isNew?: boolean | null,
          },
          partner:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          },
          request:  {
            __typename: string,
            request:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              make?: string | null,
              model?: string | null,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              variant?: string | null,
              VIN?: string | null,
              engineNumber?: string | null,
              status: Status,
              contactDetails?: string | null,
              otherDetails?: string | null,
              creatorId: string,
              creatorType: Creator,
              quoteIds?: Array< string > | null,
              vehicleId?: string | null,
            },
            autoPart:  {
              __typename: string,
              autoPart:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: string,
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quantity: number,
            },
            photos?:  Array< {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
            vehicle?:  {
              __typename: string,
              vehicle:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                discontinued?: boolean | null,
                entityType: EntityType,
                make: string,
                model: string,
                engineNumber: string,
                variant: string,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                plateNumber?: string | null,
                VIN?: string | null,
                creatorType: Creator,
                creatorId: string,
                otherDetails?: string | null,
              },
              owner: ( {
                  __typename: "Partner",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  name: string,
                  imageS3Name?: string | null,
                  email?: string | null,
                  phoneNumber: string,
                  address?: string | null,
                  bankAccount?: string | null,
                  mainContactPerson: string,
                  subscribedCategories?: Array< string > | null,
                  mobileMoneyDetails?:  Array< {
                    __typename: string,
                    processor: PaymentProcessors,
                    name: string,
                    number: string,
                  } > | null,
                } | {
                  __typename: "Customer",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  createdBy?: string | null,
                  firstName: string,
                  familyName: string,
                  gender?: Gender | null,
                  imageS3Name?: string | null,
                  birthdate?: string | null,
                  phoneNumber: string,
                  email?: string | null,
                }
              ),
            } | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quotedPrice: number,
            isSold: boolean,
            soldPrice?: number | null,
            comment?: string | null,
            paymentOptions:  Array< {
              __typename: string,
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            } >,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
        } > | null,
        payment?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: string,
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: string,
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        } | null,
      },
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ) | null,
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type SearchPaymentsQueryVariables = {
  input: searchInput,
};

export type SearchPaymentsQuery = {
  searchPayments?:  {
    __typename: "PaymentSearchResults",
    all?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatus?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreator?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byDateRange?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreatorAndStatus?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRange?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreatorAndDateRange?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRangeAndCreator?:  {
      __typename: "PaymentPage",
      payments?:  Array< {
        __typename: "PaymentResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        paymentMethod: ( {
            __typename: "MobileMoney",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            phoneNumber: string,
            country: string,
            mobileMoneyProvider?: MobileMoneyProvider | null,
            creatorId: string,
            creatorType: Creator,
          } | {
            __typename: "CreditCard",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            country: string,
            cardProvider?: CardProvider | null,
            creatorId: string,
            creatorType: Creator,
            expMonth: number,
            expYear: number,
            last4: string,
          }
        ) | null,
        creator: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetRefundQueryVariables = {
  id: string,
};

export type GetRefundQuery = {
  getRefund: ( {
      __typename: "RefundResponse",
      payment:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      },
      invalid?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        creatorId: string,
        creatorType: Creator,
        status: Status,
        paymentId: string,
        refundAmount: number,
        reason: Status,
        refundMethod:  {
          __typename: string,
          id: string,
          type: PaymentType,
        },
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type SearchRefundsQueryVariables = {
  input: searchInput,
};

export type SearchRefundsQuery = {
  searchRefunds?:  {
    __typename: "RefundSearchResults",
    all?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatus?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomer?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byDateRange?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomerAndStatus?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRange?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomerAndDateRange?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRangeAndCustomer?:  {
      __typename: "RefundsPage",
      refunds?:  Array< {
        __typename: "RefundResponse",
        payment:  {
          __typename: "Payment",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          creatorId: string,
          creatorType: string,
          currency: string,
          otherDetails?: string | null,
          referenceId: string,
          transactionId?: string | null,
          notes?: string | null,
          momoPhoneNumber: string,
          momoText: string,
          status: Status,
          eventHistory:  Array< {
            __typename: "EventHistoryItem",
            event: EventType,
            date: string,
            note: string,
          } >,
          event?: EventType | null,
          processor: PaymentProcessors,
          fee:  {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          },
          receivedFees?:  {
            __typename: "ReceivedFees",
            receivedTotal: number,
            overpayment: number,
            underpayment: number,
          } | null,
        },
        invalid?:  {
          __typename: "Refund",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          creatorId: string,
          creatorType: Creator,
          status: Status,
          paymentId: string,
          refundAmount: number,
          reason: Status,
          refundMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetPayoutQueryVariables = {
  id: string,
};

export type GetPayoutQuery = {
  getPayout?:  {
    __typename: "PayoutResponse",
    payout:  {
      __typename: "Payout",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      orderId: string,
      partnerId: string,
      status: Status,
      total: number,
      currency: string,
      paymentMethod:  {
        __typename: "PaymentMethodDef",
        id: string,
        type: PaymentType,
      },
      transactionFee: number,
      otherDetails?: string | null,
    },
    order:  {
      __typename: "OrderResponse",
      order:  {
        __typename: "Order",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: "PartnerOrder",
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: "Payment",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: "EventHistoryItem",
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: "Fees",
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: "ReceivedFees",
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    },
    to:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
  } | null,
};

export type SearchPayoutsQueryVariables = {
  input: searchInput,
};

export type SearchPayoutsQuery = {
  searchPayouts?:  {
    __typename: "PayoutSearchResults",
    all?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatus?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreator?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byDateRange?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreatorAndStatus?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRange?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byCreatorAndDateRange?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRangeAndCreator?:  {
      __typename: "PayoutPage",
      payouts?:  Array< {
        __typename: "PayoutResponse",
        payout:  {
          __typename: "Payout",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          orderId: string,
          partnerId: string,
          status: Status,
          total: number,
          currency: string,
          paymentMethod:  {
            __typename: "PaymentMethodDef",
            id: string,
            type: PaymentType,
          },
          transactionFee: number,
          otherDetails?: string | null,
        },
        order:  {
          __typename: "OrderResponse",
          order:  {
            __typename: "Order",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            quotes:  Array< {
              __typename: "PartnerOrder",
              partnerId: string,
              quoteIds: Array< string >,
            } >,
            customerId: string,
            status: Status,
          },
          quotes?:  Array< {
            __typename: "QuoteResponse",
            quote:  {
              __typename: "Quote",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              discontinued?: boolean | null,
              comment?: string | null,
              status: Status,
              expiryDate: string,
              total: number,
              isNew?: boolean | null,
            },
            partner:  {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: "MobileMoneyDetail",
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            },
            request:  {
              __typename: "RequestResponse",
              request:  {
                __typename: "Request",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                discontinued?: boolean | null,
                make?: string | null,
                model?: string | null,
                year?: number | null,
                fuel?: string | null,
                gearbox?: GearBox | null,
                variant?: string | null,
                VIN?: string | null,
                engineNumber?: string | null,
                status: Status,
                contactDetails?: string | null,
                otherDetails?: string | null,
                creatorId: string,
                creatorType: Creator,
                quoteIds?: Array< string > | null,
                vehicleId?: string | null,
              },
              autoPart:  {
                __typename: "AutoPartRequest",
                autoPart:  {
                  __typename: "AutoPart",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  subCategory?:  {
                    __typename: "AutoPartSubCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    category?:  {
                      __typename: "AutoPartCategory",
                      id: string,
                      created: string,
                      modified: string,
                      entityType: EntityType,
                      name: string,
                      imageS3Name?: string | null,
                      description?: string | null,
                    } | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                  imageS3Name?: string | null,
                  fitmentNotes?: string | null,
                  weightG?: number | null,
                  widthMM?: number | null,
                  heightMM?: number | null,
                  lengthMM?: number | null,
                  isUniversal?: boolean | null,
                  modelCode?: string | null,
                  regYearAndmonth?: string | null,
                  mileage?: string | null,
                  transmissionType?: string | null,
                  engineModel?: string | null,
                  engineSize?: string | null,
                  fuel?: string | null,
                  drive?: string | null,
                  manufacturer?: string | null,
                  OEM?: string | null,
                  src?: Array< string | null > | null,
                  make?: string | null,
                  model?: string | null,
                },
                quantity: number,
              },
              photos?:  Array< {
                __typename: "Photo",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                imageS3Name: string,
                description?: string | null,
                contentType?: string | null,
                title?: string | null,
                creatorType: Creator,
                creatorId: string,
              } > | null,
              vehicle?:  {
                __typename: "VehicleResponse",
                vehicle:  {
                  __typename: "Vehicle",
                  id: string,
                  created: string,
                  modified: string,
                  discontinued?: boolean | null,
                  entityType: EntityType,
                  make: string,
                  model: string,
                  engineNumber: string,
                  variant: string,
                  year?: number | null,
                  fuel?: string | null,
                  gearbox?: GearBox | null,
                  plateNumber?: string | null,
                  VIN?: string | null,
                  creatorType: Creator,
                  creatorId: string,
                  otherDetails?: string | null,
                },
                owner: ( {
                    __typename: "Partner",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    name: string,
                    imageS3Name?: string | null,
                    email?: string | null,
                    phoneNumber: string,
                    address?: string | null,
                    bankAccount?: string | null,
                    mainContactPerson: string,
                    subscribedCategories?: Array< string > | null,
                    mobileMoneyDetails?:  Array< {
                      __typename: string,
                      processor: PaymentProcessors,
                      name: string,
                      number: string,
                    } > | null,
                  } | {
                    __typename: "Customer",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    createdBy?: string | null,
                    firstName: string,
                    familyName: string,
                    gender?: Gender | null,
                    imageS3Name?: string | null,
                    birthdate?: string | null,
                    phoneNumber: string,
                    email?: string | null,
                  }
                ),
              } | null,
            },
            autoPart:  {
              __typename: "AutoPartQuoteOutput",
              autoPart:  {
                __typename: "AutoPart",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                subCategory?:  {
                  __typename: "AutoPartSubCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  category?:  {
                    __typename: "AutoPartCategory",
                    id: string,
                    created: string,
                    modified: string,
                    entityType: EntityType,
                    name: string,
                    imageS3Name?: string | null,
                    description?: string | null,
                  } | null,
                  description?: string | null,
                } | null,
                description?: string | null,
                imageS3Name?: string | null,
                fitmentNotes?: string | null,
                weightG?: number | null,
                widthMM?: number | null,
                heightMM?: number | null,
                lengthMM?: number | null,
                isUniversal?: boolean | null,
                modelCode?: string | null,
                regYearAndmonth?: string | null,
                mileage?: string | null,
                transmissionType?: string | null,
                engineModel?: string | null,
                engineSize?: string | null,
                fuel?: string | null,
                drive?: string | null,
                manufacturer?: string | null,
                OEM?: string | null,
                src?: Array< string | null > | null,
                make?: string | null,
                model?: string | null,
              },
              quotedPrice: number,
              isSold: boolean,
              soldPrice?: number | null,
              comment?: string | null,
              paymentOptions:  Array< {
                __typename: "Fees",
                finalFee: number,
                chargeFee: number,
                serviceFee: number,
                deliveryFee: number,
                deliveryWithdrawalFee: number,
                withdrawalFee: number,
                processorChargePercentage: number,
                processorChargeRate: number,
                processorPayoutPercentage: number,
                processorPayoutRate: number,
                serviceFeeDate: string,
                partnerPayoutAmount: number,
                deliveryPayoutAmount: number,
                isDeliveryIncluded: boolean,
                processor: PaymentProcessors,
                quotedPrice: number,
                payoutFee: number,
                discount?: number | null,
              } >,
            },
            photos?:  Array< {
              __typename: "Photo",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              imageS3Name: string,
              description?: string | null,
              contentType?: string | null,
              title?: string | null,
              creatorType: Creator,
              creatorId: string,
            } > | null,
          } > | null,
          payment?:  {
            __typename: "Payment",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            orderId: string,
            creatorId: string,
            creatorType: string,
            currency: string,
            otherDetails?: string | null,
            referenceId: string,
            transactionId?: string | null,
            notes?: string | null,
            momoPhoneNumber: string,
            momoText: string,
            status: Status,
            eventHistory:  Array< {
              __typename: "EventHistoryItem",
              event: EventType,
              date: string,
              note: string,
            } >,
            event?: EventType | null,
            processor: PaymentProcessors,
            fee:  {
              __typename: "Fees",
              finalFee: number,
              chargeFee: number,
              serviceFee: number,
              deliveryFee: number,
              deliveryWithdrawalFee: number,
              withdrawalFee: number,
              processorChargePercentage: number,
              processorChargeRate: number,
              processorPayoutPercentage: number,
              processorPayoutRate: number,
              serviceFeeDate: string,
              partnerPayoutAmount: number,
              deliveryPayoutAmount: number,
              isDeliveryIncluded: boolean,
              processor: PaymentProcessors,
              quotedPrice: number,
              payoutFee: number,
              discount?: number | null,
            },
            receivedFees?:  {
              __typename: "ReceivedFees",
              receivedTotal: number,
              overpayment: number,
              underpayment: number,
            } | null,
          } | null,
        },
        to:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
      } > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetPaymentMethodQueryVariables = {
  input: paymentMethodInput,
};

export type GetPaymentMethodQuery = {
  getPaymentMethod?:  {
    __typename: "PaymentMethodResponse",
    paymentMethod: ( {
        __typename: "MobileMoney",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        phoneNumber: string,
        country: string,
        mobileMoneyProvider?: MobileMoneyProvider | null,
        creatorId: string,
        creatorType: Creator,
      } | {
        __typename: "CreditCard",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        country: string,
        cardProvider?: CardProvider | null,
        creatorId: string,
        creatorType: Creator,
        expMonth: number,
        expYear: number,
        last4: string,
      }
    ),
    creator: ( {
        __typename: "Partner",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        name: string,
        imageS3Name?: string | null,
        email?: string | null,
        phoneNumber: string,
        address?: string | null,
        bankAccount?: string | null,
        mainContactPerson: string,
        subscribedCategories?: Array< string > | null,
        mobileMoneyDetails?:  Array< {
          __typename: string,
          processor: PaymentProcessors,
          name: string,
          number: string,
        } > | null,
      } | {
        __typename: "Customer",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        firstName: string,
        familyName: string,
        gender?: Gender | null,
        imageS3Name?: string | null,
        birthdate?: string | null,
        phoneNumber: string,
        email?: string | null,
      }
    ),
  } | null,
};

export type GetPaymentMethodsQueryVariables = {
  input: byCreatorInput,
  nextToken?: string | null,
};

export type GetPaymentMethodsQuery = {
  getPaymentMethods:  {
    __typename: "PaymentMethodPage",
    paymentMethods?:  Array< {
      __typename: "PaymentMethodResponse",
      paymentMethod: ( {
          __typename: "MobileMoney",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          phoneNumber: string,
          country: string,
          mobileMoneyProvider?: MobileMoneyProvider | null,
          creatorId: string,
          creatorType: Creator,
        } | {
          __typename: "CreditCard",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          country: string,
          cardProvider?: CardProvider | null,
          creatorId: string,
          creatorType: Creator,
          expMonth: number,
          expYear: number,
          last4: string,
        }
      ),
      creator: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } > | null,
    nextToken?: string | null,
  },
};

export type GetVehicleCategoryTreeQueryVariables = {
  input?: vehicleCategoryTreeInput | null,
};

export type GetVehicleCategoryTreeQuery = {
  getVehicleCategoryTree: ( {
      __typename: "VehicleCategoryResponse",
      category:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        generation: number,
      },
      ancestors?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        generation: number,
      } | null > | null,
      descendants?:  Array< {
        __typename: string,
        categories?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          generation: number,
        } > | null,
        generation: number,
        nextToken?: string | null,
      } | null > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetVehicleCategoryByTypeQueryVariables = {
  input?: vehicleCategoryTypeInput | null,
};

export type GetVehicleCategoryByTypeQuery = {
  getVehicleCategoryByType: ( {
      __typename: "VehicleCategoryPage",
      categories?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        generation: number,
      } > | null,
      generation: number,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    createdBy?: string | null,
    firstName: string,
    familyName: string,
    gender?: Gender | null,
    imageS3Name?: string | null,
    birthdate?: string | null,
    phoneNumber: string,
    email?: string | null,
  } | null,
};

export type GetCustomersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCustomersQuery = {
  getCustomers:  {
    __typename: "CustomerPage",
    customers?:  Array< {
      __typename: "Customer",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      firstName: string,
      familyName: string,
      gender?: Gender | null,
      imageS3Name?: string | null,
      birthdate?: string | null,
      phoneNumber: string,
      email?: string | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetVehicleQueryVariables = {
  id: string,
};

export type GetVehicleQuery = {
  getVehicle: ( {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetVehiclesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetVehiclesQuery = {
  getVehicles:  {
    __typename: "VehiclePage",
    vehicles?:  Array< {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: "Vehicle",
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | null > | null,
    nextToken?: string | null,
  },
};

export type GetVehiclesByCreatorQueryVariables = {
  input: byCreatorInput_,
};

export type GetVehiclesByCreatorQuery = {
  getVehiclesByCreator: ( {
      __typename: "VehiclePage",
      vehicles?:  Array< {
        __typename: string,
        vehicle:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          discontinued?: boolean | null,
          entityType: EntityType,
          make: string,
          model: string,
          engineNumber: string,
          variant: string,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          plateNumber?: string | null,
          VIN?: string | null,
          creatorType: Creator,
          creatorId: string,
          otherDetails?: string | null,
        },
        owner: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } | null > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetPartnerQueryVariables = {
  id: string,
};

export type GetPartnerQuery = {
  getPartner: ( {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: string,
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type GetPartnersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPartnersQuery = {
  getPartners:  {
    __typename: "PartnerPage",
    partners?:  Array< {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetAutoPartQueryVariables = {
  id: string,
};

export type GetAutoPartQuery = {
  getAutoPart: ( {
      __typename: "AutoPart",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      category?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        description?: string | null,
      } | null,
      subCategory?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        description?: string | null,
      } | null,
      description?: string | null,
      imageS3Name?: string | null,
      fitmentNotes?: string | null,
      weightG?: number | null,
      widthMM?: number | null,
      heightMM?: number | null,
      lengthMM?: number | null,
      isUniversal?: boolean | null,
      modelCode?: string | null,
      regYearAndmonth?: string | null,
      mileage?: string | null,
      transmissionType?: string | null,
      engineModel?: string | null,
      engineSize?: string | null,
      fuel?: string | null,
      drive?: string | null,
      manufacturer?: string | null,
      OEM?: string | null,
      src?: Array< string | null > | null,
      make?: string | null,
      model?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetAutoPartsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAutoPartsQuery = {
  getAutoParts: ( {
      __typename: "AutoPartPage",
      autoParts?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetSmsQueryVariables = {
  id: string,
};

export type GetSmsQuery = {
  getSms: ( {
      __typename: "Sms",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      _id: string,
      thread_id: number,
      address: string,
      person: number,
      date: string,
      date_sent: string,
      protocol: number,
      read: number,
      status: number,
      type: number,
      body: string,
      service_center: string,
      locked: number,
      error_code: number,
      sub_id: number,
      seen: number,
      deletable: number,
      sim_slot: number,
      hidden: number,
      app_id: number,
      msg_id: number,
      reserved: number,
      pri: number,
      teleservice_id: number,
      svc_cmd: number,
      roam_pending: number,
      spam_report: number,
      secret_mode: number,
      safe_message: number,
      favorite: number,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetSmsesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSmsesQuery = {
  getSmses: ( {
      __typename: "SmsPage",
      smses?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        _id: string,
        thread_id: number,
        address: string,
        person: number,
        date: string,
        date_sent: string,
        protocol: number,
        read: number,
        status: number,
        type: number,
        body: string,
        service_center: string,
        locked: number,
        error_code: number,
        sub_id: number,
        seen: number,
        deletable: number,
        sim_slot: number,
        hidden: number,
        app_id: number,
        msg_id: number,
        reserved: number,
        pri: number,
        teleservice_id: number,
        svc_cmd: number,
        roam_pending: number,
        spam_report: number,
        secret_mode: number,
        safe_message: number,
        favorite: number,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetSmsesByDateRangeQueryVariables = {
  input: byDateRangeInput,
};

export type GetSmsesByDateRangeQuery = {
  getSmsesByDateRange: ( {
      __typename: "SmsPage",
      smses?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        _id: string,
        thread_id: number,
        address: string,
        person: number,
        date: string,
        date_sent: string,
        protocol: number,
        read: number,
        status: number,
        type: number,
        body: string,
        service_center: string,
        locked: number,
        error_code: number,
        sub_id: number,
        seen: number,
        deletable: number,
        sim_slot: number,
        hidden: number,
        app_id: number,
        msg_id: number,
        reserved: number,
        pri: number,
        teleservice_id: number,
        svc_cmd: number,
        roam_pending: number,
        spam_report: number,
        secret_mode: number,
        safe_message: number,
        favorite: number,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetAutoPartCategoryQueryVariables = {
  id: string,
};

export type GetAutoPartCategoryQuery = {
  getAutoPartCategory?:  {
    __typename: "AutoPartCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    description?: string | null,
  } | null,
};

export type GetAutoPartCategoriesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAutoPartCategoriesQuery = {
  getAutoPartCategories: ( {
      __typename: "AutoPartCategoryPage",
      autoPartCategories?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        description?: string | null,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetAutoPartSubCategoryQueryVariables = {
  id: string,
};

export type GetAutoPartSubCategoryQuery = {
  getAutoPartSubCategory?:  {
    __typename: "AutoPartSubCategory",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    imageS3Name?: string | null,
    category?:  {
      __typename: "AutoPartCategory",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      imageS3Name?: string | null,
      description?: string | null,
    } | null,
    description?: string | null,
  } | null,
};

export type GetAutoPartSubCategoriesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAutoPartSubCategoriesQuery = {
  getAutoPartSubCategories: ( {
      __typename: "AutoPartSubCategoryPage",
      autoPartSubCategories?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        description?: string | null,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type GetAutoPartSubCategoriesByCategoryQueryVariables = {
  categoryId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAutoPartSubCategoriesByCategoryQuery = {
  getAutoPartSubCategoriesByCategory: ( {
      __typename: "AutoPartSubCategoryPage",
      autoPartSubCategories?:  Array< {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        category?:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        description?: string | null,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ),
};

export type SearchAutoPartsQueryVariables = {
  searchTerm?: string | null,
};

export type SearchAutoPartsQuery = {
  searchAutoParts?:  Array< {
    __typename: "AutoPart",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    name: string,
    category?:  {
      __typename: "AutoPartCategory",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      imageS3Name?: string | null,
      description?: string | null,
    } | null,
    subCategory?:  {
      __typename: "AutoPartSubCategory",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      name: string,
      imageS3Name?: string | null,
      category?:  {
        __typename: "AutoPartCategory",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        imageS3Name?: string | null,
        description?: string | null,
      } | null,
      description?: string | null,
    } | null,
    description?: string | null,
    imageS3Name?: string | null,
    fitmentNotes?: string | null,
    weightG?: number | null,
    widthMM?: number | null,
    heightMM?: number | null,
    lengthMM?: number | null,
    isUniversal?: boolean | null,
    modelCode?: string | null,
    regYearAndmonth?: string | null,
    mileage?: string | null,
    transmissionType?: string | null,
    engineModel?: string | null,
    engineSize?: string | null,
    fuel?: string | null,
    drive?: string | null,
    manufacturer?: string | null,
    OEM?: string | null,
    src?: Array< string | null > | null,
    make?: string | null,
    model?: string | null,
  } | null > | null,
};

export type GetPhotoQueryVariables = {
  id: string,
};

export type GetPhotoQuery = {
  getPhoto?:  {
    __typename: "Photo",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    imageS3Name: string,
    description?: string | null,
    contentType?: string | null,
    title?: string | null,
    creatorType: Creator,
    creatorId: string,
  } | null,
};

export type GetPhotosQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPhotosQuery = {
  getPhotos:  {
    __typename: "PhotoPage",
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetPhotosByCreatorQueryVariables = {
  input?: byCreatorInput | null,
};

export type GetPhotosByCreatorQuery = {
  getPhotosByCreator:  {
    __typename: "PhotoPage",
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    nextToken?: string | null,
  },
};

export type GetRequestQueryVariables = {
  id: string,
};

export type GetRequestQuery = {
  getRequest?:  {
    __typename: "RequestResponse",
    request:  {
      __typename: "Request",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      make?: string | null,
      model?: string | null,
      year?: number | null,
      fuel?: string | null,
      gearbox?: GearBox | null,
      variant?: string | null,
      VIN?: string | null,
      engineNumber?: string | null,
      status: Status,
      contactDetails?: string | null,
      otherDetails?: string | null,
      creatorId: string,
      creatorType: Creator,
      quoteIds?: Array< string > | null,
      vehicleId?: string | null,
    },
    autoPart:  {
      __typename: "AutoPartRequest",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quantity: number,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    vehicle?:  {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: "Vehicle",
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | null,
  } | null,
};

export type GetServiceFeeQueryVariables = {
  date: string,
};

export type GetServiceFeeQuery = {
  getServiceFee: ( {
      __typename: "ServiceFee",
      fee: number,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type SearchRequestsQueryVariables = {
  input: searchInput,
};

export type SearchRequestsQuery = {
  searchRequests?:  {
    __typename: "RequestSearchResults",
    all?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatus?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomer?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byDateRange?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomerAndStatus?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRange?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byCustomerAndDateRange?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRangeAndCustomer?:  {
      __typename: "RequestPage",
      requests?:  Array< {
        __typename: "RequestResponse",
        request:  {
          __typename: "Request",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          make?: string | null,
          model?: string | null,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          variant?: string | null,
          VIN?: string | null,
          engineNumber?: string | null,
          status: Status,
          contactDetails?: string | null,
          otherDetails?: string | null,
          creatorId: string,
          creatorType: Creator,
          quoteIds?: Array< string > | null,
          vehicleId?: string | null,
        },
        autoPart:  {
          __typename: "AutoPartRequest",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quantity: number,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
        vehicle?:  {
          __typename: "VehicleResponse",
          vehicle:  {
            __typename: "Vehicle",
            id: string,
            created: string,
            modified: string,
            discontinued?: boolean | null,
            entityType: EntityType,
            make: string,
            model: string,
            engineNumber: string,
            variant: string,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            plateNumber?: string | null,
            VIN?: string | null,
            creatorType: Creator,
            creatorId: string,
            otherDetails?: string | null,
          },
          owner: ( {
              __typename: "Partner",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              name: string,
              imageS3Name?: string | null,
              email?: string | null,
              phoneNumber: string,
              address?: string | null,
              bankAccount?: string | null,
              mainContactPerson: string,
              subscribedCategories?: Array< string > | null,
              mobileMoneyDetails?:  Array< {
                __typename: string,
                processor: PaymentProcessors,
                name: string,
                number: string,
              } > | null,
            } | {
              __typename: "Customer",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              createdBy?: string | null,
              firstName: string,
              familyName: string,
              gender?: Gender | null,
              imageS3Name?: string | null,
              birthdate?: string | null,
              phoneNumber: string,
              email?: string | null,
            }
          ),
        } | null,
      } > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetRequestsByCreatorQueryVariables = {
  id?: string | null,
  creatorType: Creator,
};

export type GetRequestsByCreatorQuery = {
  getRequestsByCreator?:  Array< {
    __typename: "Request",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    discontinued?: boolean | null,
    make?: string | null,
    model?: string | null,
    year?: number | null,
    fuel?: string | null,
    gearbox?: GearBox | null,
    variant?: string | null,
    VIN?: string | null,
    engineNumber?: string | null,
    status: Status,
    contactDetails?: string | null,
    otherDetails?: string | null,
    creatorId: string,
    creatorType: Creator,
    quoteIds?: Array< string > | null,
    vehicleId?: string | null,
  } > | null,
};

export type GetQuoteQueryVariables = {
  id: string,
};

export type GetQuoteQuery = {
  getQuote?:  {
    __typename: "QuoteResponse",
    quote:  {
      __typename: "Quote",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      comment?: string | null,
      status: Status,
      expiryDate: string,
      total: number,
      isNew?: boolean | null,
    },
    partner:  {
      __typename: "Partner",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      createdBy?: string | null,
      name: string,
      imageS3Name?: string | null,
      email?: string | null,
      phoneNumber: string,
      address?: string | null,
      bankAccount?: string | null,
      mainContactPerson: string,
      subscribedCategories?: Array< string > | null,
      mobileMoneyDetails?:  Array< {
        __typename: "MobileMoneyDetail",
        processor: PaymentProcessors,
        name: string,
        number: string,
      } > | null,
    },
    request:  {
      __typename: "RequestResponse",
      request:  {
        __typename: "Request",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        discontinued?: boolean | null,
        make?: string | null,
        model?: string | null,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        variant?: string | null,
        VIN?: string | null,
        engineNumber?: string | null,
        status: Status,
        contactDetails?: string | null,
        otherDetails?: string | null,
        creatorId: string,
        creatorType: Creator,
        quoteIds?: Array< string > | null,
        vehicleId?: string | null,
      },
      autoPart:  {
        __typename: "AutoPartRequest",
        autoPart:  {
          __typename: "AutoPart",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          subCategory?:  {
            __typename: "AutoPartSubCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            description?: string | null,
          } | null,
          description?: string | null,
          imageS3Name?: string | null,
          fitmentNotes?: string | null,
          weightG?: number | null,
          widthMM?: number | null,
          heightMM?: number | null,
          lengthMM?: number | null,
          isUniversal?: boolean | null,
          modelCode?: string | null,
          regYearAndmonth?: string | null,
          mileage?: string | null,
          transmissionType?: string | null,
          engineModel?: string | null,
          engineSize?: string | null,
          fuel?: string | null,
          drive?: string | null,
          manufacturer?: string | null,
          OEM?: string | null,
          src?: Array< string | null > | null,
          make?: string | null,
          model?: string | null,
        },
        quantity: number,
      },
      photos?:  Array< {
        __typename: "Photo",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        imageS3Name: string,
        description?: string | null,
        contentType?: string | null,
        title?: string | null,
        creatorType: Creator,
        creatorId: string,
      } > | null,
      vehicle?:  {
        __typename: "VehicleResponse",
        vehicle:  {
          __typename: "Vehicle",
          id: string,
          created: string,
          modified: string,
          discontinued?: boolean | null,
          entityType: EntityType,
          make: string,
          model: string,
          engineNumber: string,
          variant: string,
          year?: number | null,
          fuel?: string | null,
          gearbox?: GearBox | null,
          plateNumber?: string | null,
          VIN?: string | null,
          creatorType: Creator,
          creatorId: string,
          otherDetails?: string | null,
        },
        owner: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } | null,
    },
    autoPart:  {
      __typename: "AutoPartQuoteOutput",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quotedPrice: number,
      isSold: boolean,
      soldPrice?: number | null,
      comment?: string | null,
      paymentOptions:  Array< {
        __typename: "Fees",
        finalFee: number,
        chargeFee: number,
        serviceFee: number,
        deliveryFee: number,
        deliveryWithdrawalFee: number,
        withdrawalFee: number,
        processorChargePercentage: number,
        processorChargeRate: number,
        processorPayoutPercentage: number,
        processorPayoutRate: number,
        serviceFeeDate: string,
        partnerPayoutAmount: number,
        deliveryPayoutAmount: number,
        isDeliveryIncluded: boolean,
        processor: PaymentProcessors,
        quotedPrice: number,
        payoutFee: number,
        discount?: number | null,
      } >,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
  } | null,
};

export type GetQuotesByRequestQueryVariables = {
  input?: byRequestInput | null,
};

export type GetQuotesByRequestQuery = {
  getQuotesByRequest: ( {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type SearchQuotesQueryVariables = {
  input: searchInput,
};

export type SearchQuotesQuery = {
  searchQuotes?:  {
    __typename: "QuoteSearchResults",
    all?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatus?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byPartner?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byDateRange?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byPartnerAndStatus?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRange?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byPartnerAndDateRange?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
    byStatusAndDateRangeAndPartner?:  {
      __typename: "QuotePage",
      quotes?:  Array< {
        __typename: "QuoteResponse",
        quote:  {
          __typename: "Quote",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: "MobileMoneyDetail",
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: "RequestResponse",
          request:  {
            __typename: "Request",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: "AutoPartRequest",
            autoPart:  {
              __typename: "AutoPart",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: "AutoPartSubCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: "AutoPartCategory",
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: "Photo",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: "VehicleResponse",
            vehicle:  {
              __typename: "Vehicle",
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: "AutoPartQuoteOutput",
          autoPart:  {
            __typename: "AutoPart",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: "AutoPartCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: "AutoPartSubCategory",
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: "AutoPartCategory",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: "Fees",
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: "Photo",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetConversationsByCreatorQueryVariables = {
  id?: string | null,
  limit?: number | null,
  nextToken?: string | null,
  senderType: Creator,
};

export type GetConversationsByCreatorQuery = {
  getConversationsByCreator?:  {
    __typename: "ConversationPage",
    conversations?:  Array< {
      __typename: "ConversationResponse",
      conversation:  {
        __typename: "Conversation",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        members:  Array<( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ) | null >,
      },
      messages?:  Array< {
        __typename: "MessageResponse",
        date: string,
        message:  {
          __typename: "Message",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          message: string,
          conversationId: string,
          senderId: string,
          senderType: Creator,
          imageS3Name?: string | null,
        },
        sender: ( {
            __typename: "Partner",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            name: string,
            imageS3Name?: string | null,
            email?: string | null,
            phoneNumber: string,
            address?: string | null,
            bankAccount?: string | null,
            mainContactPerson: string,
            subscribedCategories?: Array< string > | null,
            mobileMoneyDetails?:  Array< {
              __typename: string,
              processor: PaymentProcessors,
              name: string,
              number: string,
            } > | null,
          } | {
            __typename: "Customer",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            createdBy?: string | null,
            firstName: string,
            familyName: string,
            gender?: Gender | null,
            imageS3Name?: string | null,
            birthdate?: string | null,
            phoneNumber: string,
            email?: string | null,
          }
        ),
      } > | null,
    } > | null,
    nextToken?: string | null,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation?:  {
    __typename: "ConversationResponse",
    conversation:  {
      __typename: "Conversation",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      members:  Array<( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ) | null >,
    },
    messages?:  Array< {
      __typename: "MessageResponse",
      date: string,
      message:  {
        __typename: "Message",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        message: string,
        conversationId: string,
        senderId: string,
        senderType: Creator,
        imageS3Name?: string | null,
      },
      sender: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } > | null,
  } | null,
};

export type GetMessagesByConversationQueryVariables = {
  id: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMessagesByConversationQuery = {
  getMessagesByConversation?:  {
    __typename: "MessagePage",
    messages?:  Array< {
      __typename: "MessageResponse",
      date: string,
      message:  {
        __typename: "Message",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        message: string,
        conversationId: string,
        senderId: string,
        senderType: Creator,
        imageS3Name?: string | null,
      },
      sender: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage?:  {
    __typename: "MessageResponse",
    date: string,
    message:  {
      __typename: "Message",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      message: string,
      conversationId: string,
      senderId: string,
      senderType: Creator,
      imageS3Name?: string | null,
    },
    sender: ( {
        __typename: "Partner",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        name: string,
        imageS3Name?: string | null,
        email?: string | null,
        phoneNumber: string,
        address?: string | null,
        bankAccount?: string | null,
        mainContactPerson: string,
        subscribedCategories?: Array< string > | null,
        mobileMoneyDetails?:  Array< {
          __typename: string,
          processor: PaymentProcessors,
          name: string,
          number: string,
        } > | null,
      } | {
        __typename: "Customer",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        createdBy?: string | null,
        firstName: string,
        familyName: string,
        gender?: Gender | null,
        imageS3Name?: string | null,
        birthdate?: string | null,
        phoneNumber: string,
        email?: string | null,
      }
    ),
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage?:  {
    __typename: "Message",
    id: string,
    created: string,
    modified: string,
    entityType: EntityType,
    message: string,
    conversationId: string,
    senderId: string,
    senderType: Creator,
    imageS3Name?: string | null,
  } | null,
};

export type OnCreateOrderSubscriptionVariables = {
  conversationId: string,
};

export type OnCreateOrderSubscription = {
  onCreateOrder: ( {
      __typename: "OrderResponse",
      order:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        quotes:  Array< {
          __typename: string,
          partnerId: string,
          quoteIds: Array< string >,
        } >,
        customerId: string,
        status: Status,
      },
      quotes?:  Array< {
        __typename: string,
        quote:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          discontinued?: boolean | null,
          comment?: string | null,
          status: Status,
          expiryDate: string,
          total: number,
          isNew?: boolean | null,
        },
        partner:  {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        },
        request:  {
          __typename: string,
          request:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            discontinued?: boolean | null,
            make?: string | null,
            model?: string | null,
            year?: number | null,
            fuel?: string | null,
            gearbox?: GearBox | null,
            variant?: string | null,
            VIN?: string | null,
            engineNumber?: string | null,
            status: Status,
            contactDetails?: string | null,
            otherDetails?: string | null,
            creatorId: string,
            creatorType: Creator,
            quoteIds?: Array< string > | null,
            vehicleId?: string | null,
          },
          autoPart:  {
            __typename: string,
            autoPart:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              subCategory?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                category?:  {
                  __typename: string,
                  id: string,
                  created: string,
                  modified: string,
                  entityType: EntityType,
                  name: string,
                  imageS3Name?: string | null,
                  description?: string | null,
                } | null,
                description?: string | null,
              } | null,
              description?: string | null,
              imageS3Name?: string | null,
              fitmentNotes?: string | null,
              weightG?: number | null,
              widthMM?: number | null,
              heightMM?: number | null,
              lengthMM?: number | null,
              isUniversal?: boolean | null,
              modelCode?: string | null,
              regYearAndmonth?: string | null,
              mileage?: string | null,
              transmissionType?: string | null,
              engineModel?: string | null,
              engineSize?: string | null,
              fuel?: string | null,
              drive?: string | null,
              manufacturer?: string | null,
              OEM?: string | null,
              src?: Array< string | null > | null,
              make?: string | null,
              model?: string | null,
            },
            quantity: number,
          },
          photos?:  Array< {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            imageS3Name: string,
            description?: string | null,
            contentType?: string | null,
            title?: string | null,
            creatorType: Creator,
            creatorId: string,
          } > | null,
          vehicle?:  {
            __typename: string,
            vehicle:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              discontinued?: boolean | null,
              entityType: EntityType,
              make: string,
              model: string,
              engineNumber: string,
              variant: string,
              year?: number | null,
              fuel?: string | null,
              gearbox?: GearBox | null,
              plateNumber?: string | null,
              VIN?: string | null,
              creatorType: Creator,
              creatorId: string,
              otherDetails?: string | null,
            },
            owner: ( {
                __typename: "Partner",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                name: string,
                imageS3Name?: string | null,
                email?: string | null,
                phoneNumber: string,
                address?: string | null,
                bankAccount?: string | null,
                mainContactPerson: string,
                subscribedCategories?: Array< string > | null,
                mobileMoneyDetails?:  Array< {
                  __typename: string,
                  processor: PaymentProcessors,
                  name: string,
                  number: string,
                } > | null,
              } | {
                __typename: "Customer",
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                createdBy?: string | null,
                firstName: string,
                familyName: string,
                gender?: Gender | null,
                imageS3Name?: string | null,
                birthdate?: string | null,
                phoneNumber: string,
                email?: string | null,
              }
            ),
          } | null,
        },
        autoPart:  {
          __typename: string,
          autoPart:  {
            __typename: string,
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            category?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              description?: string | null,
            } | null,
            subCategory?:  {
              __typename: string,
              id: string,
              created: string,
              modified: string,
              entityType: EntityType,
              name: string,
              imageS3Name?: string | null,
              category?:  {
                __typename: string,
                id: string,
                created: string,
                modified: string,
                entityType: EntityType,
                name: string,
                imageS3Name?: string | null,
                description?: string | null,
              } | null,
              description?: string | null,
            } | null,
            description?: string | null,
            imageS3Name?: string | null,
            fitmentNotes?: string | null,
            weightG?: number | null,
            widthMM?: number | null,
            heightMM?: number | null,
            lengthMM?: number | null,
            isUniversal?: boolean | null,
            modelCode?: string | null,
            regYearAndmonth?: string | null,
            mileage?: string | null,
            transmissionType?: string | null,
            engineModel?: string | null,
            engineSize?: string | null,
            fuel?: string | null,
            drive?: string | null,
            manufacturer?: string | null,
            OEM?: string | null,
            src?: Array< string | null > | null,
            make?: string | null,
            model?: string | null,
          },
          quotedPrice: number,
          isSold: boolean,
          soldPrice?: number | null,
          comment?: string | null,
          paymentOptions:  Array< {
            __typename: string,
            finalFee: number,
            chargeFee: number,
            serviceFee: number,
            deliveryFee: number,
            deliveryWithdrawalFee: number,
            withdrawalFee: number,
            processorChargePercentage: number,
            processorChargeRate: number,
            processorPayoutPercentage: number,
            processorPayoutRate: number,
            serviceFeeDate: string,
            partnerPayoutAmount: number,
            deliveryPayoutAmount: number,
            isDeliveryIncluded: boolean,
            processor: PaymentProcessors,
            quotedPrice: number,
            payoutFee: number,
            discount?: number | null,
          } >,
        },
        photos?:  Array< {
          __typename: string,
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          imageS3Name: string,
          description?: string | null,
          contentType?: string | null,
          title?: string | null,
          creatorType: Creator,
          creatorId: string,
        } > | null,
      } > | null,
      payment?:  {
        __typename: string,
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        orderId: string,
        creatorId: string,
        creatorType: string,
        currency: string,
        otherDetails?: string | null,
        referenceId: string,
        transactionId?: string | null,
        notes?: string | null,
        momoPhoneNumber: string,
        momoText: string,
        status: Status,
        eventHistory:  Array< {
          __typename: string,
          event: EventType,
          date: string,
          note: string,
        } >,
        event?: EventType | null,
        processor: PaymentProcessors,
        fee:  {
          __typename: string,
          finalFee: number,
          chargeFee: number,
          serviceFee: number,
          deliveryFee: number,
          deliveryWithdrawalFee: number,
          withdrawalFee: number,
          processorChargePercentage: number,
          processorChargeRate: number,
          processorPayoutPercentage: number,
          processorPayoutRate: number,
          serviceFeeDate: string,
          partnerPayoutAmount: number,
          deliveryPayoutAmount: number,
          isDeliveryIncluded: boolean,
          processor: PaymentProcessors,
          quotedPrice: number,
          payoutFee: number,
          discount?: number | null,
        },
        receivedFees?:  {
          __typename: string,
          receivedTotal: number,
          overpayment: number,
          underpayment: number,
        } | null,
      } | null,
    } | {
      __typename: "ErrorResponse",
      code: ErrorCode,
      error?: string | null,
    }
  ) | null,
};

export type OnCustomerCreateRequestSubscription = {
  onCustomerCreateRequest?:  {
    __typename: "RequestResponse",
    request:  {
      __typename: "Request",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      discontinued?: boolean | null,
      make?: string | null,
      model?: string | null,
      year?: number | null,
      fuel?: string | null,
      gearbox?: GearBox | null,
      variant?: string | null,
      VIN?: string | null,
      engineNumber?: string | null,
      status: Status,
      contactDetails?: string | null,
      otherDetails?: string | null,
      creatorId: string,
      creatorType: Creator,
      quoteIds?: Array< string > | null,
      vehicleId?: string | null,
    },
    autoPart:  {
      __typename: "AutoPartRequest",
      autoPart:  {
        __typename: "AutoPart",
        id: string,
        created: string,
        modified: string,
        entityType: EntityType,
        name: string,
        category?:  {
          __typename: "AutoPartCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          description?: string | null,
        } | null,
        subCategory?:  {
          __typename: "AutoPartSubCategory",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          name: string,
          imageS3Name?: string | null,
          category?:  {
            __typename: "AutoPartCategory",
            id: string,
            created: string,
            modified: string,
            entityType: EntityType,
            name: string,
            imageS3Name?: string | null,
            description?: string | null,
          } | null,
          description?: string | null,
        } | null,
        description?: string | null,
        imageS3Name?: string | null,
        fitmentNotes?: string | null,
        weightG?: number | null,
        widthMM?: number | null,
        heightMM?: number | null,
        lengthMM?: number | null,
        isUniversal?: boolean | null,
        modelCode?: string | null,
        regYearAndmonth?: string | null,
        mileage?: string | null,
        transmissionType?: string | null,
        engineModel?: string | null,
        engineSize?: string | null,
        fuel?: string | null,
        drive?: string | null,
        manufacturer?: string | null,
        OEM?: string | null,
        src?: Array< string | null > | null,
        make?: string | null,
        model?: string | null,
      },
      quantity: number,
    },
    photos?:  Array< {
      __typename: "Photo",
      id: string,
      created: string,
      modified: string,
      entityType: EntityType,
      imageS3Name: string,
      description?: string | null,
      contentType?: string | null,
      title?: string | null,
      creatorType: Creator,
      creatorId: string,
    } > | null,
    vehicle?:  {
      __typename: "VehicleResponse",
      vehicle:  {
        __typename: "Vehicle",
        id: string,
        created: string,
        modified: string,
        discontinued?: boolean | null,
        entityType: EntityType,
        make: string,
        model: string,
        engineNumber: string,
        variant: string,
        year?: number | null,
        fuel?: string | null,
        gearbox?: GearBox | null,
        plateNumber?: string | null,
        VIN?: string | null,
        creatorType: Creator,
        creatorId: string,
        otherDetails?: string | null,
      },
      owner: ( {
          __typename: "Partner",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          name: string,
          imageS3Name?: string | null,
          email?: string | null,
          phoneNumber: string,
          address?: string | null,
          bankAccount?: string | null,
          mainContactPerson: string,
          subscribedCategories?: Array< string > | null,
          mobileMoneyDetails?:  Array< {
            __typename: string,
            processor: PaymentProcessors,
            name: string,
            number: string,
          } > | null,
        } | {
          __typename: "Customer",
          id: string,
          created: string,
          modified: string,
          entityType: EntityType,
          createdBy?: string | null,
          firstName: string,
          familyName: string,
          gender?: Gender | null,
          imageS3Name?: string | null,
          birthdate?: string | null,
          phoneNumber: string,
          email?: string | null,
        }
      ),
    } | null,
  } | null,
};
