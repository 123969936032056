import { ReactNode } from 'react';
import { atom } from 'recoil';
import {
  Customer,
  PaymentResponse,
  QuoteResponse,
  OrderResponse,
  RequestResponse,
  Partner,
} from '../API';
import { CurrentUser } from '../types';
import { localPersistEffect } from './atom-effects';

export const modalCarouselStateAtom = atom<boolean>({
  key: 'modal-carousel-state',
  default: false,
});

export const modalCarouselChildrenAtom = atom<ReactNode>({
  key: 'image-modal-carousel-children',
  default: null,
});

export const selectedPaymentsAtom = atom<PaymentResponse[] | null>({
  key: 'selected-payments-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedQuotesAtom = atom<QuoteResponse[] | null>({
  key: 'selected-quotes-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedOrdersAtom = atom<OrderResponse[] | null>({
  key: 'selected-orders-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedRequestsAtom = atom<RequestResponse[] | null>({
  key: 'selected-requests-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const paymentsAtom = atom<PaymentResponse[] | null | undefined>({
  key: 'payments-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const quotesAtom = atom<QuoteResponse[] | null | undefined>({
  key: 'quotes-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedCustomerAtom = atom<Customer | null | undefined>({
  key: 'selected-customer-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const selectedPartnerAtom = atom<Partner | null | undefined>({
  key: 'selected-partner-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const ordersAtom = atom<OrderResponse[] | null | undefined>({
  key: 'orders-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const requestsAtom = atom<RequestResponse[] | null | undefined>({
  key: 'requests-atom',
  default: null,
  effects_UNSTABLE: [localPersistEffect],
});

export const currentUserAtom = atom<CurrentUser>({
  key: 'current-user',
  default: { email: '', email_verified: false, phone_number: '', sub: '' },
  effects_UNSTABLE: [localPersistEffect],
});
