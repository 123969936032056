import { Heading, VStack, HStack, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { getCustomers, getPartners } from '../../api/queries';
import { orderBy } from 'lodash';
import CreatorChart from '../charts/creator-chart';

const UsersChart: React.FC = () => {
  const partners = useInfiniteQuery(['partner', { limit: 1000 }], getPartners, {
    cacheTime: 10000,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: previousPage => {
      return previousPage ? previousPage.nextToken : null;
    },
  });

  const customers = useInfiniteQuery(
    ['customers', { limit: 1000 }],
    getCustomers,
    {
      cacheTime: 10000,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      getNextPageParam: previousPage => {
        return previousPage ? previousPage.nextToken : null;
      },
    },
  );

  if (!customers.data) return <Heading> Loading </Heading>;
  if (!partners.data) return <Heading>Loading </Heading>;
  if (!customers.data.pages[0].customers || !partners.data.pages[0].partners)
    return <Heading>No User Data </Heading>;

  const currentCustomers = customers.data.pages[0].customers;
  const currentPartners = partners.data.pages[0].partners;

  const users = orderBy(
    [...currentCustomers, ...currentPartners],
    'created',
    'asc',
  );

  return (
    <VStack bg="beige" width={'full'} p={5} align={'start'} justify={'center'}>
      <HStack>
        <Text>
          Customers: <b>{currentCustomers.length}</b>
        </Text>{' '}
        <Spacer />{' '}
        <Text>
          Partners: <b>{currentPartners.length}</b>
        </Text>
      </HStack>
      <CreatorChart
        sortedByTime={users.map(x => ({
          time: new Date(x.created).toDateString(),
          item: x,
        }))}
      />
    </VStack>
  );
};

export default UsersChart;
