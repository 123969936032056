import { groupBy } from 'lodash';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  EntityType,
  OrderResponse,
  PaymentResponse,
  QuoteResponse,
  RequestResponse,
  Status,
} from '../../API';
import { brightColors } from '../../utils/constants';

interface TimeSeriesProps {
  sortedByTime: {
    time: string;
    type: EntityType;
    status: Status;
    item: RequestResponse | QuoteResponse | OrderResponse | PaymentResponse;
  }[];
  statusToDisplay: Status[];
}

const EntityWithStatusChart = ({
  sortedByTime,
  statusToDisplay,
}: TimeSeriesProps) => {
  const groupByDate = groupBy(
    sortedByTime.filter(x => statusToDisplay.includes(x.status)),
    'time',
  );

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={Object.entries(groupByDate).map(([key, value]) => {
            return {
              time: new Date(value[0].time).toDateString(),
              type: value[0].type,
              ...value.reduce((accumulator, current) => {
                let { status } = current;
                const count = value.filter(x => x.status === status).length;
                return count > 0
                  ? { ...accumulator, [status]: count }
                  : accumulator;
              }, {}),
            };
          })}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          {statusToDisplay.map((x, i) => (
            <Line
              key={x + i}
              connectNulls
              type="monotone"
              dataKey={x}
              stroke={brightColors[i % brightColors.length]}
            />
          ))}

          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EntityWithStatusChart;
