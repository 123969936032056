import { API, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { updatePaymentInput, UpdatePaymentMutation } from '../API';
import { updatePayment as updatePaymentMutation } from '../graphql/mutations';

export async function editPayment(input: updatePaymentInput) {
  const { data } = (await API.graphql({
    query: updatePaymentMutation,
    variables: {
      input,
    },
    authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  })) as { data: UpdatePaymentMutation };
  return data.updatePayment;
}
