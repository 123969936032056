import { groupBy } from 'lodash';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Customer, Partner } from '../../API';
import { brightColors, creators } from '../../utils/constants';

interface TimeSeriesProps {
  sortedByTime: {
    time: string;
    item: Customer | Partner;
  }[];
}

const CreatorChart = ({ sortedByTime }: TimeSeriesProps) => {
  const groupByDate = groupBy(sortedByTime, 'time');

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={Object.entries(groupByDate).map(([key, value]) => {
            return {
              time: new Date(value[0].time).toDateString(),
              ...value.reduce((accumulator, current) => {
                let { entityType } = current.item;
                const count = value.filter(
                  x => x.item.entityType === entityType,
                ).length;
                return count > 0
                  ? { ...accumulator, [entityType]: count }
                  : accumulator;
              }, {}),
            };
          })}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          {creators.map((x, i) => (
            <Line
              key={x + i}
              connectNulls
              type="monotone"
              dataKey={x}
              stroke={brightColors[i % brightColors.length]}
            />
          ))}

          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CreatorChart;
