import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { RequestResponse } from '../../API';
import { paths } from '../../utils/constants';
import AutoPartCard from './AutopartCard';
import RequestCard from './RequestCard';
import VehicleCard from './VehicleCard';

interface Props {
  requestResponse: RequestResponse;
}

export default function RequestResponseCard({ requestResponse }: Props) {
  return (
    <>
      <Box borderWidth="thin" minWidth={'300px'} p={3}>
        <HStack>
          <Spacer />
          <Heading textAlign={'center'} size="x-small">
            {requestResponse.autoPart.quantity} &times;{' '}
            {requestResponse.autoPart.autoPart.name}
          </Heading>
          {requestResponse.request.quoteIds &&
            requestResponse.request.quoteIds.length > 0 && (
              <Link
                to={paths.requestQuotes.replaceAll(
                  ':requestId',
                  requestResponse.request.id,
                )}
              >
                <Text color={'blue.500'}>
                  See Quotes ({requestResponse.request.quoteIds.length})
                </Text>
              </Link>
            )}
          <Spacer />
        </HStack>
        <Accordion bg="orange.100" my={5} width={'full'} allowToggle={true}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Request
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
              <RequestCard request={requestResponse.request} />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Auto Part
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
              <AutoPartCard autopart={requestResponse.autoPart} />
            </AccordionPanel>
          </AccordionItem>

          {requestResponse.vehicle && (
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Car
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                <VehicleCard vehicle={requestResponse.vehicle.vehicle} />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Box>
    </>
  );
}
