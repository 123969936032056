import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { AutoPartRequest } from '../../API';
import {
  autoPartDisplayAttributes,
  splitCamelCase,
} from '../../utils/functions';

interface Props {
  autopart: AutoPartRequest;
}

export default function AutoPartCard({ autopart }: Props) {
  return (
    <Stack spacing="0">
      {Object.entries(autoPartDisplayAttributes(autopart)).map(
        ([key, value]) => {
          return value ? (
            <HStack width={'full'} key={key}>
              <Text fontWeight={'bold'} size="xs">
                {splitCamelCase(key)}:
              </Text>
              <Spacer />
              <Text pt="2" fontSize="xs">
                {value}
              </Text>
              )
            </HStack>
          ) : null;
        },
      )}
    </Stack>
  );
}
