import { Card, CardHeader } from '@chakra-ui/card';
import { Heading, HStack, Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import moment from 'moment';

import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { byRequestInput } from '../../API';
import { getQuotesByRequest } from '../../api/queries';
import PaymentOptionsDisplay from '../../components/payment-options';
import { splitCamelCase } from '../../utils/functions';

const RequestQuotes: React.FC = (): JSX.Element => {
  const { requestId } = useParams();
  const [input] = useState<byRequestInput>({
    request: requestId || '',
    limitAndToken: { limit: 100 },
  });

  const { data, isLoading } = useQuery(
    ['getQuotesByRequest', input],
    getQuotesByRequest,
    {
      cacheTime: 10000,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const noData = (
    <Heading width={'full'} alignSelf="center" textAlign={'center'} mt="40%">
      No Quote Data
    </Heading>
  );

  if (isLoading)
    return (
      <Heading width={'full'} alignSelf="center" textAlign={'center'} mt="40%">
        Loading
      </Heading>
    );

  if (!data) return noData;

  if (!data.quotes) return noData;

  const initQuote = data.quotes[0];

  if (!initQuote) return noData;

  const vehicleResponse = initQuote.request.vehicle;

  if (!vehicleResponse)
    return (
      <Heading>
        No Vehicle Data for Request: {initQuote.request.request.id}.
      </Heading>
    );

  const {
    vehicle: { make, model, year, variant, engineNumber, VIN },
  } = vehicleResponse;

  const { autoPart } = initQuote.request;

  return (
    <VStack>
      <Card width={'90%'} p="5" bg="orange.100">
        <CardHeader textAlign={'center'}>
          <Heading size="sm">
            {autoPart.quantity} &times;
            {autoPart.autoPart.name}
          </Heading>
          <Text fontSize={'x-small'} fontWeight="bold" color="blue.500">
            <Spacer />
            {[make, model, year, variant, engineNumber, VIN].map((x, i) =>
              x ? (
                <>
                  {' '}
                  {x} {i < 4 && '|'}
                </>
              ) : null,
            )}
            <Spacer />
          </Text>
        </CardHeader>
      </Card>
      {data.quotes.map(
        ({ quote, autoPart: { quotedPrice, paymentOptions }, partner }) => {
          return (
            <Stack key={quote.id} bg="orange.100" my={5} width={'90%'} p="5">
              {Object.entries({
                actualPrice: 'K' + quotedPrice,
                quoteId: quote.id,
                expiry: moment(quote.expiryDate).fromNow(),
                quoteStatus: quote.status,
                partnerName: partner.name,
                partnerPhone: partner.phoneNumber,
                partnerEmail: partner.email,
                partnerId: partner.id,
                paymentOptions: paymentOptions,
              }).map(([key, value]) => {
                return value ? (
                  <HStack key={key}>
                    <Text fontWeight={'bold'} size="xs">
                      {splitCamelCase(key)}:
                    </Text>
                    <Spacer />
                    {typeof value === 'string' ? (
                      <Text fontSize="xs">{value}</Text>
                    ) : key === 'paymentOptions' ? (
                      <PaymentOptionsDisplay paymentOptions={value} />
                    ) : (
                      <pre>{JSON.stringify(value, null, 2)}</pre>
                    )}
                  </HStack>
                ) : null;
              })}
            </Stack>
          );
        },
      )}
    </VStack>
  );
};

export default RequestQuotes;
