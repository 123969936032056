import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import { Vehicle } from '../../API';
import {
  vehicleDisplayAttributes,
  splitCamelCase,
} from '../../utils/functions';

interface Props {
  vehicle: Vehicle;
}

export default function VehicleCard({ vehicle }: Props) {
  return (
    <Stack spacing="0">
      {Object.entries(vehicleDisplayAttributes(vehicle)).map(([key, value]) => {
        return value ? (
          <HStack width={'full'} key={key}>
            <Text fontWeight={'bold'} size="xs">
              {splitCamelCase(key)}:
            </Text>
            <Spacer />

            <Text pt="2" fontSize="xs">
              {value}
            </Text>
          </HStack>
        ) : null;
      })}
    </Stack>
  );
}
