import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { QuoteResponse } from '../../API';
import { paths } from '../../utils/constants';
import QuoteCard from './QuoteCard';
import RequestResponseCard from './RequestResponseCard';

interface Props {
  quoteResponse: QuoteResponse;
}

export default function QuoteResponseCard({ quoteResponse }: Props) {
  const quoteIds: string[] =
    quoteResponse.request.request.quoteIds &&
    quoteResponse.request.request.quoteIds.length
      ? quoteResponse.request.request.quoteIds.filter(x => x)
      : [];

  return (
    <>
      <Box borderWidth="thin" minWidth={'300px'} p={3}>
        <HStack>
          <Spacer />
          <Heading textAlign={'center'} size="x-small">
            {quoteResponse.request.autoPart.quantity} &times;{' '}
            {quoteResponse.autoPart.autoPart.name}
          </Heading>
          {quoteIds.length && (
            <Link
              to={paths.requestQuotes.replaceAll(
                ':requestId',
                quoteResponse.request.request.id,
              )}
            >
              <Text color={'blue.500'}>
                See Other Partner Quotes ({quoteIds.length})
              </Text>
            </Link>
          )}
          <Spacer />
        </HStack>
        <Accordion bg="orange.100" my={5} width={'full'} allowToggle={true}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Quote
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
              <QuoteCard quote={quoteResponse.quote} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <RequestResponseCard requestResponse={quoteResponse.request} />
      </Box>
    </>
  );
}
