import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Select,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useState } from 'react';
import ReactJson from 'react-json-view';
import { useRecoilValue } from 'recoil';
import { paymentsAtom, selectedPaymentsAtom } from '../../recoil/atoms';
import { events } from '../../utils/constants';
import EditPayment from './edit-payment';

export default function PaymentData() {
  const initPayments = useRecoilValue(selectedPaymentsAtom);

  const payments = useRecoilValue(paymentsAtom);

  const grouped = groupBy(payments, 'payment.event');

  const [selectedPayments, setSelectedPayments] = useState(initPayments);

  return (
    <Box width="full" p={6}>
      <Heading>Payments</Heading>
      <Select
        placeholder="Select Status"
        onChange={e => {
          if (!e.target.value) {
            return;
          }

          setSelectedPayments(grouped[e.target.value]);
        }}
      >
        {events.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
      {selectedPayments ? (
        selectedPayments.map(item => {
          const { creator, order, payment } = item;
          return (
            <Accordion
              bg="orange.100"
              my={5}
              width={'full'}
              allowToggle={true}
              key={payment.id}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Customer
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={creator}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Order
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={{ order: order.order, quotes: order.quotes }}
                  />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Payment
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <EditPayment payment={item} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <Box width="full" p={6} bg="orange.100">
          PAYMENT DATA YET
        </Box>
      )}
    </Box>
  );
}
