import { Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import type { Partner as TPartner } from '../../API';
import PartnerCard from '../../components/cards/PartnerCard';
import { selectedPartnerAtom } from '../../recoil/atoms';
import { paths } from '../../utils/constants';

type PartnerProps = {
  partner: TPartner;
};

export const Partner: React.FC<PartnerProps> = ({ partner }) => {
  const setSelectedPartner = useSetRecoilState(selectedPartnerAtom);
  const navigate = useNavigate();
  return (
    <VStack p={5} borderWidth={'3px'} rounded={'lg'}>
      <PartnerCard partner={partner} />
      <Button
        colorScheme={'orange'}
        variant={'solid'}
        onClick={() => {
          setSelectedPartner(partner);
          navigate(paths.partnerQuotes);
        }}
      >
        More Details
      </Button>
      <Button isDisabled={true} colorScheme={'orange'} variant={'solid'}>
        Create Quote
      </Button>
    </VStack>
  );
};
