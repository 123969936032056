import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { Fees } from '../../API';
import { splitCamelCase } from '../../utils/functions';

interface Props {
  paymentOptions: Fees[];
}

const PaymentOptionsDisplay = ({ paymentOptions }: Props) => {
  return (
    <Accordion fontSize={'xx-small'} allowToggle={true}>
      {paymentOptions.map((x, i) => {
        return (
          <AccordionItem key={x.finalFee + i}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Option {i + 1}
              </Box>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel maxHeight={'300px'} overflow={'scroll'}>
              <Stack spacing="0">
                {Object.entries(x).map(([key, value]) => {
                  return value ? (
                    <HStack spacing={'0'} key={key}>
                      <Text fontWeight={'bold'}>{splitCamelCase(key)}:</Text>
                      <Spacer />
                      <Text>
                        {key === 'serviceFeeDate' && typeof value === 'string'
                          ? moment(value).fromNow()
                          : key === 'isDeliveryIncluded' &&
                            typeof value === 'boolean'
                          ? value
                            ? 'Yes'
                            : 'No'
                          : value}
                      </Text>
                    </HStack>
                  ) : null;
                })}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default PaymentOptionsDisplay;
