import { Avatar, Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import type { Customer as TCustomer } from '../../API';
import { selectedCustomerAtom, currentUserAtom } from '../../recoil/atoms';
import { paths, allowedEmails } from '../../utils/constants';

type CustomerProps = {
  customer: TCustomer;
};

export const Customer: React.FC<CustomerProps> = ({ customer }) => {
  const { imageS3Name, firstName, familyName, phoneNumber, email } = customer;
  const setSelectedCustomer = useSetRecoilState(selectedCustomerAtom);
  const user = useRecoilValue(currentUserAtom);
  const navigate = useNavigate();
  return (
    <VStack p={5} borderWidth={'3px'} width={'full'}>
      <HStack>
        <Avatar size={'md'} src={imageS3Name!} />
        <Box>
          <Text fontSize={'md'}>
            {' '}
            {firstName} {familyName}{' '}
          </Text>

          <VStack spacing={2}>
            <Text
              as={'a'}
              fontWeight={'medium'}
              href={`tel:${phoneNumber}`}
              color={'green.600'}
            >
              {allowedEmails.includes(user.email)
                ? phoneNumber
                : 'Ask Admins for number'}
            </Text>
            <Text as={'a'} href={`mailto:${email}`} color={'green.600'}>
              {email}
            </Text>
          </VStack>
        </Box>
      </HStack>

      <Button
        colorScheme={'orange'}
        variant={'solid'}
        onClick={() => {
          setSelectedCustomer(customer);
          navigate(paths.customerRequests);
        }}
        width="full"
      >
        More Details
      </Button>
      <Button
        isDisabled={true}
        width="full"
        colorScheme={'orange'}
        variant={'solid'}
      >
        Create Request
      </Button>
    </VStack>
  );
};
