// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { Flex } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import Header from './components/header/header';
import Routes from './Routes';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { currentUserAtom } from './recoil/atoms';

const App = () => {
  const setUser = useSetRecoilState(currentUserAtom);

  useEffect(() => {
    async function getUser() {
      const CogUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setUser(CogUser.attributes);
    }
    getUser();
  }, []);

  return (
    <Flex
      direction={{
        base: 'column',
      }}
      width={'100vw'}
      maxW={'100vw'}
      minH={'100vh'}
    >
      <Header />
      <Routes />
    </Flex>
  );
};

export default App;
