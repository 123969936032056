import { Creator, EventType, Status } from '../API';

export const allowedEmails = ['arsene@riaworx.com', 'irene@riaworx.com'];

export const paths = {
  home: '/',
  payments: '/payments',
  partners: '/partners',
  deliveries: '/deliveries',
  partnerQuotes: '/partner/quotes',
  requests: '/requests',
  quotes: '/quotes',
  orders: '/orders',
  status: '/status',
  customers: '/customers',
  customerRequests: '/customers/requests',
  customerRequest: '/customers/requests/:requestId',
  requestQuotes: '/customers/requests/:requestId/quotes',
  requestQuote: '/customers/requests/:requestId/quotes/:quoteId',
  quoteOrder: '/customers/requests/:requestId/quotes/:quoteId/:orderId',
};

export const statuses = [
  Status.CLOSED,
  Status.OPEN,
  Status.EXPIRED,
  Status.IN_PROGRESS,
];
export const events = [
  //
  EventType.MTN_PAYMENT_SUCCESSFUL,
  EventType.MTN_PAYMENT_REJECTED,
  EventType.MTN_PAYMENT_FAILED,
  EventType.MTN_PAYMENT_TIMED_OUT,
  EventType.MANUAL_CUSTOMER_PAYED,
  EventType.MANUAL_PAYMENT_SUCCESSFUL,
  EventType.MANUAL_PAYMENT_FAILED,
  EventType.PAYMENT_CONFIRMED,
  EventType.PAYMENT_PENDING,
  EventType.WAITING_FOR_CUSTOMER_PAYMENT,
]; /* 
  EventType.PAYMENT_CREATED,
  EventType.PAYMENT_PROCESSOR_INITIATION_RESPONSE,
  EventType.PAYMENT_PROCESSOR_WEBHOOK_VERIFICATION,
  EventType.TEST_EVENT,
  EventType.CUSTOMER_OVERPAYED,
  EventType.CUSTOMER_UNDERPAYED,
  EventType.QUOTATION_NOT_EXPIRED,
  EventType.QUOTATION_EXPIRED,
  EventType.ORDER_CANCELLED,
  EventType.MOBILE_MONEY_WEBHOOK_RECEIVED,
  EventType.PAYMENT_CONFIRMATION_FAILED,
  EventType.PAYMENT_STATUS_CHECK_SUCCESS,
  EventType.PAYMENT_STATUS_CHECK_SUCCESS_FAIL,
  EventType.AIRTEL_PAYMENT_SUCCESSFUL,
  EventType.AIRTEL_PAYMENT_REJECTED,
  EventType.AIRTEL_PAYMENT_FAILED,
  EventType.AIRTEL_PAYMENT_TIMED_OUT,
  EventType.RETRY_PAYMENT,
]; */

export const brightColors = [
  'red',
  'green',
  'blue',
  'orange',
  '#FF355E',
  '#FF00CC',
  '#A83731',
  '#1B1B1B',
  '#E936A7',
  '#44D7A8',
  '#FFCFF1',
  '#FF878D',
  '#ABAD48',
  '#FF6037',
  '#66FF66',
  '#C9A0DC',
];

export const creators = [Creator.CUSTOMER, Creator.PARTNER];
