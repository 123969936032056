import {
  Box,
  Heading,
  HStack,
  IconButton,
  Select,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useQuery } from 'react-query';
import { searchPayments } from '../../api/queries';

import { groupBy, orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { EventType, searchInput } from '../../API';
import { paymentsAtom, selectedPaymentsAtom } from '../../recoil/atoms';
import { events, paths } from '../../utils/constants';
import EntityWithEventChart from '../charts/entity-with-event';

export default function PaymentSummary() {
  const [paymentInput] = useState<searchInput>({ all: { all: true } });
  const [eventList, setEventList] = useState<EventType[]>(events);
  const navigate = useNavigate();
  const { data } = useQuery(['payments', paymentInput], searchPayments, {
    cacheTime: 10000,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const setSelectedPayments = useSetRecoilState(selectedPaymentsAtom);
  const setPayments = useSetRecoilState(paymentsAtom);

  useEffect(() => {
    if (!data) return;
    if (!data.all) return;
    setPayments(data.all.payments);
  }, [data]);

  if (!data) return null;

  const groupedByEvent = groupBy(data.all?.payments, 'payment.event');

  if (Object.keys(groupedByEvent).length < 1) {
    return (
      <Box width="full" p={6} bg="beige">
        <Heading fontSize={'md'} my="3" color={'orange.500'}>
          NO PAYMENT DATA YET
        </Heading>
      </Box>
    );
  }

  const sortedByTime = orderBy(
    data.all?.payments?.map(x => {
      return {
        time: new Date(x.payment.created).toDateString(),
        type: x.payment.entityType,
        event: x.payment.event || EventType.WAITING_FOR_CUSTOMER_PAYMENT,
        item: x,
      };
    }),
    'time',
    'desc',
  );

  return (
    <Box width="full" p={6} bg="beige">
      <HStack p={3}>
        <Heading fontSize={'md'} color={'orange.500'}>
          PAYMENT
        </Heading>
        <Spacer />
        <IconButton
          onClick={() => {
            setSelectedPayments(groupedByEvent[eventList[0]]);
            navigate(paths.payments);
          }}
          aria-label="more button"
          bg="orange"
          icon={<FiMoreHorizontal />}
        />
      </HStack>
      <Select
        placeholder="Select Status"
        onChange={e => {
          setSelectedPayments(groupedByEvent[e.target.value]);
          if (!e.target.value) {
            setEventList(events);
            return;
          }
          setEventList([e.target.value as EventType]);
        }}
      >
        {events.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
      <VStack spacing={'auto'}>
        {events.map(x =>
          groupedByEvent[x] ? (
            <Text key={x} fontSize={'small'}>
              {x}: <b>{groupedByEvent[x].length}</b>
            </Text>
          ) : null,
        )}
      </VStack>
      <EntityWithEventChart
        sortedByTime={sortedByTime}
        eventToDisplay={eventList}
      />
    </Box>
  );
}
