import { Link as ChakraLink } from '@chakra-ui/react';

import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface Params {
  href: string;
  children?: any;
  [key: string]: any;
}

const Link: React.ForwardRefExoticComponent<
  Pick<Params, keyof Params> & React.RefAttributes<HTMLAnchorElement>
> = forwardRef((props: Params, ref?: React.LegacyRef<HTMLAnchorElement>) => {
  const { href, children, ...rest } = props;
  const navigate = useNavigate();

  return (
    <ChakraLink
      href={href}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        navigate(href);
      }}
      color={'blue.500'}
      _focus={{
        outline: 0,
        fontWeight: 'semibold',
      }}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
});

export default Link;
