import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { OrderResponse } from '../../API';
import { capitalizeEveryFirstLetter } from '../../utils/functions';
import OrderCard from './OrderCard';
import PaymentCard from './PaymentCard';
import QuoteResponseCard from './QuoteResponseCard';

interface Props {
  orderResponse: OrderResponse;
}

export default function OrderResponseCard({ orderResponse }: Props) {
  return (
    <>
      <Box borderWidth="thin" minWidth={'300px'} p={3}>
        <Accordion bg="orange.100" my={5} width={'full'} allowToggle={true}>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Order
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
              <OrderCard order={orderResponse.order} />
            </AccordionPanel>
          </AccordionItem>
          {orderResponse.payment && (
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Payment
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                <PaymentCard payment={orderResponse.payment} />
              </AccordionPanel>
            </AccordionItem>
          )}
          {orderResponse.quotes &&
            orderResponse.quotes.length &&
            orderResponse.quotes.map(quote => (
              <AccordionItem key={quote.quote.id}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Quote from{' '}
                      {capitalizeEveryFirstLetter(quote.partner.name)}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <QuoteResponseCard quoteResponse={quote} />
                </AccordionPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </Box>
    </>
  );
}
