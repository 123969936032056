import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { Creator, OrderSearchResults, searchInput } from '../../API';
import { searchOrders, searchRequests } from '../../api/queries';
import OrderResponseCard from '../../components/cards/OrderResponseCard';
import RequestResponseCard from '../../components/cards/RequestResponseCard';
import { selectedCustomerAtom, currentUserAtom } from '../../recoil/atoms';
import { allowedEmails } from '../../utils/constants';
import {
  customerDisplayAttributes,
  splitCamelCase,
} from '../../utils/functions';

const CustomerRequests: React.FC = (): JSX.Element => {
  const selectedCustomer = useRecoilValue(selectedCustomerAtom);
  const user = useRecoilValue(currentUserAtom);

  const [orders, setOrders] = useState<OrderSearchResults | undefined>();
  const [input] = useState<searchInput>({
    byCreator: {
      id: selectedCustomer?.id || '',
      limitAndToken: { limit: 100 },
      creatorType: Creator.CUSTOMER,
    },
  });

  const { data: customerRequestData } = useQuery(
    ['customerRequests', input],
    searchRequests,
    {
      cacheTime: 10000,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const { data: customerOrderData } = useQuery(
    ['customerOrders', input],
    searchOrders,
    {
      cacheTime: 10000,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!customerOrderData) return;

    if (!Object.keys(customerOrderData).includes('code'))
      setOrders(customerOrderData as OrderSearchResults);
  }, [customerOrderData]);

  return selectedCustomer ? (
    <VStack>
      <Card bg="orange.100" my={5} borderWidth="thin" p="2">
        <CardHeader>
          <Heading size="md">Customer</Heading>
        </CardHeader>
        <CardBody>
          <Stack spacing="0">
            {Object.entries(
              customerDisplayAttributes({
                ...selectedCustomer,
                phoneNumber: allowedEmails.includes(user.email)
                  ? selectedCustomer.phoneNumber
                  : 'Ask Admins',
              }),
            ).map(([key, value]) => {
              return value ? (
                <HStack key={key}>
                  <Text fontWeight={'bold'} size="xs">
                    {splitCamelCase(key)}:
                  </Text>
                  <Spacer />
                  <Text pt="2" fontSize="xs">
                    {value}
                  </Text>
                </HStack>
              ) : null;
            })}
          </Stack>
        </CardBody>
      </Card>
      <Accordion width={'90%'} allowToggle={true}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading size="md">Latest Requests</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel maxHeight={'300px'} overflow={'scroll'}>
            {' '}
            {customerRequestData?.byCustomer?.requests?.map(x => (
              <RequestResponseCard requestResponse={x} key={x.request.id} />
            ))}
            {customerRequestData?.byCustomer?.requests &&
              customerRequestData?.byCustomer?.requests.length < 1 && (
                <Heading>No Requests Yet</Heading>
              )}
          </AccordionPanel>
        </AccordionItem>
        {orders && (
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Heading size="md">Latest Orders</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel maxHeight={'300px'} overflow={'scroll'}>
              {orders.byCustomer?.orders?.map(x => (
                <OrderResponseCard orderResponse={x} key={x.order.id} />
              ))}
              {!orders.byCustomer?.orders?.length && (
                <Heading>No Orders Yet</Heading>
              )}
            </AccordionPanel>
          </AccordionItem>
        )}
      </Accordion>
    </VStack>
  ) : (
    <Heading>No Data</Heading>
  );
};

export default CustomerRequests;
