import { Authenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { Amplify } from 'aws-amplify';
import * as ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import queryClient from './api/query-client';
import App from './App';
import {
  amplifyCustomizations,
  amplifyFormFields,
} from './components/amplify/customizations';
import ErrorBoundary from './components/error/boundary';
import Config from './config/backend-config';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

try {
  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: Config.cognito.REGION,
      userPoolId: Config.cognito.USER_POOL_ID,
      identityPoolId: Config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: Config.cognito.APP_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        bucket: Config.s3.BUCKET,
        region: Config.s3.REGION,
      },
    },

    ...Config.appsync,
  });
} catch (error) {
  console.log(error);
}
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <RecoilRoot>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Authenticator
          hideSignUp={true}
          formFields={amplifyFormFields}
          components={amplifyCustomizations}
        >
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ChakraProvider>
        </Authenticator>
      </QueryClientProvider>
    </ErrorBoundary>
  </RecoilRoot>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
