import {
  Avatar,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Authenticator } from '@aws-amplify/ui-react';
import Link from '../link/link';
import Logo from '../logo/logo';
import { paths } from '../../utils/constants';
import Navigation from '../navigation';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_STAGE = '' } = process.env;

export default function Header() {
  const linkColor = useColorModeValue('black', 'white');
  const navigate = useNavigate();

  return (
    <Authenticator>
      {({ signOut, user }) => {
        return (
          <HStack
            as={'header'}
            position={'sticky'}
            top={0}
            zIndex={1000}
            width={'100vw'}
            spacing={{
              base: 4,
              md: 6,
            }}
            boxShadow={'sm'}
            py={4}
            px={{
              base: 4,
              md: 8,
            }}
          >
            <HStack
              onClick={() => {
                navigate(paths.home);
              }}
              spacing={4}
            >
              <Logo />
              <Text
                fontSize={'l'}
                letterSpacing={'0.2'}
                fontWeight={'bold'}
                color={REACT_APP_STAGE !== 'prod' ? 'red' : 'black'}
              >
                Admin {`${REACT_APP_STAGE !== 'prod' ? 'DEV' : ''}`}
              </Text>
            </HStack>
            <Spacer />
            <Navigation />
            <Menu>
              <MenuButton as={Avatar} size={'sm'} cursor={'pointer'} />
              <MenuList
                as={VStack}
                spacing={2}
                p={4}
                alignItems={'start'}
                width={'full'}
              >
                <Link
                  href={paths.payments}
                  color={{
                    base: linkColor,
                    md: 'black',
                  }}
                  fontWeight={'medium'}
                >
                  <Text fontWeight={'medium'}>Payments</Text>
                </Link>

                <Divider />
                <Button
                  size={'sm'}
                  width={'full'}
                  colorScheme={'red'}
                  onClick={signOut}
                >
                  {' '}
                  Log Out{' '}
                </Button>
              </MenuList>
            </Menu>
          </HStack>
        );
      }}
    </Authenticator>
  );
}
