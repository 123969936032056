import {
  Box,
  Heading,
  HStack,
  IconButton,
  Select,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { searchRequests } from '../../api/queries';
import { groupBy, orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { searchInput, Status } from '../../API';
import { selectedRequestsAtom, requestsAtom } from '../../recoil/atoms';
import { paths, statuses } from '../../utils/constants';
import EntityWithStatusChart from '../charts/entity-with-status';

export default function RequestSummary() {
  const [requestInput] = useState<searchInput>({ all: { all: true } });
  const setSelectedRequests = useSetRecoilState(selectedRequestsAtom);
  const [statusList, setStatusList] = useState<Status[]>(statuses);

  const navigate = useNavigate();

  const { data } = useQuery(['requests', requestInput], searchRequests, {
    cacheTime: 10000,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const setRequests = useSetRecoilState(requestsAtom);

  useEffect(() => {
    if (!data) return;
    if (!data.all) return;
    setRequests(data.all.requests);
  }, [data]);

  if (!data) return null;

  const groupedByStatus = groupBy(data.all?.requests, 'request.status');

  if (Object.keys(groupedByStatus).length < 1) {
    return (
      <Box width="full" p={6} bg="beige">
        <Heading fontSize={'md'} my="3" color={'orange.500'}>
          NO REQUEST DATA YET
        </Heading>
      </Box>
    );
  }

  const sortedByTime = orderBy(
    data.all?.requests?.map(x => {
      return {
        time: new Date(x.request.created).toDateString(),
        type: x.request.entityType,
        status: x.request.status,
        item: x,
      };
    }),
    'time',
    'desc',
  );

  return (
    <Box width="full" bg="beige">
      <HStack p={3}>
        <Heading fontSize={'md'} color={'orange.500'}>
          REQUESTS
        </Heading>
        <Spacer />
        <IconButton
          onClick={() => {
            setSelectedRequests(groupedByStatus[statusList[0]]);
            navigate(paths.requests);
          }}
          aria-label="more button"
          bg="orange"
          icon={<FiMoreHorizontal />}
        />
      </HStack>
      <Select
        placeholder="Select Status"
        onChange={e => {
          setSelectedRequests(groupedByStatus[e.target.value]);
          if (!e.target.value) {
            setStatusList(statuses);
            return;
          }
          setStatusList([e.target.value as Status]);
        }}
      >
        {statuses.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>
      <VStack spacing={'auto'}>
        {statuses.map(x =>
          groupedByStatus[x] ? (
            <Text key={x} fontSize={'small'}>
              {x}: <b>{groupedByStatus[x].length}</b>
            </Text>
          ) : null,
        )}
      </VStack>
      <EntityWithStatusChart
        sortedByTime={sortedByTime}
        statusToDisplay={statusList}
      />
    </Box>
  );
}
