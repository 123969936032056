import { Image } from '@chakra-ui/react';
import logo from '../../logo.svg';

export default function Logo() {
  return (
    <Image
      src={logo}
      alt={'riaparts logo'}
      height={'32px'}
      width={'32px'}
      cursor={'pointer'}
    />
  );
}
