import React from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { GetCustomersQuery } from '../../API';
import { Customer } from './customer';

type ContentProps = {
  query: UseInfiniteQueryResult<GetCustomersQuery['getCustomers']>;
};

export const Content: React.FC<ContentProps> = ({ query }) => {
  return (
    <>
      {query.data &&
        query.data.pages.map(page => {
          return page.customers
            ? page.customers.map(customer => {
                return <Customer key={customer.id} customer={customer} />;
              })
            : null;
        })}
    </>
  );
};
