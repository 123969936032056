import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Select,
} from '@chakra-ui/react';
import ReactJson from 'react-json-view';
import { useRecoilValue } from 'recoil';
import {
  currentUserAtom,
  quotesAtom,
  selectedQuotesAtom,
} from '../../recoil/atoms';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { statuses, allowedEmails } from '../../utils/constants';

export default function QuoteData() {
  const initQuotes = useRecoilValue(selectedQuotesAtom);

  const user = useRecoilValue(currentUserAtom);

  const quotes = useRecoilValue(quotesAtom);

  const grouped = groupBy(quotes, 'quote.status');

  const [selectedQuotes, setSelectedQuotes] = useState(initQuotes);

  return (
    <Box width="full" p={6}>
      <Heading>Quotes</Heading>
      <Select
        placeholder="Select Status"
        onChange={e => {
          if (!e.target.value) {
            return;
          }

          setSelectedQuotes(grouped[e.target.value]);
        }}
      >
        {statuses.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>

      {selectedQuotes ? (
        selectedQuotes.map(({ quote, autoPart, partner, request }) => {
          return (
            <Accordion
              bg="orange.100"
              my={5}
              width={'full'}
              allowToggle={true}
              key={quote.id}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Quote
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={quote}
                  />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Request
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={{
                      ...request,
                      vehicle: allowedEmails.includes(user.email)
                        ? request.vehicle
                        : {
                            vehicle: request.vehicle?.vehicle,
                            owner: 'Ask Admins',
                          },
                    }}
                  />
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      AutoPart
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={autoPart}
                  />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Partner
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={partner}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <Box width="full" p={6} bg="orange.100">
          PAYMENT DATA YET
        </Box>
      )}
    </Box>
  );
}
