/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: createCustomerInput!) {
    createCustomer(input: $input) {
      id
      created
      modified
      entityType
      createdBy
      firstName
      familyName
      gender
      imageS3Name
      birthdate
      phoneNumber
      email
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: updateCustomerInput_!) {
    updateCustomer(input: $input) {
      ... on Customer {
        id
        created
        modified
        entityType
        createdBy
        firstName
        familyName
        gender
        imageS3Name
        birthdate
        phoneNumber
        email
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const adminCreateCustomer = /* GraphQL */ `
  mutation AdminCreateCustomer($input: createCustomerInput!) {
    adminCreateCustomer(input: $input) {
      id
      created
      modified
      entityType
      createdBy
      firstName
      familyName
      gender
      imageS3Name
      birthdate
      phoneNumber
      email
    }
  }
`;
export const adminUpdateCustomer = /* GraphQL */ `
  mutation AdminUpdateCustomer($input: adminUpdateCustomerInput_!) {
    adminUpdateCustomer(input: $input) {
      ... on Customer {
        id
        created
        modified
        entityType
        createdBy
        firstName
        familyName
        gender
        imageS3Name
        birthdate
        phoneNumber
        email
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      ... on OperationResponse {
        success
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner($input: createPartnerInput!) {
    createPartner(input: $input) {
      ... on Partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner($input: updatePartnerInputNew!) {
    updatePartner(input: $input) {
      ... on Partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const adminCreatePartner = /* GraphQL */ `
  mutation AdminCreatePartner($input: createPartnerInput!) {
    adminCreatePartner(input: $input) {
      ... on Partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const adminUpdatePartner = /* GraphQL */ `
  mutation AdminUpdatePartner($input: adminUpdatePartnerInputNew!) {
    adminUpdatePartner(input: $input) {
      ... on Partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner($id: ID!) {
    deletePartner(id: $id) {
      ... on OperationResponse {
        success
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const createAutoPartCategory = /* GraphQL */ `
  mutation CreateAutoPartCategory($input: createAutoPartCategoryInput!) {
    createAutoPartCategory(input: $input) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      description
    }
  }
`;
export const updateAutoPartCategory = /* GraphQL */ `
  mutation UpdateAutoPartCategory($input: updateAutoPartCategoryInput!) {
    updateAutoPartCategory(input: $input) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      description
    }
  }
`;
export const createAutoPartSubCategory = /* GraphQL */ `
  mutation CreateAutoPartSubCategory($input: createAutoPartSubCategoryInput!) {
    createAutoPartSubCategory(input: $input) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      category {
        id
        created
        modified
        entityType
        name
        imageS3Name
        description
      }
      description
    }
  }
`;
export const updateAutoPartSubCategory = /* GraphQL */ `
  mutation UpdateAutoPartSubCategory($input: updateAutoPartSubCategoryInput!) {
    updateAutoPartSubCategory(input: $input) {
      id
      created
      modified
      entityType
      name
      imageS3Name
      category {
        id
        created
        modified
        entityType
        name
        imageS3Name
        description
      }
      description
    }
  }
`;
export const createAutoPart = /* GraphQL */ `
  mutation CreateAutoPart($input: createAutoPartInput!) {
    createAutoPart(input: $input) {
      ... on AutoPart {
        id
        created
        modified
        entityType
        name
        category {
          id
          created
          modified
          entityType
          name
          imageS3Name
          description
        }
        subCategory {
          id
          created
          modified
          entityType
          name
          imageS3Name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          description
        }
        description
        imageS3Name
        fitmentNotes
        weightG
        widthMM
        heightMM
        lengthMM
        isUniversal
        modelCode
        regYearAndmonth
        mileage
        transmissionType
        engineModel
        engineSize
        fuel
        drive
        manufacturer
        OEM
        src
        make
        model
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updateAutoPart = /* GraphQL */ `
  mutation UpdateAutoPart($input: updateAutoPartInput!) {
    updateAutoPart(input: $input) {
      ... on AutoPart {
        id
        created
        modified
        entityType
        name
        category {
          id
          created
          modified
          entityType
          name
          imageS3Name
          description
        }
        subCategory {
          id
          created
          modified
          entityType
          name
          imageS3Name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          description
        }
        description
        imageS3Name
        fitmentNotes
        weightG
        widthMM
        heightMM
        lengthMM
        isUniversal
        modelCode
        regYearAndmonth
        mileage
        transmissionType
        engineModel
        engineSize
        fuel
        drive
        manufacturer
        OEM
        src
        make
        model
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const createSms = /* GraphQL */ `
  mutation CreateSms($input: createSmsInput!) {
    createSms(input: $input) {
      ... on Sms {
        id
        created
        modified
        entityType
        _id
        thread_id
        address
        person
        date
        date_sent
        protocol
        read
        status
        type
        body
        service_center
        locked
        error_code
        sub_id
        seen
        deletable
        sim_slot
        hidden
        app_id
        msg_id
        reserved
        pri
        teleservice_id
        svc_cmd
        roam_pending
        spam_report
        secret_mode
        safe_message
        favorite
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updateSms = /* GraphQL */ `
  mutation UpdateSms($input: updateSmsInput!) {
    updateSms(input: $input) {
      ... on Sms {
        id
        created
        modified
        entityType
        _id
        thread_id
        address
        person
        date
        date_sent
        protocol
        read
        status
        type
        body
        service_center
        locked
        error_code
        sub_id
        seen
        deletable
        sim_slot
        hidden
        app_id
        msg_id
        reserved
        pri
        teleservice_id
        svc_cmd
        roam_pending
        spam_report
        secret_mode
        safe_message
        favorite
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteSms = /* GraphQL */ `
  mutation DeleteSms($id: ID!) {
    deleteSms(id: $id)
  }
`;
export const createInvalidPayment = /* GraphQL */ `
  mutation CreateInvalidPayment($input: createInvalidPaymentInput!) {
    createInvalidPayment(input: $input) {
      ... on InvalidPayment {
        id
        created
        modified
        entityType
        momoText
        momoNumber
        momoAmount
        momoName
        refNumber
        reason
        isOpen
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updateInvalidPayment = /* GraphQL */ `
  mutation UpdateInvalidPayment($input: updateInvalidPaymentInput!) {
    updateInvalidPayment(input: $input) {
      ... on InvalidPayment {
        id
        created
        modified
        entityType
        momoText
        momoNumber
        momoAmount
        momoName
        refNumber
        reason
        isOpen
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteInvalidPayment = /* GraphQL */ `
  mutation DeleteInvalidPayment($id: ID!) {
    deleteInvalidPayment(id: $id)
  }
`;
export const deleteAutoPart = /* GraphQL */ `
  mutation DeleteAutoPart($id: ID!) {
    deleteAutoPart(id: $id) {
      ... on OperationResponse {
        success
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteAutoPartsFromQuote = /* GraphQL */ `
  mutation DeleteAutoPartsFromQuote($id: ID!, $autoPartId: ID!) {
    deleteAutoPartsFromQuote(id: $id, autoPartId: $autoPartId)
  }
`;
export const deleteAutoPartSubCategory = /* GraphQL */ `
  mutation DeleteAutoPartSubCategory($id: ID!, $autoPartId: ID!) {
    deleteAutoPartSubCategory(id: $id, autoPartId: $autoPartId)
  }
`;
export const deleteAutoPartCategory = /* GraphQL */ `
  mutation DeleteAutoPartCategory($id: ID!, $autoPartId: ID!) {
    deleteAutoPartCategory(id: $id, autoPartId: $autoPartId)
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto($input: updatePhotoInput!) {
    updatePhoto(input: $input) {
      id
      created
      modified
      entityType
      imageS3Name
      description
      contentType
      title
      creatorType
      creatorId
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto($id: ID!) {
    deletePhoto(id: $id)
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest($input: createRequestInput!) {
    createRequest(input: $input) {
      request {
        id
        created
        modified
        entityType
        discontinued
        make
        model
        year
        fuel
        gearbox
        variant
        VIN
        engineNumber
        status
        contactDetails
        otherDetails
        creatorId
        creatorType
        quoteIds
        vehicleId
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quantity
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      vehicle {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest($input: updateRequestInput!) {
    updateRequest(input: $input) {
      request {
        id
        created
        modified
        entityType
        discontinued
        make
        model
        year
        fuel
        gearbox
        variant
        VIN
        engineNumber
        status
        contactDetails
        otherDetails
        creatorId
        creatorType
        quoteIds
        vehicleId
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quantity
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
      vehicle {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest($id: ID!) {
    deleteRequest(id: $id)
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: createPaymentInput!) {
    createPayment(input: $input) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              discontinued
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                discontinued
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
                discount
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($input: updatePaymentInput!) {
    updatePayment(input: $input) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              discontinued
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                discontinued
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
                discount
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const changePaymentProcessor = /* GraphQL */ `
  mutation ChangePaymentProcessor($input: changePaymentProcessorInput!) {
    changePaymentProcessor(input: $input) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              discontinued
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                discontinued
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
                discount
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const retryPayment = /* GraphQL */ `
  mutation RetryPayment($id: ID!) {
    retryPayment(id: $id) {
      hasRetryBeenInitialized
      message
    }
  }
`;
export const updateManualCustomerPayment = /* GraphQL */ `
  mutation UpdateManualCustomerPayment(
    $input: updateManualCustomerPaymentInput!
  ) {
    updateManualCustomerPayment(input: $input) {
      ... on PaymentResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        order {
          order {
            id
            created
            modified
            entityType
            quotes {
              partnerId
              quoteIds
            }
            customerId
            status
          }
          quotes {
            quote {
              id
              created
              modified
              entityType
              discontinued
              comment
              status
              expiryDate
              total
              isNew
            }
            partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            request {
              request {
                id
                created
                modified
                entityType
                discontinued
                make
                model
                year
                fuel
                gearbox
                variant
                VIN
                engineNumber
                status
                contactDetails
                otherDetails
                creatorId
                creatorType
                quoteIds
                vehicleId
              }
              autoPart {
                autoPart {
                  id
                  created
                  modified
                  entityType
                  name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  subCategory {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    category {
                      id
                      created
                      modified
                      entityType
                      name
                      imageS3Name
                      description
                    }
                    description
                  }
                  description
                  imageS3Name
                  fitmentNotes
                  weightG
                  widthMM
                  heightMM
                  lengthMM
                  isUniversal
                  modelCode
                  regYearAndmonth
                  mileage
                  transmissionType
                  engineModel
                  engineSize
                  fuel
                  drive
                  manufacturer
                  OEM
                  src
                  make
                  model
                }
                quantity
              }
              photos {
                id
                created
                modified
                entityType
                imageS3Name
                description
                contentType
                title
                creatorType
                creatorId
              }
              vehicle {
                vehicle {
                  id
                  created
                  modified
                  discontinued
                  entityType
                  make
                  model
                  engineNumber
                  variant
                  year
                  fuel
                  gearbox
                  plateNumber
                  VIN
                  creatorType
                  creatorId
                  otherDetails
                }
                owner {
                  ... on Partner {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    name
                    imageS3Name
                    email
                    phoneNumber
                    address
                    bankAccount
                    mainContactPerson
                    subscribedCategories
                    mobileMoneyDetails {
                      processor
                      name
                      number
                    }
                  }
                  ... on Customer {
                    id
                    created
                    modified
                    entityType
                    createdBy
                    firstName
                    familyName
                    gender
                    imageS3Name
                    birthdate
                    phoneNumber
                    email
                  }
                }
              }
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quotedPrice
              isSold
              soldPrice
              comment
              paymentOptions {
                finalFee
                chargeFee
                serviceFee
                deliveryFee
                deliveryWithdrawalFee
                withdrawalFee
                processorChargePercentage
                processorChargeRate
                processorPayoutPercentage
                processorPayoutRate
                serviceFeeDate
                partnerPayoutAmount
                deliveryPayoutAmount
                isDeliveryIncluded
                processor
                quotedPrice
                payoutFee
                discount
              }
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
          }
          payment {
            id
            created
            modified
            entityType
            orderId
            creatorId
            creatorType
            currency
            otherDetails
            referenceId
            transactionId
            notes
            momoPhoneNumber
            momoText
            status
            eventHistory {
              event
              date
              note
            }
            event
            processor
            fee {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
            receivedFees {
              receivedTotal
              overpayment
              underpayment
            }
          }
        }
        paymentMethod {
          ... on MobileMoney {
            id
            created
            modified
            entityType
            name
            phoneNumber
            country
            mobileMoneyProvider
            creatorId
            creatorType
          }
          ... on CreditCard {
            id
            created
            modified
            entityType
            name
            country
            cardProvider
            creatorId
            creatorType
            expMonth
            expYear
            last4
          }
        }
        creator {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($creator: byCreatorInput!, $paymentId: ID!) {
    deletePayment(creator: $creator, paymentId: $paymentId)
  }
`;
export const createRefund = /* GraphQL */ `
  mutation CreateRefund($input: createRefundInput!) {
    createRefund(input: $input) {
      ... on RefundResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        invalid {
          id
          created
          modified
          entityType
          creatorId
          creatorType
          status
          paymentId
          refundAmount
          reason
          refundMethod {
            id
            type
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updateRefund = /* GraphQL */ `
  mutation UpdateRefund($input: updateRefundInput!) {
    updateRefund(input: $input) {
      ... on RefundResponse {
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
        invalid {
          id
          created
          modified
          entityType
          creatorId
          creatorType
          status
          paymentId
          refundAmount
          reason
          refundMethod {
            id
            type
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteRefund = /* GraphQL */ `
  mutation DeleteRefund($id: ID!) {
    deleteRefund(id: $id)
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout($input: createPayoutInput!) {
    createPayout(input: $input) {
      payout {
        id
        created
        modified
        entityType
        orderId
        partnerId
        status
        total
        currency
        paymentMethod {
          id
          type
        }
        transactionFee
        otherDetails
      }
      order {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            discontinued
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              discontinued
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      to {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout($input: updatePayoutInput!) {
    updatePayout(input: $input) {
      payout {
        id
        created
        modified
        entityType
        orderId
        partnerId
        status
        total
        currency
        paymentMethod {
          id
          type
        }
        transactionFee
        otherDetails
      }
      order {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            discontinued
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              discontinued
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      to {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
    }
  }
`;
export const deletePayout = /* GraphQL */ `
  mutation DeletePayout($creator: byCreatorInput!, $payoutId: ID!) {
    deletePayout(creator: $creator, payoutId: $payoutId)
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: createOrderInput!) {
    createOrder(input: $input) {
      ... on OrderResponse {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            discontinued
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              discontinued
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: updateOrderInput!) {
    updateOrder(input: $input) {
      ... on OrderResponse {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            discontinued
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              discontinued
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteQuotesFromOrder = /* GraphQL */ `
  mutation DeleteQuotesFromOrder($input: deleteQuoteFromOrderInput!) {
    deleteQuotesFromOrder(input: $input) {
      ... on OrderResponse {
        order {
          id
          created
          modified
          entityType
          quotes {
            partnerId
            quoteIds
          }
          customerId
          status
        }
        quotes {
          quote {
            id
            created
            modified
            entityType
            discontinued
            comment
            status
            expiryDate
            total
            isNew
          }
          partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          request {
            request {
              id
              created
              modified
              entityType
              discontinued
              make
              model
              year
              fuel
              gearbox
              variant
              VIN
              engineNumber
              status
              contactDetails
              otherDetails
              creatorId
              creatorType
              quoteIds
              vehicleId
            }
            autoPart {
              autoPart {
                id
                created
                modified
                entityType
                name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                subCategory {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  category {
                    id
                    created
                    modified
                    entityType
                    name
                    imageS3Name
                    description
                  }
                  description
                }
                description
                imageS3Name
                fitmentNotes
                weightG
                widthMM
                heightMM
                lengthMM
                isUniversal
                modelCode
                regYearAndmonth
                mileage
                transmissionType
                engineModel
                engineSize
                fuel
                drive
                manufacturer
                OEM
                src
                make
                model
              }
              quantity
            }
            photos {
              id
              created
              modified
              entityType
              imageS3Name
              description
              contentType
              title
              creatorType
              creatorId
            }
            vehicle {
              vehicle {
                id
                created
                modified
                discontinued
                entityType
                make
                model
                engineNumber
                variant
                year
                fuel
                gearbox
                plateNumber
                VIN
                creatorType
                creatorId
                otherDetails
              }
              owner {
                ... on Partner {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  name
                  imageS3Name
                  email
                  phoneNumber
                  address
                  bankAccount
                  mainContactPerson
                  subscribedCategories
                  mobileMoneyDetails {
                    processor
                    name
                    number
                  }
                }
                ... on Customer {
                  id
                  created
                  modified
                  entityType
                  createdBy
                  firstName
                  familyName
                  gender
                  imageS3Name
                  birthdate
                  phoneNumber
                  email
                }
              }
            }
          }
          autoPart {
            autoPart {
              id
              created
              modified
              entityType
              name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              subCategory {
                id
                created
                modified
                entityType
                name
                imageS3Name
                category {
                  id
                  created
                  modified
                  entityType
                  name
                  imageS3Name
                  description
                }
                description
              }
              description
              imageS3Name
              fitmentNotes
              weightG
              widthMM
              heightMM
              lengthMM
              isUniversal
              modelCode
              regYearAndmonth
              mileage
              transmissionType
              engineModel
              engineSize
              fuel
              drive
              manufacturer
              OEM
              src
              make
              model
            }
            quotedPrice
            isSold
            soldPrice
            comment
            paymentOptions {
              finalFee
              chargeFee
              serviceFee
              deliveryFee
              deliveryWithdrawalFee
              withdrawalFee
              processorChargePercentage
              processorChargeRate
              processorPayoutPercentage
              processorPayoutRate
              serviceFeeDate
              partnerPayoutAmount
              deliveryPayoutAmount
              isDeliveryIncluded
              processor
              quotedPrice
              payoutFee
              discount
            }
          }
          photos {
            id
            created
            modified
            entityType
            imageS3Name
            description
            contentType
            title
            creatorType
            creatorId
          }
        }
        payment {
          id
          created
          modified
          entityType
          orderId
          creatorId
          creatorType
          currency
          otherDetails
          referenceId
          transactionId
          notes
          momoPhoneNumber
          momoText
          status
          eventHistory {
            event
            date
            note
          }
          event
          processor
          fee {
            finalFee
            chargeFee
            serviceFee
            deliveryFee
            deliveryWithdrawalFee
            withdrawalFee
            processorChargePercentage
            processorChargeRate
            processorPayoutPercentage
            processorPayoutRate
            serviceFeeDate
            partnerPayoutAmount
            deliveryPayoutAmount
            isDeliveryIncluded
            processor
            quotedPrice
            payoutFee
            discount
          }
          receivedFees {
            receivedTotal
            overpayment
            underpayment
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($id: ID!) {
    deleteOrder(id: $id)
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle($input: createVehicleInput_!) {
    createVehicle(input: $input) {
      vehicle {
        id
        created
        modified
        discontinued
        entityType
        make
        model
        engineNumber
        variant
        year
        fuel
        gearbox
        plateNumber
        VIN
        creatorType
        creatorId
        otherDetails
      }
      owner {
        ... on Partner {
          id
          created
          modified
          entityType
          createdBy
          name
          imageS3Name
          email
          phoneNumber
          address
          bankAccount
          mainContactPerson
          subscribedCategories
          mobileMoneyDetails {
            processor
            name
            number
          }
        }
        ... on Customer {
          id
          created
          modified
          entityType
          createdBy
          firstName
          familyName
          gender
          imageS3Name
          birthdate
          phoneNumber
          email
        }
      }
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle($input: updateVehicleInput!) {
    updateVehicle(input: $input) {
      ... on VehicleResponse {
        vehicle {
          id
          created
          modified
          discontinued
          entityType
          make
          model
          engineNumber
          variant
          year
          fuel
          gearbox
          plateNumber
          VIN
          creatorType
          creatorId
          otherDetails
        }
        owner {
          ... on Partner {
            id
            created
            modified
            entityType
            createdBy
            name
            imageS3Name
            email
            phoneNumber
            address
            bankAccount
            mainContactPerson
            subscribedCategories
            mobileMoneyDetails {
              processor
              name
              number
            }
          }
          ... on Customer {
            id
            created
            modified
            entityType
            createdBy
            firstName
            familyName
            gender
            imageS3Name
            birthdate
            phoneNumber
            email
          }
        }
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      ... on OperationResponse {
        success
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const createPaymentMethod = /* GraphQL */ `
  mutation CreatePaymentMethod($input: createPaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      ... on MobileMoney {
        id
        created
        modified
        entityType
        name
        phoneNumber
        country
        mobileMoneyProvider
        creatorId
        creatorType
      }
      ... on CreditCard {
        id
        created
        modified
        entityType
        name
        country
        cardProvider
        creatorId
        creatorType
        expMonth
        expYear
        last4
      }
    }
  }
`;
export const updatePaymentMethod = /* GraphQL */ `
  mutation UpdatePaymentMethod($input: updatePaymentMethodInput!) {
    updatePaymentMethod(input: $input)
  }
`;
export const deletePaymentMethod = /* GraphQL */ `
  mutation DeletePaymentMethod(
    $creator: byCreatorInput!
    $provider: paymentMethodInput!
  ) {
    deletePaymentMethod(creator: $creator, provider: $provider)
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote($input: createQuoteInput!) {
    createQuote(input: $input) {
      quote {
        id
        created
        modified
        entityType
        discontinued
        comment
        status
        expiryDate
        total
        isNew
      }
      partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      request {
        request {
          id
          created
          modified
          entityType
          discontinued
          make
          model
          year
          fuel
          gearbox
          variant
          VIN
          engineNumber
          status
          contactDetails
          otherDetails
          creatorId
          creatorType
          quoteIds
          vehicleId
        }
        autoPart {
          autoPart {
            id
            created
            modified
            entityType
            name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            subCategory {
              id
              created
              modified
              entityType
              name
              imageS3Name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              description
            }
            description
            imageS3Name
            fitmentNotes
            weightG
            widthMM
            heightMM
            lengthMM
            isUniversal
            modelCode
            regYearAndmonth
            mileage
            transmissionType
            engineModel
            engineSize
            fuel
            drive
            manufacturer
            OEM
            src
            make
            model
          }
          quantity
        }
        photos {
          id
          created
          modified
          entityType
          imageS3Name
          description
          contentType
          title
          creatorType
          creatorId
        }
        vehicle {
          vehicle {
            id
            created
            modified
            discontinued
            entityType
            make
            model
            engineNumber
            variant
            year
            fuel
            gearbox
            plateNumber
            VIN
            creatorType
            creatorId
            otherDetails
          }
          owner {
            ... on Partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              createdBy
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quotedPrice
        isSold
        soldPrice
        comment
        paymentOptions {
          finalFee
          chargeFee
          serviceFee
          deliveryFee
          deliveryWithdrawalFee
          withdrawalFee
          processorChargePercentage
          processorChargeRate
          processorPayoutPercentage
          processorPayoutRate
          serviceFeeDate
          partnerPayoutAmount
          deliveryPayoutAmount
          isDeliveryIncluded
          processor
          quotedPrice
          payoutFee
          discount
        }
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote($input: updateQuoteInput!) {
    updateQuote(input: $input) {
      quote {
        id
        created
        modified
        entityType
        discontinued
        comment
        status
        expiryDate
        total
        isNew
      }
      partner {
        id
        created
        modified
        entityType
        createdBy
        name
        imageS3Name
        email
        phoneNumber
        address
        bankAccount
        mainContactPerson
        subscribedCategories
        mobileMoneyDetails {
          processor
          name
          number
        }
      }
      request {
        request {
          id
          created
          modified
          entityType
          discontinued
          make
          model
          year
          fuel
          gearbox
          variant
          VIN
          engineNumber
          status
          contactDetails
          otherDetails
          creatorId
          creatorType
          quoteIds
          vehicleId
        }
        autoPart {
          autoPart {
            id
            created
            modified
            entityType
            name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            subCategory {
              id
              created
              modified
              entityType
              name
              imageS3Name
              category {
                id
                created
                modified
                entityType
                name
                imageS3Name
                description
              }
              description
            }
            description
            imageS3Name
            fitmentNotes
            weightG
            widthMM
            heightMM
            lengthMM
            isUniversal
            modelCode
            regYearAndmonth
            mileage
            transmissionType
            engineModel
            engineSize
            fuel
            drive
            manufacturer
            OEM
            src
            make
            model
          }
          quantity
        }
        photos {
          id
          created
          modified
          entityType
          imageS3Name
          description
          contentType
          title
          creatorType
          creatorId
        }
        vehicle {
          vehicle {
            id
            created
            modified
            discontinued
            entityType
            make
            model
            engineNumber
            variant
            year
            fuel
            gearbox
            plateNumber
            VIN
            creatorType
            creatorId
            otherDetails
          }
          owner {
            ... on Partner {
              id
              created
              modified
              entityType
              createdBy
              name
              imageS3Name
              email
              phoneNumber
              address
              bankAccount
              mainContactPerson
              subscribedCategories
              mobileMoneyDetails {
                processor
                name
                number
              }
            }
            ... on Customer {
              id
              created
              modified
              entityType
              createdBy
              firstName
              familyName
              gender
              imageS3Name
              birthdate
              phoneNumber
              email
            }
          }
        }
      }
      autoPart {
        autoPart {
          id
          created
          modified
          entityType
          name
          category {
            id
            created
            modified
            entityType
            name
            imageS3Name
            description
          }
          subCategory {
            id
            created
            modified
            entityType
            name
            imageS3Name
            category {
              id
              created
              modified
              entityType
              name
              imageS3Name
              description
            }
            description
          }
          description
          imageS3Name
          fitmentNotes
          weightG
          widthMM
          heightMM
          lengthMM
          isUniversal
          modelCode
          regYearAndmonth
          mileage
          transmissionType
          engineModel
          engineSize
          fuel
          drive
          manufacturer
          OEM
          src
          make
          model
        }
        quotedPrice
        isSold
        soldPrice
        comment
        paymentOptions {
          finalFee
          chargeFee
          serviceFee
          deliveryFee
          deliveryWithdrawalFee
          withdrawalFee
          processorChargePercentage
          processorChargeRate
          processorPayoutPercentage
          processorPayoutRate
          serviceFeeDate
          partnerPayoutAmount
          deliveryPayoutAmount
          isDeliveryIncluded
          processor
          quotedPrice
          payoutFee
          discount
        }
      }
      photos {
        id
        created
        modified
        entityType
        imageS3Name
        description
        contentType
        title
        creatorType
        creatorId
      }
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote($id: ID!) {
    deleteQuote(id: $id) {
      ... on OperationResponse {
        success
      }
      ... on ErrorResponse {
        code
        error
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: createMessageInput!) {
    createMessage(input: $input) {
      id
      created
      modified
      entityType
      message
      conversationId
      senderId
      senderType
      imageS3Name
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($id: ID!, $conversationId: ID!) {
    deleteMessage(id: $id, conversationId: $conversationId)
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation($input: createConversationInput!) {
    createConversation(input: $input) {
      id
      created
      modified
      entityType
      members {
        ... on Partner {
          id
          created
          modified
          entityType
          createdBy
          name
          imageS3Name
          email
          phoneNumber
          address
          bankAccount
          mainContactPerson
          subscribedCategories
          mobileMoneyDetails {
            processor
            name
            number
          }
        }
        ... on Customer {
          id
          created
          modified
          entityType
          createdBy
          firstName
          familyName
          gender
          imageS3Name
          birthdate
          phoneNumber
          email
        }
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation($id: ID!) {
    deleteConversation(id: $id)
  }
`;
export const updateStatus = /* GraphQL */ `
  mutation UpdateStatus($input: updateStatusInput!) {
    updateStatus(input: $input)
  }
`;
