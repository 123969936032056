import { Stack } from '@chakra-ui/react';
import Main from '../../components/layouts/Main';
import OrderSummary from '../../components/orders/summary';

import PaymentSummary from '../../components/payments/summary';
import QuoteSummary from '../../components/quotes/summary';
import RequestSummary from '../../components/requests/summary';
import UsersChart from '../../components/users/summary';

export default function Structure() {
  return (
    <Main>
      <Stack
        w={'full'}
        justifyContent={'center'}
        alignItems={'center'}
        h={'auto'}
        pb={'8'}
        gap={'4'}
      >
        <Stack
          w={'full'}
          justifyContent={'center'}
          alignItems={'center'}
          mb={'4'}
        >
          <RequestSummary />
          <QuoteSummary />
          <OrderSummary />
          <PaymentSummary />
          <UsersChart />
        </Stack>
      </Stack>
    </Main>
  );
}
