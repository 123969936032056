import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Select,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import {
  selectedRequestsAtom,
  requestsAtom,
  currentUserAtom,
} from '../../recoil/atoms';
import ReactJson from 'react-json-view';
import { statuses, allowedEmails } from '../../utils/constants';
import { useState } from 'react';
import { groupBy } from 'lodash';

export default function RequestData() {
  const initRequests = useRecoilValue(selectedRequestsAtom);

  const user = useRecoilValue(currentUserAtom);

  const requests = useRecoilValue(requestsAtom);

  const grouped = groupBy(requests, 'request.status');

  const [selectedRequests, setSelectedRequests] = useState(initRequests);

  return (
    <Box width="full" p={6}>
      <Heading>Requests</Heading>

      <Select
        placeholder="Select Status"
        onChange={e => {
          if (!e.target.value) {
            return;
          }

          setSelectedRequests(grouped[e.target.value]);
        }}
      >
        {statuses.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>

      {selectedRequests ? (
        selectedRequests.map(({ autoPart, request, vehicle }) => {
          return (
            <Accordion
              bg="orange.100"
              my={5}
              width={'full'}
              allowToggle={true}
              key={request.id}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Request
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={request}
                  />
                </AccordionPanel>
              </AccordionItem>
              {vehicle && (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Vehicle
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    maxHeight={'600px'}
                    overflow={'scroll'}
                    pb={4}
                  >
                    <ReactJson
                      name={null}
                      sortKeys={true}
                      collapsed={true}
                      displayDataTypes={false}
                      indentWidth={1}
                      src={{
                        ...vehicle,
                        owner: allowedEmails.includes(user.email)
                          ? vehicle.owner
                          : 'Ask Admins',
                      }}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )}

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      AutoPart
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={autoPart}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <Box width="full" p={6} bg="orange.100">
          NO REQUEST DATA YET
        </Box>
      )}
    </Box>
  );
}
