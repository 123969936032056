import { Text } from '@chakra-ui/react';
import { groupBy } from 'lodash';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { EntityType, EventType, PaymentResponse } from '../../API';
import { brightColors } from '../../utils/constants';

interface TimeSeriesProps {
  sortedByTime: {
    time: string;
    type: EntityType;
    event: EventType;
    item: PaymentResponse;
  }[];
  eventToDisplay: EventType[];
}

const EntityWithEventChart = ({
  sortedByTime,
  eventToDisplay,
}: TimeSeriesProps) => {
  const groupByDate = groupBy(
    sortedByTime.filter(x => eventToDisplay.includes(x.event)),
    'time',
  );

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={Object.entries(groupByDate).map(([key, value]) => {
            return {
              time: new Date(value[0].time).toDateString(),
              type: value[0].type,
              ...value.reduce((accumulator, current) => {
                let { event } = current;
                const count = value.filter(x => x.event === event).length;
                return count > 0
                  ? { ...accumulator, [event]: count }
                  : accumulator;
              }, {}),
            };
          })}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis />
          <Tooltip />
          {eventToDisplay.map((x, i) => (
            <Line
              key={x + i}
              connectNulls
              type="monotone"
              dataKey={x}
              stroke={brightColors[i % brightColors.length]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <Text color={'red'} fontSize={'xx-small'}>
        No Legend is displayed because there are too many possible Events.
      </Text>
    </div>
  );
};

export default EntityWithEventChart;
