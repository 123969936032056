import { Button, Heading, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { GetPartnersQueryVariables } from '../../API';
import { getPartners } from '../../api/queries';
import { Content } from './content';

export const Partners: React.FC = () => {
  const [input] = useState<GetPartnersQueryVariables>({
    limit: 10,
  });

  const query = useInfiniteQuery(['partners', input], getPartners, {
    cacheTime: 10000,
    refetchInterval: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: previousPage => {
      return previousPage ? previousPage.nextToken : null;
    },
  });

  return (
    <VStack
      spacing={5}
      p={5}
      pb={20}
      maxW={'500px'}
      align={'start'}
      justify={'center'}
    >
      <Heading> Partners </Heading>
      <Content query={query} />
      <Button
        colorScheme={'red'}
        variant={'link'}
        disabled={query.isLoading || !query.hasNextPage}
        isLoading={query.isFetching}
        onClick={() => query.fetchNextPage()}
      >
        load more
      </Button>
    </VStack>
  );
};
