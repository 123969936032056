import { HStack, useBreakpointValue } from '@chakra-ui/react';
import { FaHandsHelping } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { GiSoundWaves } from 'react-icons/gi';

import { GoHome } from 'react-icons/go';
import { paths } from '../../utils/constants';
import NavigationItem from './item';

export default function NavigationContent(props) {
  const iconSize = useBreakpointValue({
    base: '18px',
    md: '14px',
  });

  const ROUTES = [
    { path: paths.home, text: 'Dashboard', icon: <GoHome size={iconSize} /> },
    {
      path: paths.partners,
      text: 'Partners',
      icon: <FaHandsHelping size={iconSize} />,
    },
    {
      path: paths.customers,
      text: 'Customers',
      icon: <FiUsers size={iconSize} />,
    },
    {
      path: paths.status,
      text: 'Status',
      icon: <GiSoundWaves size={iconSize} />,
    },
  ];

  return (
    <HStack
      direction={{
        base: 'row',
      }}
      justifyContent={{
        base: 'center',
        sm: 'center',
      }}
      alignItems={{
        base: 'center',
        md: 'center',
      }}
      spacing={{
        base: 0,
        md: 2,
      }}
      width={'full'}
      px={{
        base: 0,
        md: 0,
      }}
      py={{
        base: '10px',
        md: 0,
      }}
      boxShadow={useBreakpointValue({ base: 'xs', md: '' })}
      {...props}
      data-component="nav"
    >
      {ROUTES.map(route => {
        return route.children ? (
          route.children
        ) : (
          <NavigationItem key={route.path} route={route} />
        );
      })}
    </HStack>
  );
}
