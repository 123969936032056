import {
  Box,
  HStack,
  List,
  ListItem,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { FaSave } from 'react-icons/fa';
import ReactJson from 'react-json-view';
import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  eventHistoryItemInput,
  EventType,
  PaymentProcessors,
  PaymentResponse,
  receivedFeesInput,
  Status,
} from '../../API';
import { editPayment } from '../../api/mutations';
import { currentUserAtom } from '../../recoil/atoms';
import { allowedEmails } from '../../utils/constants';
import { ButtonSoftPrimary } from '../buttons';

interface EditPaymentProps {
  payment: PaymentResponse;
}

//const events: EventType[] = [EventType.MANUAL_CUSTOMER_PAYED, EventType.PAYMENT_CONFIRMED, EventType.CUSTOMER_OVERPAYED, EventType.CUSTOMER_UNDERPAYED];

const { REACT_APP_STAGE = 'dev' } = process.env;

export default function EditPayment({ payment }: EditPaymentProps) {
  const [eventHistory, setEventHistory] = useState<eventHistoryItemInput>(
    payment.payment.eventHistory[0],
  );
  const [receivedFees, setReceivedFees] = useState<receivedFeesInput>(
    payment.payment.receivedFees
      ? payment.payment.receivedFees
      : {
          receivedTotal: 0,
          overpayment: 0,
          underpayment: 0,
        },
  );
  //NOTE: initialize the create vehicle mutation
  const updatePayment = useMutation(editPayment);
  const [status, setStatus] = useState<Status>(payment.payment.status);
  const user = useRecoilValue(currentUserAtom);
  const toast = useToast();

  useEffect(() => {
    let event: EventType = EventType.WAITING_FOR_CUSTOMER_PAYMENT;

    if (receivedFees.overpayment !== 0) {
      event = EventType.CUSTOMER_OVERPAYED;
    }

    if (receivedFees.underpayment !== 0) {
      event = EventType.CUSTOMER_UNDERPAYED;
    }
    if (receivedFees.receivedTotal === payment.payment.fee.finalFee) {
      event = EventType.PAYMENT_CONFIRMED;
      setStatus(Status.CLOSED);
    }

    setEventHistory({
      event,
      date: new Date().toJSON(),
      note:
        'Updated by admin ID: ' +
        user.sub +
        ' with email: ' +
        user.email +
        'and number: ' +
        user.phone_number,
    });
  }, [receivedFees]);

  const onSave = async () => {
    if (!payment) {
      toast({
        status: 'error',
        title: 'Failed to Update',
        description: 'No Payment Chosen',
        duration: 3000,
        isClosable: true,
        position: 'bottom',
      });

      return;
    }
    //TODO! ONLY ALLOW USE COGNITO GROUPS TO RESTRICT PERMISSIONS!

    if (REACT_APP_STAGE === 'prod')
      if (!allowedEmails.includes(user.email)) {
        toast({
          status: 'error',
          title: 'Failed to Update',
          description: 'You are not allowed to update payments',
          duration: 5000,
          isClosable: true,
          position: 'bottom',
        });
        return;
      }

    updatePayment
      .mutateAsync({
        id: payment.payment.id,
        eventHistoryItem: eventHistory,
        receivedFees,
        status,
      })
      .then(details => {
        if (!details) {
          toast({
            status: 'error',
            title: 'Failed to Update',
            description: 'Check your inputs',
            duration: 5000,
            isClosable: true,
            position: 'bottom',
          });
          return;
        }
        if (details.__typename === 'ErrorResponse') {
          toast({
            status: 'error',
            title: details ? details?.code : 'Failed to Update',
            description: details ? details?.error : 'Check your inputs',
            duration: 5000,
            isClosable: true,
            position: 'bottom',
          });
          return;
        }

        if (
          details.payment.event === eventHistory.event &&
          details.payment.status === status
        ) {
          toast({
            status: 'success',
            title: 'Payment Update Success',
            description: '',
            duration: 3000,
            isClosable: true,
            position: 'bottom',
          });
        } else {
          toast({
            status: 'error',
            title: 'Failed to Update',
            description: "Check your inputs or there's a backend issue",
            duration: 5000,
            isClosable: true,
            position: 'bottom',
          });
        }
      });
  };

  return (
    <Box width="full" p={2} bg="beige" alignItems={'center'}>
      {/*       <Select
        onChange={(e) => {
          const event = e.target.value as EventType;
          setEventHistory({ date: new Date().toJSON(), event, note: "Updated to " + event + " by admin with email " + user.email + " with ID: " + user.sub });
        }}
        placeholder={"Select Event"}
      >
        {events.map((x) => (
          <option value={x}>{x.replaceAll("_", " ")}</option>
        ))}
      </Select> */}

      {payment.payment.event !== EventType.PAYMENT_CONFIRMED &&
      payment.payment.processor === PaymentProcessors.MANUAL ? (
        <VStack>
          <Box>
            <List size={'xs'} spacing={1}>
              <ListItem>
                <HStack>
                  <Text as={'span'} fontWeight={'bold'}>
                    Event/Status:
                  </Text>{' '}
                  <Text>{eventHistory.event.replaceAll('_', ' ')}</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  {' '}
                  <Text as={'span'} fontWeight={'bold'}>
                    Received (K)
                  </Text>{' '}
                  <NumberInput
                    width={'100px'}
                    defaultValue={
                      payment.payment.receivedFees
                        ? payment.payment.receivedFees.receivedTotal
                        : 0
                    }
                    min={0}
                    precision={2}
                    step={1}
                    onChange={e => {
                      const value = parseFloat(e) || 0;
                      const diff = Math.floor(
                        value - payment.payment.fee.finalFee,
                      );
                      setReceivedFees({
                        ...receivedFees,
                        receivedTotal: Math.round(value),
                        overpayment: diff > 0 ? Math.abs(diff) : 0,
                        underpayment: diff < 0 ? Math.abs(diff) : 0,
                      });
                    }}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  {' '}
                  <Text as={'span'} fontWeight={'bold'}>
                    Expected:
                  </Text>{' '}
                  <Text>K {payment.payment.fee.finalFee}</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Text as={'span'} fontWeight={'bold'}>
                    Total:
                  </Text>{' '}
                  <Text>K {receivedFees.receivedTotal.toFixed(2)}</Text>
                </HStack>
              </ListItem>

              <ListItem>
                <HStack>
                  <Text as={'span'} fontWeight={'bold'}>
                    Refund:
                  </Text>{' '}
                  <Text>K {receivedFees.overpayment.toFixed(2)}</Text>
                </HStack>
              </ListItem>
              <ListItem>
                <HStack>
                  <Text as={'span'} fontWeight={'bold'}>
                    Pending:
                  </Text>{' '}
                  <Text>K {receivedFees.underpayment.toFixed(2)}</Text>
                </HStack>
              </ListItem>
            </List>
          </Box>
          <ButtonSoftPrimary onClick={onSave} leftIcon={<FaSave />}>
            Save
          </ButtonSoftPrimary>
        </VStack>
      ) : (
        <ReactJson
          name={null}
          sortKeys={true}
          collapsed={false}
          displayDataTypes={false}
          indentWidth={1}
          src={{ payment: payment.payment }}
        />
      )}
    </Box>
  );
}
