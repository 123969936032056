/* eslint-disable no-unused-vars */
export enum AUTH_MODES {
  API_KEY = 'API_KEY',
  AWS_IAM = 'AWS_IAM',
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}

export enum STAGE {
  DEV = 'dev',
  PROD = 'prod',
}

export type CurrentUser = {
  email: string;
  email_verified: boolean;
  phone_number: string;
  sub: string;
};
