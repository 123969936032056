import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Payment from './pages/Payments';
import PartnerQuotes from './pages/Quotes/PartnerQuotes';
import Order from './pages/Orders';
import Requests from './pages/Requests/Requests';
import Quotes from './pages/Quotes/Quotes';
import { paths } from './utils/constants';
import { Partners } from './pages/Partners';
import { Customers } from './pages/Customers';
import CustomerRequests from './pages/Requests/CustomerRequests';
import RequestQuotes from './pages/RequestQuotes/RequestQuotes';

const Index = () => {
  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.partners} element={<Partners />} />
      <Route path={paths.customers} element={<Customers />} />
      <Route path={paths.partnerQuotes} element={<PartnerQuotes />} />
      <Route path={paths.quotes} element={<Quotes />} />
      <Route path={paths.orders} element={<Order />} />
      <Route path={paths.requests} element={<Requests />} />
      <Route path={paths.payments} element={<Payment />} />
      <Route path={paths.customerRequests} element={<CustomerRequests />} />
      <Route path={paths.requestQuotes} element={<RequestQuotes />} />
      <Route path="*" element={<p>Path not resolved</p>} />
    </Routes>
  );
};

export default Index;
