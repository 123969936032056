import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import { Heading, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { Creator, searchInput } from '../../API';
import { searchQuotes } from '../../api/queries';
import PartnerCard from '../../components/cards/PartnerCard';
import QuoteResponseCard from '../../components/cards/QuoteResponseCard';
import { selectedPartnerAtom } from '../../recoil/atoms';

const PartnerQuotes: React.FC = (): JSX.Element => {
  const selectedPartner = useRecoilValue(selectedPartnerAtom);

  const [input] = useState<searchInput>({
    byCreator: {
      id: selectedPartner?.id || '',
      limitAndToken: { limit: 100 },
      creatorType: Creator.PARTNER,
    },
  });

  const { data: partnerRequestData } = useQuery(
    ['customerRequests', input],
    searchQuotes,
    {
      cacheTime: 10000,
      refetchInterval: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return selectedPartner ? (
    <VStack>
      <Card bg="orange.100" my={5} borderWidth="thin" p="2">
        <CardHeader>
          <Heading size="md">Partner</Heading>
        </CardHeader>
        <CardBody>
          <PartnerCard partner={selectedPartner} />
        </CardBody>
      </Card>

      <Heading size="md">Latest Quotes</Heading>
      {partnerRequestData?.byPartner?.quotes?.map(x => (
        <QuoteResponseCard key={x.quote.id} quoteResponse={x} />
      ))}
    </VStack>
  ) : (
    <Heading>No Data</Heading>
  );
};

export default PartnerQuotes;
