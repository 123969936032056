import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Select,
} from '@chakra-ui/react';
import { groupBy } from 'lodash';
import { useState } from 'react';
import ReactJson from 'react-json-view';
import { useRecoilValue } from 'recoil';
import { ordersAtom, selectedOrdersAtom } from '../../recoil/atoms';
import { statuses } from '../../utils/constants';

export default function OrderData() {
  const initOrders = useRecoilValue(selectedOrdersAtom);

  const orders = useRecoilValue(ordersAtom);

  const grouped = groupBy(orders, 'order.status');

  const [selectedOrders, setSelectedOrders] = useState(initOrders);

  return (
    <Box width="full" p={6}>
      <Heading>Orders</Heading>
      <Select
        placeholder="Select Status"
        onChange={e => {
          if (!e.target.value) {
            return;
          }

          setSelectedOrders(grouped[e.target.value]);
        }}
      >
        {statuses.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </Select>

      {selectedOrders ? (
        selectedOrders.map(({ order, payment, quotes }) => {
          return (
            <Accordion
              bg="orange.100"
              my={5}
              width={'full'}
              allowToggle={true}
              key={order.id}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Order
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={{ ...order }}
                  />
                </AccordionPanel>
              </AccordionItem>

              {payment && (
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Payment
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    maxHeight={'600px'}
                    overflow={'scroll'}
                    pb={4}
                  >
                    <ReactJson
                      name={null}
                      sortKeys={true}
                      collapsed={true}
                      displayDataTypes={false}
                      indentWidth={1}
                      src={payment}
                    />
                  </AccordionPanel>
                </AccordionItem>
              )}
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      Quotes
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel maxHeight={'600px'} overflow={'scroll'} pb={4}>
                  <ReactJson
                    name={null}
                    sortKeys={true}
                    collapsed={true}
                    displayDataTypes={false}
                    indentWidth={1}
                    src={{ quotes }}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          );
        })
      ) : (
        <Box width="full" p={6} bg="orange.100">
          NO ORDER DATA YET
        </Box>
      )}
    </Box>
  );
}
