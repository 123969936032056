import React from 'react';
import { UseInfiniteQueryResult } from 'react-query';
import { GetPartnersQuery } from '../../API';
import { Partner } from './partner';

type ContentProps = {
  query: UseInfiniteQueryResult<GetPartnersQuery['getPartners']>;
};

export const Content: React.FC<ContentProps> = ({ query }) => {
  return (
    <>
      {query.data &&
        query.data.pages.map(page => {
          return page.partners
            ? page.partners.map(partner => {
                return <Partner key={partner.id} partner={partner} />;
              })
            : null;
        })}
    </>
  );
};
