import moment from 'moment';
import {
  AutoPartRequest,
  Customer,
  EventHistoryItem,
  EventType,
  Fees,
  GearBox,
  MobileMoneyDetail,
  Order,
  Partner,
  PartnerOrder,
  Payment,
  PaymentProcessors,
  Quote,
  ReceivedFees,
  Request,
  Status,
  Vehicle,
} from '../API';

export const formatDate = (date: Date) => {
  let d = date ? date : new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export function unStringfy(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const paymentDisplayAttributes = ({
  id,
  created,
  status,
  referenceId,
  transactionId,
  notes,
  momoPhoneNumber,
  momoText,
  eventHistory,
  event,
  processor,
  fee,
  orderId,
  receivedFees,
}: Payment): {
  id: string;
  created: string;
  orderId: string;
  referenceId: string;
  transactionId?: string | null;
  notes?: string | null;
  momoPhoneNumber: string;
  momoText: string;
  status: Status;
  eventHistory: EventHistoryItem[];
  event?: EventType | null;
  processor: PaymentProcessors;
  fee: Fees;
  receivedFees?: ReceivedFees | null;
} => {
  return {
    id,
    created: moment(created).fromNow(),
    status,
    orderId,
    referenceId,
    transactionId,
    notes,
    momoPhoneNumber,
    momoText,
    eventHistory,
    event,
    processor,
    fee,
    receivedFees,
  };
};
export const orderDisplayAttributes = ({
  id,
  created,
  quotes,
  customerId,
  status,
}: Order): {
  id: string;
  created: string;
  quotes: PartnerOrder[];
  customerId: string;
  status: Status;
} => {
  return {
    id,
    created: moment(created).fromNow(),
    quotes,
    customerId,
    status,
  };
};

export const requestDisplayAttributes = ({
  id,
  created,
  status,
  quoteIds,
  make,
  model,
  year,
  fuel,
  variant,
  VIN,
  engineNumber,
  vehicleId,
}: Request): {
  id: string;
  status: Status;
  created: string;
  quoteIds?: string;
  make?: string | null;
  model?: string | null;
  year?: number | null;
  fuel?: string | null;
  gearbox?: GearBox | null;
  variant?: string | null;
  VIN?: string | null;
  engineNumber?: string | null;
  vehicleId?: string | null;
} => {
  return {
    id,
    status,
    created: moment(created).fromNow(),
    quoteIds: quoteIds?.join(', '),
    make,
    model,
    year,
    fuel,
    variant,
    VIN,
    engineNumber,
    vehicleId,
  };
};

export const quoteDisplayAttributes = ({
  id,
  created,
  status,
  expiryDate,
  comment,
  isNew,
}: Quote): {
  id: string;
  created: string;
  comment?: string | null;
  status: Status;
  expiryDate: string;
  isNew?: boolean | null;
} => {
  return {
    id,
    status,
    created: moment(created).fromNow(),
    expiryDate: moment(expiryDate).fromNow(),
    comment,
    isNew,
  };
};

export const isPrimitive = (val: any): boolean => {
  if (val === Object(val)) {
    return false;
  } else {
    return true;
  }
};

export const autoPartDisplayAttributes = ({
  quantity,
  autoPart: {
    id,
    name,
    description,
    fitmentNotes,
    weightG,
    widthMM,
    heightMM,
    lengthMM,
    isUniversal,
    modelCode,
    regYearAndmonth,
    mileage,
    transmissionType,
    engineModel,
    engineSize,
    fuel,
    drive,
    manufacturer,
    OEM,
    created,
  },
}: AutoPartRequest): {
  created: string;
  quantity: number;
  id: string;
  name: string;
  description?: string | null;
  fitmentNotes?: string | null;
  weightG?: number | null;
  widthMM?: number | null;
  heightMM?: number | null;
  lengthMM?: number | null;
  isUniversal?: boolean | null;
  modelCode?: string | null;
  regYearAndmonth?: string | null;
  mileage?: string | null;
  transmissionType?: string | null;
  engineModel?: string | null;
  engineSize?: string | null;
  fuel?: string | null;
  drive?: string | null;
  manufacturer?: string | null;
  OEM?: string | null;
} => {
  return {
    id,
    name,
    quantity,
    description,
    fitmentNotes,
    weightG,
    widthMM,
    heightMM,
    lengthMM,
    isUniversal,
    modelCode,
    regYearAndmonth,
    mileage,
    transmissionType,
    engineModel,
    engineSize,
    fuel,
    drive,
    manufacturer,
    OEM,
    created: moment(created).fromNow(),
  };
};

export const vehicleDisplayAttributes = ({
  //
  id,
  make,
  model,
  year,
  gearbox,
  variant,
  created,
  VIN,
  discontinued,
}: Vehicle): {
  //
  id: string;
  make: string;
  model: string;
  VIN?: string;
  year?: number;
  variant: string;
  gearbox?: GearBox;
  created: string;
  discontinued: string;
} => {
  return {
    id,
    make,
    model,
    VIN: VIN || undefined,
    year: year || undefined,
    gearbox: gearbox || undefined,
    variant,
    discontinued: discontinued ? 'DELETED' : 'NOT DELETED',
    created: moment(created).fromNow(),
  };
};

export const partnerDisplayAttributes = ({
  id,
  created,
  name,
  email,
  phoneNumber,
  address,
  mainContactPerson,
  mobileMoneyDetails,
}: Partner): {
  id: string;
  created: string;
  name: string;
  email?: string | null;
  phoneNumber: string;
  address?: string | null;
  mainContactPerson: string;
  mobileMoneyDetails?: MobileMoneyDetail[] | null | undefined;
} => {
  return {
    created: moment(created).fromNow(),
    id,
    name,
    email,
    phoneNumber,
    address,
    mainContactPerson,
    mobileMoneyDetails,
  };
};

export const customerDisplayAttributes = ({
  //
  id,
  firstName,
  familyName,
  gender,
  phoneNumber,
  email,
  created,
}: Customer): {
  //
  id: string;
  firstName: string;
  familyName: string;
  gender?: string;
  phoneNumber: string;
  email?: string;
  created: string;
} => {
  return {
    //
    id,
    firstName,
    familyName,
    gender: gender || undefined,
    phoneNumber,
    email: email || undefined,
    created: moment(created).fromNow(),
  };
};

export const splitCamelCase = (s: string): string =>
  s
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });

export const capitalizeEveryFirstLetter = (mySentence: string): string =>
  mySentence
    ? mySentence
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    : '';
